.TCGL_V_A1_Id_Upload{
    .btn-banner{
        min-width: unset !important;
    }

.supagebg1{
    background: url(../../img/TCGL_V_A1/ae1de7ccf65eea9650bd5418d79e821d.jpg) no-repeat top center #1891a5;
    background-size: cover;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
.nobg{
    background: none !important;
}

.form-header-sub2 {
    background: linear-gradient(0deg, rgba(206,214,255,1) 0%, rgba(195,223,255,1) 100%);
    p {
        font-size: 14px;
        color: #000;
        margin: 0px;
        line-height: 21px;
    }
    h4 {
        font-size: 35px
    }
    // border-bottom: solid 1px #BABABA;
}

.formsub-idbg{
    // background: rgb(206,214,255) url("../../img/TCGL_V_A1/idtopbg.png") no-repeat center top  !important;
    // background-size: cover !important;
    // background: linear-gradient(0deg, rgba(206,214,255,1) 0%, rgba(195,223,255,1) 100%);
    padding: 23px 0;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-top: solid 7px #ffffff;
    margin-bottom:15px;
    color: black;
}
.iduploadcol{
    background-color: #FFFFFF;
    border: solid 1px #D1D1D1;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 25px;
    border-radius: 10px;
    .idcol{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 15px 0;

        h3{
            color: #333333 !important;
        }
        .idicon{

            img{
                background-color:#D9D9D9;
                padding: 10px;
                border-radius: 50px;
            }
        }
        .buttoncol{
            padding-left: 15px;
            text-align: left;
        }
    }

    .clickupload{

        padding: 10px;
        color: #FFFFFF;
        border: 0px;
        border-radius: 30px;
        font-size: 16px;
        min-width: 225px;

        margin: auto;
        margin-top: 16px;
        background-color: #1c6500;
        &:hover{
            background-color:#2145dd;
        }
    }

    .fileupload{
        background-color: #81E18A;
        padding: 5px;
        color: #0C9D1B;
        border: 0px;
        border-radius: 30px;
        font-size: 14px;
        min-width: 225px;
    }

}

.border-0{
    border: 0px !important;
}


.btn-back{
    background: #fff;
    border-radius: 30px;
    color: #1F1F1F;
    border: 1px solid #2145DD;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    text-align: center;
    margin: 10px 0 20px;
    width: 100% !important;
    display: inline-block;
}

.green_color_but  {
    padding: 10px 2px;
    background: #01b636;
    border: 0;
    border-radius:25px;
    position: relative;
    font-size: 1rem;
    color: #fff;
}

.form-header {
    padding: 15px 0;
    border-bottom: solid 1px #BABABA;
    margin-bottom: 35px;

    h4 {
        color: #A0A0A0;

        span {
            color: #000;
        }
    }

    h5 {
        color: #000;
        font-size: 18px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: #000;
        margin: 0px;
    }

    .bor-l {
        border-left: solid 1px #BABABA;
        padding: 10px 15px;
    }

    img {
        max-height: 60px;
    }
}
@media screen and (max-width: 768px) {
    .form-header-sub{
        img{
            max-height: 35px;
        }
    }
    .iduploadcol{
        .idcol{
            padding: 15px 0px;
            .h3{
                margin: 10px 0;
            }
            .idicon{
                img{
                    max-height: 55px;
                }
            }
        }
        .clickupload{
            font-size: 14px;
            padding: 10px;
        }
    }

}
}
}


.up-wrap {
    border: 1px dashed #2d2d2d;
    border-radius: 20px;
    height: 210px;
    position: relative;
    text-align: center;
    display: flex;
    font-size: 3rem;
    justify-content: center;
    align-items: center;

    .upload-text {
        font-size: 1.2rem;
    }

    img {
        border-radius: 10px;
    }

    .imgloader {
        position: absolute;
        background: #ffffff57;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .progress {
            width: 80%;
            margin-top: 9rem;

            .progress-bar {
                animation: profill 2s ease-in 100ms both;
            }

            @keyframes profill {
                0% {
                    width: 0%;
                }

                50% {
                    width: 100%;
                }
            }
        }
    }
}
.uploadbox {
    color: #2d2d2d;
}
.upload-text {
    font-size: 1.2rem;
    color: #2d2d2d;
}

.upl-btn {
    padding: 10px 2px;
    background: #2145dd;
    border: 0;
    border-radius:25px;
    position: relative;
    font-size: 1rem;
    color: #fff;
    input {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        right: 0;
        opacity: 0;
    }
}
.green_color_but  {
    padding: 10px 2px;
    background: #01b636;
    border: 0;
    border-radius:25px;
    position: relative;
    font-size: 1rem;
    color: #fff;
}

/* styles.css */
.next-css{
    max-width: none !important;
    min-width: none !important;
  }
