.thankyou {
    .check-tik {
        width: 100%;
        text-align: center;
        display: flex;
        padding-top: 60px;
        justify-content: center;
    }

    .circle-border {
        z-index: 0;
        position: absolute;
        background: #00ff55;
        transform: scale(1.1);

    }

    .circle {
        z-index: 1;
        position: relative;
        background: rgba(0, 255, 55, 0);
        transform: scale(1);
    }

    .success::before {
        width: 28px;
        top: 56%;
        left: 18%;
        transform: rotateZ(37deg);
    }

    .success::before
     {
        content: "";
        display: block;
        height: 9px;
        background: #ffffff;
        position: absolute;
    }
    .success::after{
        content: "";
        display: block;
        width: 40px;
        top: 47%;
        left: 36%;
        transform: rotateZ(309deg);
    }

}