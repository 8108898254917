.id_uploadv1{
$background_color_1:#ffffff;
$background_color_2:#063783;
$background_color_3:#f0f2f9;


$color_1:#ffffff;
$color_2:#063783;
$color_3: #000;


.id_upload{
    background: #e8e8e8 url(../../img/signature_bg.png) no-repeat center left;
    padding:80px 0 80px;
	background-size: cover;
    background-attachment: fixed;
    .top_right_sec{
        .fixed {
            display: flex; 
            flex-direction: column;
        
            position: -webkit-sticky;
            position: sticky;
            right:0px;
            top: 0;
        }
        .rating{
            margin-bottom:15px;
            img{
                height: 20px;
            }
        }
        .potential-claim {
            background: #ec6842 url(../../img/bg-claim.png) no-repeat;
            background-size: cover;
            border-radius: 0px;
            color: #fff;
            margin: 20px auto 10px;
            position: relative;
            width: 100%;
            padding: 25px 15px;
            text-align: center;
            box-shadow: 0 25px 25px rgba(5,28,49,.14);
            -webkit-box-shadow:0 25px 25px rgba(5,28,49,.14);
            &::before{
                
                    background: url(../../img/left_ar.png) no-repeat;
                    background-size: 100% 100%;
                    content: "";
                    height: 34px;
                    left: -20px;
                    position: absolute;
                    top: 50px;
                    width: 20px;
                
            }
            @media screen and (max-width: 768px) {
                &::before{ display: none;}
            &::after {
                background: url(../../img/bot_ar.png) no-repeat;
                content: "";
                height: 24px;
                position: absolute;
                bottom: -23px;
                left: 45%;
                width: 30px;
                background-size: contain;
            }
        }
    
    
    
            h4{
                font-size: 20px;
                font-weight: 400;
                span{
                    font-size: 64px;
                        line-height: 64px;
                     font-weight: 800;
                     margin-top: 15px;
                     display: block;
                
                }
            }
        }
    
        .useful-facts {
            background: #ffe500  url(../../img/bg-claim2.png) no-repeat;
            background-size: 100% 100%;
            border-radius: 0px;
            color: $color_2;
            margin: 20px auto 10px;
            position: relative;
            width: 100%;
            padding: 15px;
            box-shadow: 0 25px 25px rgba(5,28,49,.14);
            -webkit-box-shadow:0 25px 25px rgba(5,28,49,.14);
            
            &::before{
                
                    background: url(../../img/left_ar_2.png) no-repeat;
                    background-size: 100% 100%;
                    content: "";
                    height: 34px;
                    left: -20px;
                    position: absolute;
                    top: 50px;
                    width: 20px;
                
            }
            @media screen and (max-width: 768px) {
                margin: 35px auto 10px;
                border-radius: 7px;
                &::before{ display: none;}
            }
            h4{
                font-size: 20px;
                font-weight: 600;
                text-align: center;
            }
    
            ul{
                margin: 0px;
                padding: 0px;
                padding-left: 15px;
                li{
                    background: url(../../img/fact_ico.png) no-repeat center left;
                    background-size: 24px;
                    list-style: none;
                    padding: 10px 0;
                    padding-left: 35px;
                }
            }
    
        }
    
    
    
    
    
    
    }
    .idwrapper{
        padding: 15px 20px 30px;
        background: #fff;
        border-radius: 7px;
        border-top:5px solid $color_2;
        border-bottom:5px solid $color_2;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        .uploads {
            border: 2px solid $color_2;
            padding: 15px;
            border-radius: 5px;
            font-size: 1.1rem;
            font-weight: 500;
            background-color: $background_color_2;
            color: $color_1;
        
          }
          .uploadbox{
            color: $color_2;
            i{
                color: $color_2;
            }
          }
          .upl-btn {
            padding: 10px 2px;
            background: $background_color_2;
            border: 0;
            border-radius: 5px;
            position: relative;
            font-size: 1rem;
            color: #fff;
            input {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              right: 0;
               opacity: 0;
            }
        
        
          }
          .up-wrap {
            border: 3px dashed $color_2;
            border-radius: 20px;
            height: 210px;
            position: relative;
            text-align: center;
            display: flex;
            font-size: 3rem;
             justify-content: center;
             align-items: center;
             .upload-text {
              font-size: 1.2rem;
              color: $color_2;
             }
             img {
              border-radius: 10px;
             }
            .imgloader {
              position: absolute;
              background: #ffffff57;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
               
              .progress {
                width: 80%;
                margin-top: 9rem;
                
                
                .progress-bar {
                  animation: profill 2s ease-in 100ms  both  ;
                }
                @keyframes profill {
                  0% {
                    width: 0%;
                  }
                
                  50% {
                    width: 100%;
                  }
                }
              }
        
              
              
        
            }
          }

    h2{
        font-size: 36px;
        font-weight: 600;
        color: $color_2;
        margin: 20px 0 0;
    }
    h3{
        font-size: 18px;
        line-height: 1.5;
        font-weight: 600;
        color: #063783;
        margin-bottom: 15px;
        span{
            background: #01b636;
            color: #fff;
            padding: 5px;
            font-size: 17px;
            line-height: 35px;
        
        }
    }
    p{
        font-size: 14px;
        a{
            color: $color_2 !important;
            text-decoration: underline;
            &:hover{
                color:#01b636 !important;
            }
        }
    }
    .error_msg{
        background: red;
        border-radius: 4px;
        width: 100%;
        margin-left: 0px;
        margin-top: 5px;
        padding-left: 7px;
        padding-right: 7px;
        color: white;
            display: block;
        }

.idButton {
	background-image: url(../../img/right-chevron.svg) !important;
	background-size: 17% 42px !important;
	background-repeat: no-repeat !important;
	background-position: 100% !important;
	display: inline-block;
	background: $background_color_2;
	color: #fff !important;
	padding: 16px 0px;
	border-radius: 7px;
	border: 0;
    font-size: 22px;
    width: 50%;
    // max-width: 200px;
	font-weight: 600;
	box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
	cursor: pointer;
	transition: all .3s ease-in-out;
    text-align: center;
	&:hover{
		background: #01b636;
	}
}
.green_color_but{
	background-image: url(../../img/right-chevron.svg) !important;
	background: #01b636 ;
	transition: all .3s ease-in-out;
	&:hover{
		background: #ec6842 ;
	}
}

.upload-text{
    font-size: 12px !important;
    color:black !important;
}

}



// animated fixed but
@media screen and (max-width: 768px) {
    .top_right_sec {
        .potential-claim{
            margin: 0px auto 10px;
            padding: 10px 0px;
            border-radius: 7px;
            h4{
                font-size: 16px;
                span{
                    font-size: 24px;
                    line-height: 23px;
                    margin-top: 0px;
                    display: inline-block;
                }
            }
        }
    }
    .idwrapper{


    }

    .fixed_but{
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 99;
        width: 100% !important;
    }
    .but_anim{
        animation: mymove 1s infinite;
    }
    
    @keyframes mymove {
      50% {box-shadow: 0px 0px 50px #063783;}
    }
    }
    // animated fixed but end

}

.skip-btn{
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    background-size: 14px;
    background-color: #ccc;
    font-weight: 700;
    outline: none;
    border: 0;
    height: auto !important;
    max-width: 100px !important;

}


    @media screen and (max-width: 768px) {

        .id_upload{
            padding: 10px 0 40px;
            .idwrapper{
                margin-top: 20px;
                h2{
                    font-size:24px;
                    margin: 15px 0 0;
                }
                h3{
                    margin-top: 15px;
                }
            }
        }

    }


}
