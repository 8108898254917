@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.TCG_L_C_2 {
/*
*******************************************

Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/





// Global styles


	// * {
	// 	margin: 0;
	// 	padding: 0;
	// 	box-sizing: border-box;
	//   }
	@import "odometer/themes/odometer-theme-default.css";
	html,
	body {
		margin: 0;
		padding: 0;
		font-family: "Poppins", sans-serif;
	}

	//   .error_msg{
	// 	color: white; 
	// 	background-color: red; 
	// 	padding: 3px; 
	// 	margin-top: 10px; 
	// 	border-radius: 5px; 
	// 	width: 200px !important;
	// 	width: 50% !important;
	//     text-align: center;
	// 	border-radius: 5px !important;
	//   }
		.error_msg {
			color: white;
			margin-bottom: 2px;
			width: 100%;
			padding: 1px 5px;
			background: #ff0000;
			display: inline-block;
			font-size: 13px;
			border-radius: 5px;
		}

	.show {
		display: block !important;
	}

	.hide {
		display: none !important;
	}

	// Link styles
	a {
		color: #007BFF; // Default link color
		text-decoration: none; // Remove underline from links

		&:hover {
			text-decoration: underline; // Underline on hover
		}

		&:focus {
			outline: none; // Remove outline on focus
			box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); // Add a focus ring
		}
	}

	// Unordered list styles
	ul {
		list-style: none; // Remove bullets
		padding-left: 0 !important; // Remove default padding

		li {
			list-style-type: none;
			//padding-left: 20px; // Add some padding to list items
			//   position: relative;
		}
	}

	// Additional common elements
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 10px 0; // Margin for headings

		color: #ffffff ; // Heading color
	}

	p {
		margin: 0 0 15px 0; // Margin for paragraphs
	}

	img {
		max-width: 100%; // Make images responsive
		height: auto;
	}

	.wrapper {
		font-family: "Poppins", sans-serif;
	}

	.button-blink{
		animation: animation-blue-flash 1.25s linear infinite normal;
	}
	
		@keyframes animation-blue-flash {
			0% {
				box-shadow: 0 0 0 0 #0092fb;
			}
	
			70% {
				box-shadow: 0 0 0 15px rgba(2, 103, 193, 0);
			}
	
			100% {
				box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
			}
		}
	header {
		width: 100%;
		padding: 18px 0;
		background: #fff;
		border-bottom: 1px solid #e1e1e1;
		/* animation magic */
		transition: all 0.4s ease-in-out;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;

		@media (max-width: 510px) {
			padding: 15px 0;
			text-align: center;
		}

		.logo_img {
			width: 220px;

			@media (max-width: 510px) {
				width: 200px;
			}
		}

	}

	.header-rating {
		background: #02a1e5;
		padding: 8px 0;
		width: 100%;

		.reviews-io {
			align-items: center;
			color: #23a893;
			display: flex;
			flex-direction: column;
			font-size: 1rem;
			margin: 0 auto;
			pointer-events: none;
			width: 100%;

			.reviews-io-rating {
				align-items: center;
				display: flex;
				flex-direction: row;
				gap: .25em;
				justify-content: center;
				pointer-events: all;
				width: auto;

				.reviews-io-rating--logo {
					width: 6em;
				}

				.reviews-io-stars {
					font-size: 1em;
				}

				.reviews-io-stars {
					--reviews-io-stars--empty: rgba(0, 0, 0, .25);
					--reviews-io-stars--filled: #fcd015;
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					font-size: .8125em;
					justify-content: flex-start;
					position: relative;
					unicode-bidi: bidi-override;
				}
			}
		}
	}

	section.form {
		padding: 60px 0 90px 0;
		background: url(../../../assets/img/bg.webp) no-repeat center center;
		background-size: cover;
		background-color: #063783;
		z-index: 4;
		position: relative;
		height: 100%;
		min-height: 600px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		&:before {
			position: absolute;
			content: " ";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			z-index: 0;
			background-color: #000000b8;
		}

		@media only screen and (min-width: 598px) and (max-width: 1200px) {
			padding: 40px 0 75px 0;
		}

		@media only screen and (min-width: 280px) and (max-width: 598px) {
			padding: 30px 0 75px 0;
			img{
				max-height: 200px;
			}
		}

		.form-left {
			// width: 100%;
			// padding: 0 450px 0 0;
			z-index: 10;

			@media only screen and (min-width: 960px) and (max-width: 1200px) {
				// padding: 0 325px 0 0;
			}

			@media only screen and (min-width: 268px) and (max-width: 960px) {
				// padding: 0;
			}

			.head_text {
				font-size: 40px;
				line-height: 140%;

				@media only screen and (min-width: 960px) and (max-width: 1200px) {
					font-size: 36px;
				}

				@media only screen and (min-width: 768px) and (max-width: 960px) {
					font-size: 32px;
				}

				@media only screen and (min-width: 510px) and (max-width: 768px) {
					font-size: 30px;
					text-align: center;
				}

				@media only screen and (min-width: 210px) and (max-width: 510px) {
					font-size: 33px;
					text-align: center;
				}
			}

			.sub_text {
				font-size: 26px;
				line-height: 140%;

				@media only screen and (min-width: 960px) and (max-width: 1200px) {
					font-size: 25px;
				}

				@media only screen and (min-width: 768px) and (max-width: 960px) {
					font-size: 23px;
				}

				@media only screen and (min-width: 510px) and (max-width: 768px) {
					font-size: 20px;
					text-align: center;
				}

				@media only screen and (min-width: 210px) and (max-width: 510px) {
					font-size: 23px;
					text-align: center;
				}

				#i {
					font-size: 38px;
					font-weight: 600;

					@media only screen and (min-width: 960px) and (max-width: 1200px) {
						font-size: 32px;
					}

					@media only screen and (min-width: 768px) and (max-width: 960px) {
						font-size: 29px;
					}

					@media only screen and (min-width: 510px) and (max-width: 768px) {
						font-size: 25px;
					}

					@media only screen and (min-width: 210px) and (max-width: 510px) {
						font-size: 23px;
					}
				}
			}

			.highlight-circle {
				position: relative;
				z-index: 5;
				display: inline-block;

				&:before {
					content: '';
					z-index: -1;
					left: -0.2em;
					top: -6px;
					border-width: 4px;
					border-style: solid;
					border-color: #ec6842;
					position: absolute;
					border-right-color: transparent;
					width: 100%;
					height: 2em;
					-webkit-transform: rotate(2deg);
					transform: rotate(2deg);
					opacity: 0.65;
					border-radius: 50%;
					padding: 0;
					-webkit-animation: animation-in-out 1.5s infinite alternate;
					animation: animation-in-out 1.5s infinite alternate;
				}

				&:after {
					content: '';
					z-index: -1;
					left: -0.2em;
					top: -6px;
					padding: 0;
					border-width: 4px;
					border-style: solid;
					border-color: #ec6842;
					border-left-color: transparent;
					border-top-color: transparent;
					position: absolute;
					width: 100%;
					height: 2em;
					-webkit-transform: rotate(-1deg);
					transform: rotate(-1deg);
					opacity: 0.65;
					border-radius: 50%;
					-webkit-animation: animation-in-out 1.5s infinite alternate;
					animation: animation-in-out 1.5s infinite alternate;
				}
			}

			@keyframes animation-in-out {
				0% {
					transform: scale(1.2) rotate(4deg);
				}

				100% {
					transform: scale(1) rotate(-4deg);
				}
			}
		}

		.ssl-logo{
			max-height:70px;
			margin-top: 15px;
		}
		//.form-left

		.form-bg {
			background-color: rgba(255, 255, 255, 0.98);
			border-radius: 15px;
			box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
			margin-top: 40px;
			margin-bottom: 10px;
			padding: 20px 20px 15px;
			position: relative;

			@media (max-width: 510px) {
				margin-top: 20px;
			}
		}
		   
		.img-class1 {
			position: absolute;
			z-index: 100;
			right: 0;
		}

		.personal_detail {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
				gap: 0px;
			}
		}

		.choose_Y_N {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 10px;

			.choose_Y_N2 {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 10px;
				gap: 10px;
				width: 100%;
			}

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
			}
		}

		.choose_ques {
			display: grid;
			grid-template-columns: repeat(1, 1fr);

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
			}
		}

		.note_pick {
			display: inline-flex;
			font-size: 12px;
			color: #000 !important;

			img {
				height: fit-content !important;
			}

			a {
				color: #000 !important;
				text-decoration: none !important;
			}
		}


		.options {
			display: flex;
			clear: both;
			width: 100%;
			flex-wrap: wrap;

			li {
				width: auto;
				display: block;
				// float: left;
				margin: 0 10px 10px 0;
			}
		}

		.options.two {
			li {
				width: calc(50% - 10px);
				// float: left;
			}
		}

		//.options.two
		@media only screen and (min-width: 480px) and (max-width: 768px) {
			form .options.two.lender li {
				width: 100%;
			}
		}

		@media only screen and (max-width: 480px) {

			form .options li,
			form .options.two li {
				width: 100%;
				margin: 0 0 10px 0;
			}
		}

		@media only screen and (min-width: 960px) and (max-width: 1200px) {
			form .options.two.lender li {
				width: 100%;
			}
		}

		.additional_lenders {
			margin: -9px 0 5px 0;
			display: block;
			text-align: center;
			color: #1f5652 !important;
			cursor: pointer;
			font-size: 18px;
			text-decoration: underline;
			font-weight: 500;

			@media (max-width: 510px) {
				font-size: 16px;
			}
		}

		//additional_lenders

		.anotherlenderoptions {
			label {
				span {
					padding: 0;
					font-size: 14px !important;
				}
			}
		}

		.margin_use {
			margin: 0 10px 10px 0 !important;

			@media (max-width: 510px) {
				margin: 0 0px 6px 0 !important;
			}
		}

		.custom-checkbox {
			display: none;
		}

		label {
			color: #1b1b1b;
			font-size: 22px;
			font-weight: 600;
			display: block;
			clear: both;
			margin-bottom: 13px;
			line-height: 140%;

			@media (max-width: 510px) {
				font-size: 20px;
			}

			span {
				font-size: 18px;
				display: block;
				line-height: 160%;
				padding: 5px 0 0 0;
				clear: both;

				@media (max-width: 510px) {
					font-size: 17px;
				}
			}

		}

		.custom-checkbox:checked+label {
			background: #FFFFFF;
			background: #EBFFEB !important;
			border: 2px solid #00A400;
			color: black !important;
		}

		.custom-checkbox:checked+label:before {
			border: 2px solid #00A400;
			// background-color: #00A400;
			content: '';
			background: #00A400 url(../../img/check.png) no-repeat center center;
			background-size: 100%;
		}

		.custom-checkbox+label {
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 8px 0;
			border-radius: 5px;
			width: 100%;
			font-size: 14px;
			font-weight: 600;
			background: #FFFFFF;
			border: 2px solid #080D21;
			padding: 10px 20px 10px 50px;
			height: 50px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			color: #080D21;

			&:before {
				border: 2px solid #080D21;
				background: #FFFFFF;
				width: 18px;
				height: 18px;
				content: '';
				margin: 0;
				position: absolute;
				top: 13px;
				left: 17px;
				display: block;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			}

			&:active {
				-webkit-box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
				box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
			}
		}

		//.custom-checkbox + label

		form input,
		form #idpc_button,
		form select,
		form textarea {
			width: 100%;
			height: 50px;
			outline: none;
			border-radius: 5px;
			-webkit-appearance: none;
			appearance: none;
			padding: 0 20px;
			font-size: 16px;
			font-weight: 500;
			color: #080D21;
			border: 2px solid #080D21;
			z-index: 30;
		}

		form select {
			background: #FFFFFF url(../../img/select.svg) no-repeat right 20px center;
			background-size: 15px 15px;
			-webkit-background-size: 15px 15px;
			-moz-background-size: 15px 15px;
			-o-background-size: 15px 15px;
			padding: 0 50px 0 20px;
		}

		.input-group-text {
			display: flex;
			align-items: center;
			padding: 0.375rem 0.75rem;
			height: 50px;
			margin-bottom: 0;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border-left: 0;
			border: 1px solid #080d21;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		// ==============================
		// BUTTON DESIGN
		// ==============================

		.back-but {
			display: inline-block;
			padding: 5px 25px;
			cursor: pointer;
			text-align: center;
			background: url(../../img/left-arrow.png)no-repeat center left 10px;
			background-size: 14px;
			background-color: #cccccc;
			font-weight: 700;
			outline: none;
			border: 0;
			height: auto !important;
			max-width: 100px !important;

			@media (max-width: 510px) {
				background: url(../../img/left-arrow.png)no-repeat center left 80px;
				background-size: 14px;
				background-color: #cccccc;
				max-width: unset !important;
				font-size: 20px;
			}
		}

		.back-arrow {
			display: inline-block;
			padding: 5px 25px;
			cursor: pointer;
			text-align: center;
			background: url(../../img/right-arroww.png)no-repeat center right 12px;
			padding-right: 28px;
			background-size: 14px;
			background-color: #cccccc;
			font-weight: 700;
			outline: none;
			border: 0;
			height: auto !important;
			max-width: 100px !important;

			@media (max-width: 510px) {
				background: url(../../img/right-arroww.png)no-repeat center left 80px;
				background-size: 14px;
				background-color: #cccccc;
				max-width: unset !important;
				font-size: 20px;
			}
		}

		#skipStepADD {
			display: inline-block;
			padding: 5px 10px;
			cursor: pointer;
			text-align: center;
			background: url(../../img/right-arrow.png)no-repeat center right 10px;
			background-size: 14px;
			background-color: #cccccc;
			font-weight: 700;
			outline: none;
			border: 0;
			height: auto !important;
			max-width: 100px !important;

			@media (max-width: 510px) {
				background: url(../../img/right-arrow.png)no-repeat center right 80px;
				background-size: 14px;
				background-color: #cccccc;
				max-width: unset !important;
				font-size: 20px;
			}
		}



		.next-but {
			display: inline-block;
			padding: 18px 50px;
			color: #FFFFFF;
			font-size: 24px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			margin: 7px auto !important;
			// max-width: 250px !important;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			background: url(../../img/right-arrow.png)no-repeat center right 44px;
			background-size: 21px;
			background-color: #02a1e5;
			outline: none;
			border: 0;
			height: auto !important;

			@media (max-width: 510px) {
				// background: url(../../img/right-arrow.png)no-repeat center right 70px;
				background-size: 21px;
				background-color: #02a1e5;
				max-width: unset !important;
				padding: 18px 40px;
				font-size: 20px;
			}

			&:hover {
				background-color: #021458
			}

		}

		.div-space{
			margin: 15px 0;
			@media (max-width:768px) {
				text-align: center;
			}
		}
		

		.reg-ur-clm{
			display: inline-block;
			padding: 18px 50px;
			color: #FFFFFF;
			font-size: 22px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			margin-top: 20px;
			margin: 7px 0;
			// max-width: 250px !important;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			background: url(../../img/right-arrow.png)no-repeat center right 20px;
			background-size: 21px;
			background-color: #ec6842;
			outline: none;
			border: 0;
			height: auto !important;

			@media (max-width: 510px) {
				// background: url(../../img/right-arrow.png)no-repeat center right 70px;
				background-size: 21px;
				background-color: #ec6842;
				max-width: unset !important;
				padding: 18px 20px;
				font-size: 20px;
				margin: 7px auto;
				width: 100%;
			}

			&:hover {
				background-color: #b7330d;
			}

		}

		.override-styles {
			max-width: unset !important;
			margin: 0 !important;
		}


		#findADD,
		#findADD1 {
			max-width: 330px !important;

			@media (max-width: 510px) {
				max-width: unset !important;
			}
		}


		.progress_bar {
			.progress-bar-line {
				width: 100%;
				height: 15px;
				background: #e7e7e7;
				display: block;
				clear: both;
				-webkit-border-radius: 50px;
				-moz-border-radius: 50px;
				border-radius: 50px;
				overflow: hidden;
				margin: 0 0 30px 0;
				position: relative;

				span {
					position: absolute;
					top: 0;
					left: 0;
					background: #002186;
					height: 15px;
					color: #FFFFFF;
					padding: 0 10px 0 0;
					text-align: right;
					line-height: 15px;
					-webkit-border-radius: 50px;
					-moz-border-radius: 50px;
					border-radius: 50px;
				}

				.load {
					animation: load 2s normal forwards;
					-webkit-animation: load 2s normal forwards;
				}

				.load1 {
					animation: load1 2s normal forwards;
					-webkit-animation: load1 2s normal forwards;
				}

				.load2 {
					animation: load2 2s normal forwards;
					-webkit-animation: load2 2s normal forwards;
				}

				.load3 {
					animation: load3 2s normal forwards;
					-webkit-animation: load3 2s normal forwards;
				}

				.load4 {
					animation: load4 2s normal forwards;
					-webkit-animation: load4 2s normal forwards;
				}
			}
		}

		// PROGRESS_BAR

		@keyframes load {
			0% {
				width: 0;
			}

			100% {
				width: 10%;
			}
		}

		@keyframes load1 {
			0% {
				width: 0;
			}

			100% {
				width: 30%;
			}
		}

		@keyframes load2 {
			0% {
				width: 0;
			}

			100% {
				width: 50%;
			}
		}

		@keyframes load3 {
			0% {
				width: 0;
			}

			100% {
				width: 70%;
			}
		}

		@keyframes load4 {
			0% {
				width: 0;
			}

			100% {
				width: 95%;
			}
		}


		@media only screen and (min-width: 768px) and (max-width: 990px) {
			.form-right.top-up {
				// margin: 40px 0 0 0;
			}
		}

		@media only screen and (min-width: 768px) and (max-width: 990px) {
			.form-right.top-up .badge_right:before {
				border-width: 0 30px 30px 30px;
				border-color: transparent transparent #ec6842 transparent;
				transform: rotateX(178deg);
			}
		}

		@media only screen and (min-width: 480px) and (max-width: 768px) {
			.form-right.top-up .badge_right:before {
				border-width: 0 15px 15px 15px;
				border-color: transparent transparent #ec6842 transparent;
				transform: rotateX(178deg);
			}
		}

		@media only screen and (max-width: 480px) {
			.form-right.top-up .badge_right:before {
				border-width: 0 15px 15px 15px;
				border-color: transparent transparent #ec6842 transparent;
				transform: rotateX(178deg);
			}
		}

		.form-right {
			// width: 400px;
			// position: absolute;
			// top: 0;
			// right: 0;
			height: 100%;

			@media only screen and (min-width: 960px) and (max-width: 1200px) {
				// width: 250px;
			}

			@media only screen and (min-width: 768px) and (max-width: 990px) {
				width: 100%;
				height: auto;
				position: relative;
				margin: 30px 0 30px 0;
				// float: left;
			}

			@media only screen and (min-width: 280px) and (max-width: 768px) {
				width: 100%;
				height: auto;
				// position: relative;
				margin: 0 0 30px 0;
				// float: left;
			}

			@media only screen and (max-width: 480px) {
				margin: 0 0 20px 0;
			}

			.badge_right {
				width: 100%;
				background: #ec6842;
				// float: right;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				position: -webkit-sticky;
				position: sticky;
				top: 15px;

				&:before {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 30px 30px 30px 0;
					border-color: transparent #ec6842 transparent transparent;
					position: absolute;
					top: 50%;
					left: -28px;
					transform: translateY(-50%);

					@media only screen and (min-width: 768px) and (max-width: 990px) {
						border-width: 30px 30px 0 30px;
						border-color: #ec6842 transparent transparent transparent;
						top: auto;
						bottom: -26px;
						left: 75px;
					}

					@media only screen and (min-width: 480px) and (max-width: 768px) {
						border-width: 15px 15px 0 15px;
						border-color: #ec6842 transparent transparent transparent;
						top: auto;
						bottom: -12px;
						left: 75px;
					}

					@media only screen and (max-width: 480px) {
						border-width: 15px 15px 0 15px;
						border-color: #ec6842 transparent transparent transparent;
						top: auto;
						bottom: -12px;
						left: 75px;
					}
				}

				.logo_design {
					position: absolute;
					bottom: -60px;
					right: -15px;
					width: 250px;
					padding: 10px 20px;
					background: #FFFFFF;
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					border-radius: 5px;
					margin: 0 0 15px 0;

					@media only screen and (min-width: 268px) and (max-width: 960px) {
						display: none;
					}
				}

				//logo_design
				.content_aware {
					width: 100%;
					margin: 0 auto;
					padding: 30px 30px 40px 30px;

					@media only screen and (min-width: 768px) and (max-width: 960px) {
						padding: 30px;
					}

					@media only screen and (min-width: 480px) and (max-width: 768px) {
						padding: 15px 30px 10px 30px;
					}

					@media only screen and (max-width: 480px) {
						padding: 15px 30px 10px 30px;
					}

					p {
						font-size: 20px;
						color: #ffffff;
						text-align: center;
						font-weight: 700;

						@media (max-width: 768px) {
							font-size: 13px;
						}
					}

					h2 {
						font-size: 38px;
						color: #ffffff !important;
						text-align: center;
						font-weight: 900 !important;

						@media only screen and (min-width: 768px) and (max-width: 960px) {
							font-size: 42px;
							letter-spacing: -0.025em;
						}

						@media (max-width: 768px) {
							font-size: 25px;
						}

						span {
							font-size: 20px !important;
							color: #ffffff;
							text-align: center;
							font-weight: 700;
							position: relative;
							top: -4px;

							@media (max-width: 768px) {
								font-size: 13px !important;
							}
						}
					}
				}

				//content_aware
			}

			//logo_design
		}

		//form-right

		@media only screen and (min-width: 480px) and (max-width: 768px) {
			form .form-right.top-up {
				margin: 30px 0 0 0;
			}
		}

		@media only screen and (max-width: 480px) {
			form .form-right.top-up {
				margin: 15px 0 0 0;
			}
		}
	}
	.zoom {
		animation: zoom-in-zoom-out 1s ease-in-out infinite;
	  }
	  
	  @keyframes zoom-in-zoom-out {
		0% {
		  transform: scale(0.9, 0.9);
		}
	  
		50% {
		  transform: scale(1, 1);
		}
	  
		100% {
		  transform: scale(0.9, 0.9);
		}
	  }
	//section.form

	.modal {
		background: rgba(0, 0, 0, 0.86);

		// padding-right: 0 !important;
		.modalContent_sml {
			min-height: 35vh !important;
		}

		.modal-content {
			display: flex;
			border-radius: 20px 20px 20px 20px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
			padding: 5%;
			background-image: url(../../img/bubble_bg.png);
			background-position: center right;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 60vh;

			.modal-header {
				border: 0;
				justify-content: center;

				.modal-title {
					img {
						width: 220px;
					}
				}
			}

			.modal-body {
				padding: 10px 0;

				h2 {
					color: #000 !important;
					font-weight: 700;
					font-size: 26px;
				}
			}

			.modal-footer {
				display: block;
				flex-shrink: 0;
				flex-wrap: wrap;
				align-items: center;
				justify-content: start;
				padding: 0;
				border-top: 0;
			}
		}

	}



	.faq_ques {
		width: 100%;

		// display: flex;
		.bg_wrap {
			position: relative;
			background-image: url(../../img/23.png);
			background-size: 51%;
			background-repeat: no-repeat;
			background-position: right bottom;
			overflow: hidden;
			padding: 120px 0;
			background-color: #e7f8ff;

			@media only screen and (max-width: 767px) {
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}
	}

	.comm-head {
		position: relative;

		h2 {
			font-size: 70px;
			font-weight: bolder;
			color: #e4e4e4c2 !important;
			margin-top: -44px;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 0;

			@media screen and (max-width: 540px) {
				display: none;
			}
		}

		h1 {
			font-weight: bold;
			color: #02a1e5 !important;
			position: relative;
			z-index: 1;
			font-size: 2.4rem;

			@media screen and (max-width: 540px) {
				font-size: 1.3rem;
			}
		}
	}


	.accordion-item {
		border-radius: 50px !important;
		border: 0px;
	}

	.align-center {
		align-items: center;
		align-items: center;
	}

	.accordion-button {
		border-radius: 50px !important;
		border: 1px solid #02a1e5 !important;
	}

	.soft-info-thumb {
		position: relative;
		padding-left: 120px;
		padding-top: 100px;

		@media only screen and (max-width: 767px) {
			padding: 0;
			margin-top: 30px;
		}
	}

	.go-premium {
		background: #fff;
		padding: 40px;
		border-radius: 10px;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		box-shadow: 0 3px 40px 0 #0000001a;
		max-width: 330px;

		@media only screen and (max-width: 767px) {
			max-width: 100%;
			position: inherit;
			display: block;
			margin-top: 30px;
		}

		span {
			display: inline-block;
			color: #02a1e5;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 10px;
			font-size: 15px;
		}

		h5 {
			color: #000 !important;
			font-weight: 600;
			margin-bottom: 15px;
			font-size: 20px;
		}
	}

	.user-lists {
		display: flex;
		align-items: center;
		margin-left: 15px;
		margin-top: 30px;

		li {
			margin-left: -15px;
		}

		img {
			height: 50px;
			width: 50px;
			border: 2px solid #fff;
			border-radius: 50% !important;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
			position: relative;
			display: inline-block;
			background: #fff;
			text-align: center;
			line-height: 46px;
			color: #5153ff;
		}
	}

	.btn.btn-theme.secondary {
		color: #fff;
		border: none;
		background: #02a1e5;
	}

	.btn.circle {
		border-radius: 30px !important;
	}

	.btn-sm {
		padding: 16px 50px;
		font-size: 14px;
	}


	// ======================================================
	// SIGNATIRE PAGE DESIGN
	// ========================================================

	.sign-here {
		border-radius: 5px;
		padding: 0 0;
		border: 0;
		height: auto;
		box-shadow: rgba(100, 100, 111, 0.38) 0px 6px 20px 0px;

		.sign-note {
			background: #e7e7e7;
			padding: 15px 25px;
			border-radius: 5px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			img {
				// width: 10px;
			}

			.ulclass {
				padding-left: 0;
				margin-bottom: 20;
				list-style: none;

				.liclass {
					margin-top: 15px;
					position: relative;
					//z-index: 1;
					padding-left: 25px;
					font-size: 13px;

					&::after {
						position: absolute;
						left: 0;
						top: 4px;
						content: "";
						height: 10px;
						width: 10px;
						background: #02a1e5;
						border-radius: 3px;
						opacity: 0.6;
					}
				}
			}
		//sub -Unordered list styles
		.sub_ul {
			list-style: disc; 
			padding-left: 20px !important; 
	
			.sub_li {
				list-style-type: circle ;
			}
		}

			p {
				padding: 0;
				margin: 0;
				line-height: 100%;
			}

			.sign-title {
				font-size: 16px;
			}

			.sml-note {
				font-size: 12px;
				padding: 0;
				margin: 0;
				line-height: 100%;
			}
		}

		.sign-pad {
			background: #ffffff;
			border: 1px solid #b9b9b9;
			padding: 0px 20px;
		}

		canvas#canvas_signature {
			background: #eef5ff;
			width: 100%;
			height: 250px;
			border-radius: 5px;
			margin: 20px 0 10px;
			border: dashed 2px #063783;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
		}
	}

	// signature

	.sign_Btn_paGe {
		@media (max-width: 510px) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 4;
		}

		input {
			@media (max-width: 510px) {
				border-radius: 0 !important;
			}
		}
	}

	.abutdiv {
		// display: none;

		@media (max-width: 768px) {
			display: block;
			z-index: 999;
		}
	}

	.check_box_agree {
		font-size: 15px;
		color: #000;
		font-weight: 600;
		margin: 0;
		padding: 0px 27px 0px;

		.form-check-input {
			width: 1em;
			height: 1em;
			padding: 0;
			margin-top: .25em;
			vertical-align: top;
			background-color: #fff;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			border: 1px solid rgba(0, 0, 0, 0.25);
			appearance: none;
		}

		.form-check-input {
			float: left;
			margin-left: -1.5em;
			animation: mymovecheck 1s infinite;

			&:checked {
				background-color: #021458;
				border-color: #021458;
			}

			&:focus {
				border-color: #021458;
				outline: 0;
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			}
		}

		.blink_red {
			animation: mymovecheckRed 1s infinite !important;
		}

		.blink_green {
			animation: mymovecheckGreen 1s infinite !important;
		}


		label {
			display: inline-block !important;
			font-size: 13px !important;
			font-weight: 600 !important;
			margin-bottom: 5px !important;
		}

		@keyframes mymovecheck {
			50% {
				box-shadow: 0px 0px 40px #063783;
			}
		}

		@keyframes mymovecheckRed{
			50% {
				box-shadow: 0px 0px 10px 4px  red;
			}
		}

		@keyframes mymovecheckGreen{
			50% {
				box-shadow: 0px 0px 10px 4px #12cf1c;
			}
		}
	}

	//check_box_agree


	.clear-btn {
		background: rgb(231, 231, 231);
		border-radius: 5px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 10px 25px 25px;
		height: auto;

		.bt_points {
			position: relative;

			p {
				padding-top: 10px;
				margin: 0;
				display: inline-flex;
				align-items: flex-start;
				gap: 16px;

				img {
					width: 12px;
					padding-top: 4px;
				}
			}

			.sml-note {
				font-size: 12px;
				padding: 0;
				margin: 0;
			}
		}


		.msf-nav-button.clear {
			padding: 8px 14px;
			outline: none;
			border-radius: 5px;
			appearance: none;
			font-size: 18px;
			font-weight: 500;
			color: #082954;
			background: #c7c7c7;
			color: #082954;
			border: unset;
			font-size: 16px;
			font-weight: 600;
		}
	}

	// ======================================================
	// SIGNATIRE PAGE DESIGN
	// ========================================================


	// ======================================================
	// car registrtion  PAGE DESIGN
	// ========================================================

	.sub_head {
		font-size: 22px;
		color: #fff;

		@media (max-width: 560px) {
			font-size: 18px;
		}
	}

	mark {
		padding: 0;
		color: #fff !important;
		background-color: unset !important;
		-webkit-animation: 1s highlight 1s 1 normal forwards;
		animation: 1s highlight 1s 1 normal forwards;
		background-color: none;
		background: linear-gradient(90deg, #15218d 50%, rgba(255, 255, 255, 0) 50%);
		background-size: 200% 100%;
		background-position: 100% 0;
		line-height: 35px;
	}

	@-webkit-keyframes highlight {
		to {
			background-position: 0 0;
		}
	}

	@keyframes highlight {
		to {
			background-position: 0 0;
		}
	}


	.form_drop {
		margin-top: 20px;
		background: #f9fbfc;

		.vehicle_name {
			width: auto;
			display: flex;
			justify-content: space-between;

			.note_cond {
				font-size: 16px;
				font-weight: 700;
				display: block;
				text-align: left;
			}

			//note_cond
			.vehicle_dis_name {
				position: relative;
				text-align: left;

				// display: none;
				span {
					line-height: 160%;
					font-size: 12px;
				}

				//span
				.top_vhName {
					font-size: 16px;
					font-weight: 700;
				}

				//top_vhName
			}

			//vehicle_dis_name
		}

		//vehicle_name
		.searchbox-wrap {
			display: flex;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			font-family: "Poppins", sans-serif;

			@media (max-width: 560px) {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}

			input {
				padding: 15px 10px;
				font-size: 16px;
				border-radius: 0;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border: 1px solid #cfcfcf;
				border-right: 0;
				width: 70%;

				@media (max-width: 768px) {
					width: 100%;
					border-radius: 5px;
					border: 1px solid #cfcfcf !important;
				}
			}

			label {
				background-color: #fff;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				// border: 1px solid #cfcfcf;
				border-left: 0;
				cursor: pointer;
				color: #fff;
				font-size: 16px;
				text-align: center;
				background-color: #02a1e5;
				padding: 14px 20px;
				width: 30%;

				&:hover {
					background-color: #021458;
					box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.19);
				}

				@media (max-width: 768px) {
					width: 100%;
					border-radius: 5px;
					// border: 1px solid #cfcfcf !important;
				}
			}

			//BUTTON
		}

		//searchbox-wrap	
		.vehicle_detail {
			text-transform: uppercase;
			background: #efefef;
			color: #0b334a;
			display: none;
			margin: 20px 0;
			text-align: left;
			line-height: 140%;

			.vh_na_top {
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 5px;
			}

			//vh_na_top
			.vh_clr_typ {
				font-size: 12px;
				margin-bottom: 0;
			}

			//vh_clr_typ
		}

		//vehicle_detail

		#purchase1 {
			.txt_update {
				font-weight: 600 !important;

				@media (max-width: 560px) {
					font-size: 14px !important;
				}
			}
		}

		.Vh_purDeal {
			text-align: left;
			display: none;
			padding: 18px 0 0;

			label {
				font-size: 13px;
				font-weight: 700;
				margin-bottom: 5px;
			}

			//label 
		}

		//Vh_purDeal
	}

	//form_drop

	.btn_sec_control {
		display: flex;
		width: 100%;
		gap: 8px;
		margin-top: 10px;

		.button {
			width: 50%;
			outline: none;
			border: 0;
			background: none;
			padding: 13px 0;
			font-size: 15px;
			font-weight: 700;
			border-radius: 5px;
		}

		//..BUTTON STYLE
		.btn_no_br {
			border: 2px solid #02a1e5;
			color: #02a1e5;

			&:hover {
				color: #021458;
				border: 2px solid #021458;
			}
		}

		//btn_no_br
		.btn_bg_cmn {
			background: #02a1e5;
			color: #fff;
			border: 2px solid #02a1e5;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1);

			&:hover {
				background: #021458;
				border: 2px solid #021458;
			}
		}

		//btn_bg_cmn
	}

	//btn_sec_control


	// ======================================================
	// car registrtion  PAGE DESIGN
	// ========================================================

	// ======================================================
	// ID UPLOAD  PAGE DESIGN
	// ========================================================


	.uploads-section {
		margin-top: 20px;

		h1 {
			font-size: 22px;
		}

		h5 {
			font-size: 16px;
		}


		.uploads {
			border: 2px solid #080D21;
			padding: 15px;
			border-radius: 5px;
			font-size: 1.1rem;
			font-weight: 500;
			background: white;

			.img_pop {
				height: 35px !important;
			}

		}

		.upl-btn {
			padding: 10px 2px;
			background: #002186;
			border: 0;
			border-radius: 5px;
			position: relative;
			font-size: 1rem;
			color: #fff;
			display: flex;
			justify-content: center;

			input {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				right: 0;
				opacity: 0;
			}


		}

		.up-wrap {
			border: 3px dashed #02a1e5;
			border-radius: 20px;
			height: 210px;
			position: relative;
			text-align: center;
			display: flex;
			font-size: 3rem;
			justify-content: center;
			align-items: center;

			.upload-text {
				font-size: 1.2rem;
			}

			img {
				border-radius: 10px;
			}

			.imgloader {
				position: absolute;
				background: #ffffff57;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.progress {
					width: 80%;
					margin-top: 9rem;


					.progress-bar {
						animation: profill 2s ease-in 100ms both;
					}

					@keyframes profill {
						0% {
							width: 0%;
						}

						50% {
							width: 100%;
						}
					}
				}


			}
		}
	}


	// ======================================================
	// ID UPLOAD  PAGE DESIGN
	// ========================================================


















	.btm_sec ._29ahV {
		z-index: 999;
		padding: 70px 0 10px;
		color: #fff;
		background: url(../../img/768b5161fb435eaf5cc833c580a362cc.svg), linear-gradient(90deg, #1a1a1a, #0a0e0c);

		.fot_cont {
			font-size: 11px;
		}
		img{
			@media (max-width: 798px) {
				width: 215px;
			}
		}

		.br-top {
			border-top: 2px solid rgb(161, 161, 161);
		}
	}


	.loader_full_Pg {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 85vh;

		#wifi-loader {
			--background: #627fff;
			--front-color: #02a1e5;
			--back-color: #c3c6de;
			--text-color: #414556;
			width: 64px;
			height: 64px;
			border-radius: 50px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#wifi-loader svg {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		#wifi-loader svg circle {
			position: absolute;
			fill: none;
			stroke-width: 6px;
			stroke-linecap: round;
			stroke-linejoin: round;
			transform: rotate(-100deg);
			transform-origin: center;
		}

		#wifi-loader svg circle.back {
			stroke: var(--back-color);
		}

		#wifi-loader svg circle.front {
			stroke: var(--front-color);
		}

		#wifi-loader svg.circle-outer {
			height: 86px;
			width: 86px;
		}

		#wifi-loader svg.circle-outer circle {
			stroke-dasharray: 62.75 188.25;
		}

		#wifi-loader svg.circle-outer circle.back {
			animation: circle-outer135 1.8s ease infinite 0.3s;
		}

		#wifi-loader svg.circle-outer circle.front {
			animation: circle-outer135 1.8s ease infinite 0.15s;
		}

		#wifi-loader svg.circle-middle {
			height: 60px;
			width: 60px;
		}

		#wifi-loader svg.circle-middle circle {
			stroke-dasharray: 42.5 127.5;
		}

		#wifi-loader svg.circle-middle circle.back {
			animation: circle-middle6123 1.8s ease infinite 0.25s;
		}

		#wifi-loader svg.circle-middle circle.front {
			animation: circle-middle6123 1.8s ease infinite 0.1s;
		}

		#wifi-loader svg.circle-inner {
			height: 34px;
			width: 34px;
		}

		#wifi-loader svg.circle-inner circle {
			stroke-dasharray: 22 66;
		}

		#wifi-loader svg.circle-inner circle.back {
			animation: circle-inner162 1.8s ease infinite 0.2s;
		}

		#wifi-loader svg.circle-inner circle.front {
			animation: circle-inner162 1.8s ease infinite 0.05s;
		}

		#wifi-loader .text,
		.text_2 {
			position: fixed;
			display: flex;
			top: 60%;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			font-size: 21px;
			letter-spacing: 0.2px;

			@media screen and (max-width: 510px) {
				font-size: 13px;
				top: 55%;
			}
		}

		#wifi-loader .text::before,
		.text_2::before,
		#wifi-loader .text::after,
		.text_2::after {
			content: attr(data-text);
		}

		#wifi-loader .text::before,
		.text_2::before {
			color: var(--text-color);
		}

		#wifi-loader .text::after,
		.text_2::after {
			color: var(--front-color);
			animation: text-animation76 3.6s ease infinite;
			position: absolute;
			left: 0;
		}

		@keyframes circle-outer135 {
			0% {
				stroke-dashoffset: 25;
			}

			25% {
				stroke-dashoffset: 0;
			}

			65% {
				stroke-dashoffset: 301;
			}

			80% {
				stroke-dashoffset: 276;
			}

			100% {
				stroke-dashoffset: 276;
			}
		}

		@keyframes circle-middle6123 {
			0% {
				stroke-dashoffset: 17;
			}

			25% {
				stroke-dashoffset: 0;
			}

			65% {
				stroke-dashoffset: 204;
			}

			80% {
				stroke-dashoffset: 187;
			}

			100% {
				stroke-dashoffset: 187;
			}
		}

		@keyframes circle-inner162 {
			0% {
				stroke-dashoffset: 9;
			}

			25% {
				stroke-dashoffset: 0;
			}

			65% {
				stroke-dashoffset: 106;
			}

			80% {
				stroke-dashoffset: 97;
			}

			100% {
				stroke-dashoffset: 97;
			}
		}

		@keyframes text-animation76 {
			0% {
				clip-path: inset(0 100% 0 0);
			}

			50% {
				clip-path: inset(0);
			}

			100% {
				clip-path: inset(0 0 0 100%);
			}
		}
	}

	//loader_full_Pg


	.modal-backdrop {
		z-index: 0 !important;
	}

	.prev-dtls {
		.form-popupdetails {

			@media (max-width: 510px) {
				margin-top: 20px;
			}

			.personal_detail {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;

				@media (max-width: 510px) {
					grid-template-columns: 1fr;
					gap: 0px;
				}
			}

			.choose_Y_N {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 10px;

				@media (max-width: 510px) {
					grid-template-columns: 1fr;
				}
			}

			.options {
				display: flex;
				clear: both;
				width: 100%;
				flex-wrap: wrap;

				li {
					width: auto;
					display: block;
					// float: left;
					margin: 0 10px 10px 0;
				}
			}

			.options.two {
				li {
					width: calc(50% - 10px);
					// float: left;
				}
			}

			//.options.two
			@media only screen and (min-width: 480px) and (max-width: 768px) {
				.options.two.lender li {
					width: 100%;
				}
			}

			@media only screen and (max-width: 480px) {

				.options li,
				.options.two li {
					width: 100%;
					margin: 0 0 10px 0;
				}
			}

			@media only screen and (min-width: 960px) and (max-width: 1200px) {
				.options.two.lender li {
					width: 100%;
				}
			}

			.additional_lenders {
				margin: -9px 0 5px 0;
				display: block;
				text-align: center;
				color: #1f5652 !important;
				cursor: pointer;
				font-size: 18px;
				text-decoration: underline;
				font-weight: 500;

				@media (max-width: 510px) {
					font-size: 16px;
				}
			}

			//additional_lenders

			.anotherlenderoptions {
				label {
					span {
						padding: 0;
						font-size: 14px !important;
					}
				}
			}

			.margin_use {
				margin: 0 10px 10px 0 !important;

				@media (max-width: 510px) {
					margin: 0 0px 6px 0 !important;
				}
			}

			.custom-checkbox {
				display: none;
			}

			label {
				color: #1b1b1b;
				font-size: 22px;
				font-weight: 600;
				display: block;
				clear: both;
				margin-bottom: 13px;
				line-height: 140%;

				@media (max-width: 510px) {
					font-size: 20px;
				}

				span {
					font-size: 18px;
					display: block;
					line-height: 160%;
					padding: 5px 0 0 0;
					clear: both;

					@media (max-width: 510px) {
						font-size: 17px;
					}
				}

			}

			.custom-checkbox:checked+label {
				background: #FFFFFF;
				background: #EBFFEB;
				border: 2px solid #00A400;
			}

			.custom-checkbox:checked+label:before {
				border: 2px solid #00A400;
				// background-color: #00A400;
				content: '';
				background: #00A400 url(../../img/check.png) no-repeat center center;
				background-size: 80%;
			}

			.custom-checkbox+label {
				position: relative;
				cursor: pointer;
				outline: none;
				margin: 0;
				border-radius: 5px;
				width: 100%;
				font-size: 14px;
				font-weight: 600;
				background: #FFFFFF;
				border: 2px solid #080D21;
				padding: 10px 20px 10px 50px;
				height: 50px;
				display: flex;
				align-items: center;
				color: #FFFFFF;
				color: #080D21;

				&:before {
					border: 2px solid #080D21;
					background: #FFFFFF;
					width: 18px;
					height: 18px;
					content: '';
					margin: 0;
					position: absolute;
					top: 13px;
					left: 17px;
					display: block;
					-webkit-border-radius: 100%;
					-moz-border-radius: 100%;
					border-radius: 100%;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
				}

				&:active {
					-webkit-box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
					box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
				}
			}

			//.custom-checkbox + label
			input,
			#idpc_button,
			select,
			textarea {
				width: 100%;
				height: 50px;
				outline: none;
				border-radius: 5px;
				-webkit-appearance: none;
				appearance: none;
				padding: 0 20px;
				font-size: 16px;
				font-weight: 500;
				color: #080D21;
				border: 2px solid #080D21;
				z-index: 30;
			}

			select {
				background: #FFFFFF url(../../img/select.svg) no-repeat right 20px center;
				background-size: 15px 15px;
				-webkit-background-size: 15px 15px;
				-moz-background-size: 15px 15px;
				-o-background-size: 15px 15px;
				padding: 0 50px 0 20px;
			}

			.input-group-text {
				display: flex;
				align-items: center;
				padding: 0.375rem 0.75rem;
				height: 50px;
				margin-bottom: 0;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: #495057;
				text-align: center;
				white-space: nowrap;
				background-color: #e9ecef;
				border-left: 0;
				border: 1px solid #080d21;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}


			// ==============================
			// BUTTON DESIGN
			// ==============================


			.next-but {
				display: inline-block;
				padding: 18px 50px;
				color: #FFFFFF !important;
				font-size: 24px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin: 7px auto !important;
				min-width: 250px !important;
				cursor: pointer;
				text-align: center;
				font-weight: 700;
				background: url(../../img/right-arrow.png)no-repeat center right 44px;
				background-size: 21px;
				background-color: #02a1e5;
				outline: none;
				border: 0;
				height: auto !important;

				@media (max-width: 510px) {
					// background: url(../../img/right-arrow.png)no-repeat center right 70px;
					background-size: 21px;
					background-color: #02a1e5;
					max-width: unset !important;
					padding: 18px 40px;
					font-size: 20px;
				}

				&:hover {
					background-color: #021458
				}
			}



		}

		.modal {
			background: rgba(0, 0, 0, 1);

			// padding-right: 0 !important;
			.modal-popupdetails {
				display: flex;
				border-radius: 20px 20px 20px 20px;
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
				padding: 5%;
				background-image: url(../../img/bubble_bg.png);
				background-position: center right;
				background-repeat: no-repeat;
				background-size: contain;

				.modal-header {
					border: 0;
					justify-content: center;

					.modal-title {
						img {}
					}
				}

				.modal-body {
					padding: 10px 0;

					h2 {
						color: #02a1e5 !important;
						font-weight: 700;
						font-size: 24px;
						margin-bottom: 20px;
						text-align: center;

						@media (max-width: 768px) {
							font-size: 18px;
							text-align: center;
						}
					}

					.fs-6 {
						font-size: 1rem !important;
						margin: 10px 0;
					}

					.name-col {
						width: 100%;
						background: #fff url(../../img/name_bg.png) no-repeat left top;
						background-size: 20px;
						background-position: 5px 20px;
						padding: 20px;
						padding-left: 35px;
						border-radius: 10px;
						display: inline-block;
						box-shadow: 0 3px 40px 0 #0000001a;
						margin: 10px 0;

						p {
							margin: 0px;
						}
					}

					.address-col {
						width: 100%;
						background: #fff url(../../img/loc_bg.png) no-repeat left top;
						background-size: 25px;
						background-position: 5px 20px;
						padding: 20px;
						padding-left: 35px;
						border-radius: 10px;
						display: inline-block;
						box-shadow: 0 3px 40px 0 #0000001a;
						margin: 10px 0;

						p {
							margin: 0px;
						}
					}
				}

			}

		}
	}

	.prev-add-btn {
		border: none;
		color: white;
		background-color: #02a1e5;
		padding: 3px;
		border-radius: 8px;
	}

	.field-set{
		border: 2px solid #000;
		padding: 8px 10px 10px 10px;
		border-radius: 5px;
		margin-bottom: 10px;
		legend {
			width: auto;
			padding: 0 10px;
			float: initial;
			margin: 0;
			color: #000;
			font-weight: 600;
		}
	}
	.btn-sticky{
		position: sticky !important;
    	bottom: 0px !important;
	}
	.btn-relative{
		position: relative !important;
	}

	.css-26l3qy-menu {
		position: relative;
		z-index: 1;
	}

	.sublender-div {
		background: #EBFFEB;
		width: 100%;
		padding: 10px;
		border: 2px solid #00A400;
		border-radius: 7px;
		position: relative;
		margin-top: -15px;
		border-top: 0;
		padding-top: 20px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-bottom: 15px;

		p {
			font-size: 14px;
			margin-bottom: 4px;
			border-bottom: dashed 1px #00A400;
			padding-bottom: 4px;
		}

		.b-0 {
			border: 0px;
		}
	}
	.otheroption{
		border: unset !important;
	}

	.font-11{
		font-size: 11px;
	}

	.sublender-pop {
		background: #edae9d;
		margin-top: -22px;
		border: solid 2px #df4514;
		border-top: 0px;
	}

	.selected-defendant {
		width: 70px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.group-button {
			background: none;
			border: none;
			color: #000;
			font-size: 12px;
			padding-right: 25px;
			position: relative;
			float: right;
			height: 50px;
			width: 100%;
			font-weight: 500;
			cursor: pointer;
			z-index: 99;

			&::after {
				background: url("../../img/down-arrow.png") no-repeat;
				background-size: contain;
				content: "";
				height: 50px;
				position: absolute;
				right: 7px;
				top: 39%;
				transition: all .15s ease;
				width: 12px;
				cursor: pointer;
				color: #000;
			}
		}
	}
}

.loader-modal {
	.modal-content {
		border: none !important;
		border-radius: 0px !important;
		background-image: none !important;
		box-shadow: none !important;
	}
}