
.nobg{
    background: none !important;
}

.thankyou{
    text-align: center;
   h2{
    font-size: 36px !important;
    color: #ffffff;
    font-weight: bold;
    margin: 15px 0;
    &::after{
        display: none !important;
    }
   } 
   h3{
    font-size:17px;
    color: #ffffff;
    font-weight: 600;
   }
   img{
    margin-bottom: 30px;
   }
}

.supagebg{
    background:url("../../img/TCGL_V_A1/loaderpagebg-desktop.jpg") no-repeat top center #1891a5;
    background-size: cover;
    min-height:100vh;
    overflow-x: hidden;
    position: relative;
  }
  
  .supagebg1{
    background:url("../../img/TCGL_V_A1/sub-page-bg.jpg") no-repeat top center #1891a5;
    background-size: cover;
    min-height:100vh;
    overflow-x: hidden;
    position: relative;
  }

@media screen and (max-width: 768px) {


    .thankyou{
        text-align: center;
       h2{
        font-size: 20px !important;
        margin: 15px 0;
        &::after{
            display: none !important;
        }
       } 
       h3{
        font-size:16px;
        font-weight: 500;
       }
    }

}