.edit-page {
    .editpage-banner {
        background: #ffffff;
        // min-height: 95vh;
        //overflow-x: hidden;
        position: relative;
        padding: 5px 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    hr {
        margin: 8px 0 !important;
    }

    .editcol-details {
        border-bottom: solid 1px #8b8b8b;
        padding: 0 0 8px 0;
        margin-bottom: 8px;
        color: #212529;

        p {
            color: #212529;
            margin-bottom: 8px;

            strong {
                color: #212529 !important;
            }
        }

    }

    .btn-editdetail {

        background: #fff;
        border-radius: 30px;
        color: #1F1F1F;
        border: 1px solid #2145DD;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        text-align: center;
        margin: 10px 0 20px;
        width: 100% !important;
        display: inline-block;

        &:hover {
            background: #fbda53;
        }

    }


    @media screen and (max-width: 768px) {}
    .btn-back {
        background: #fff;
        border-radius: 30px;
        color: #1F1F1F;
        border: 1px solid #2145DD;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        text-align: center;
        margin: 10px 0 20px;
        width: 100% !important;
        display: inline-block;
        min-width: 150px;
      }


      .blue-btn{
        background: #2145dd;
        @media screen and (max-width: 768px) {
            min-width: inherit !important; 
        }

      }
      .choose_date {
        display: flex;
        width: 100%;
        padding: 0 0px;
        flex-direction: column
    }
    
    @media (max-width: 768px) {
        .choose_date {
            flex-direction:column;
            gap: 4px
        }
    }
    
    .choose_date .form-check {
        width: 100%;
        margin-bottom: 10px
    }
    
    .choose_date .form-check [type="radio"] {
        display: none
    }
    
    .choose_date .form-check label {
        display: block;
        padding: 13px 60px;
        background: #fff;
        border: 2px solid #1A3ECA;
        box-shadow: 0 12px 10px -10px rgba(0,0,0,0.4);
        border-radius: 10px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #1A3ECA;
        white-space: nowrap;
        position: relative;
        transition: 0.4s ease-in-out 0s
    }
    
    @media (max-width: 768px) {
        .choose_date .form-check label {
            display:block;
            padding: 13px 10px 13px 50px;
            width: 100%;
            white-space: inherit
        }
    }
    
    .choose_date .form-check label:after {
        height: 19px;
        width: 19px;
        border: 2px solid #1A3ECA;
        left: 19px;
        top: calc(50% - 10px)
    }
    
    .choose_date .form-check label:before {
        background: #1A3ECA;
        height: 11px;
        width: 11px;
        left: 23px;
        top: calc(50% - 6px);
        transform: scale(5);
        opacity: 0;
        visibility: hidden;
        transition: 0.4s ease-in-out 0s
    }
    
    .choose_date .form-check [type="radio"]:checked ~ label {
        border-color: #1A3ECA;
        box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
        background: #1A3ECA;
        color: #ffffff
    }
    
    .choose_date .form-check [type="radio"]:checked ~ label::before {
        background: #fff;
        opacity: 1;
        visibility: visible;
        transform: scale(1)
    }
    
    .choose_date .form-check [type="radio"]:checked ~ label::after {
        border: 2px solid #fff
    }
    
    .choose_date .form-check label:after,.choose_date .form-check label:before {
        content: "";
        position: absolute;
        border-radius: 50%
    }    
    .sticky-btnwrap {
        position: sticky;
        position: -webkit-sticky;
        bottom: 0; 
    }
    .add-btn {
        cursor: pointer;
    }

}