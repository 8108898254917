.thankyou{
    header{
        background-color: #257eba;
        padding: 20px 0px;

        img{
            height: 60px;
        }
    }

    section{
        padding: 100px 0px;


        h5{
            font-size: 40px;
            font-weight: 700;
        }
    }

    .fixd-ftr{
        position: fixed;
        width: 100%;
        bottom: 0px;
    }

    @media (max-width: 767px) {
        .fixd-ftr{
            position: relative;
        }
    }

    footer {
        padding: 25px 0;
        background: #333;
      
        p {
          color: #beb8b8;
          font-size: 14px;
        }
      
        ul {
          padding: 0;
          margin-top: 15px;
      
          li {
            list-style: none;
            border-right: 1px solid #beb8b8;
            padding: 0 10px;
            display: inline;
      
            &:last-child {
              border-right: none;
            }
      
            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
      .loader{        
        // font-family: $font_family_1;
        img{
            width: 100px;
            margin-top: 20px;
        }
        p{
            color: #2f3996;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            margin-top: 10px;
        }
        .btn-next {
            background: #69a2d7;
            color: #fff;
            font-weight: 400;
            font-size: 20px;
            padding: 15px 10px;
            width: 100%;
            cursor: pointer;
            border: 0;
            border-radius: 5px!important;
            float: right;
            margin: 15px 0;
            text-decoration: none;
            &:hover{
                background-color: #2387e3;
            }
        }
    }

}