
// preloader
$size : 300px;
$duration: 2s;
$logo-color: grey;
$background: linear-gradient(0deg, #1c3dc7 0%, rgba(20, 7, 91, 0.2) 100%);

.bg_preldr {
    height: 100dvh;
    display: grid;
    place-content: center;
    background: #1c3dc7;
    color: #fff;
    .loadtext{
      margin-top: 50px;
      width: 100%;
      text-align: center ;
      color: #fff ;
      position: absolute ;
      bottom: 6% ;
      
      p{
        text-align: center;
      }
    }
}

.preloader {
    height: $size;
    aspect-ratio: 1;
    // background: black;
    position: relative;
    
    .box {
      position: absolute;
      background: rgba(100, 100, 100,0.15);
      background: $background;
      border-radius: 50%;
      border-top: 1px solid rgba(255, 255, 255, 0.638);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
      backdrop-filter: blur(5px);
      animation: ripple $duration infinite ease-in-out;
      
      &:nth-child(1){
        inset: 40%;
        z-index: 99;
      }
      &:nth-child(2){
        inset: 30%;
        z-index: 98;
        border-color: rgba(255, 255, 255, 0.66);
        animation-delay: 0.2s;
      }
      &:nth-child(3){
        inset: 20%;
        z-index: 97;
        border-color: rgba(255, 255, 255, 0.392);
        animation-delay: 0.4s;
      }
      &:nth-child(4){
        inset: 10%;
        z-index: 96;
        border-color: rgba(255, 255, 255, 0.29);
        animation-delay: 0.6s;
      }
      &:nth-child(5){
        inset: 0%;
        z-index: 95;
        border-color: rgba(255, 255, 255, 0.126);
        animation-delay: 0.8s;
      }
    }
    
    .logoloader {
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;
      padding: 30%;
      text-align: center;
      svg {
        fill: $logo-color;
        width: 100%;
        animation: color-change $duration infinite ease-in-out;
      }    
    }
  
  }
  
  @keyframes ripple {
    0% {transform: scale(1); box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px; }
    50% {transform: scale(1.3); box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 20px -0px;}
    100% { transform: scale(1); box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;}
  }
  
  @keyframes color-change {
      0% { fill: $logo-color; }
      50% { fill: white; }
      100% { fill: $logo-color; }
  }

// preloader end