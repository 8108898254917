.car-reg-page{
    .vehicle_detail {
        text-transform: uppercase;
        background: #efefef;
        color: #0b334a;
        display: none;
        margin-top: 20px;
        text-align: left;
        line-height: 140%;
        border-radius: 10px;
        .vh_na_top {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        .vh_clr_typ {
            font-size: 12px;
            margin-bottom: 0;
        }
    }

    .anim_blink {
        transition: all ease-in 0.5s;
        animation: pulse2 1500ms infinite
    }
    
    @keyframes pulse2 {
        0% {
            box-shadow: #197def 0 0 0 0
        }
    
        75% {
            box-shadow: #ff69b400 0 0 0 12px
        }
    }

.btn-banner{
    min-width: 100% !important;
}
.bankname{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    img{
        margin-right: 10px;
    }
}

.supagebg1{
    background-image: url("../../img/TCGL_V_A1/sub-page-bg.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.subpageheader{
    background: none !important;
    box-shadow: none !important;
    text-align: center;
    padding: 15px 0 !important;
    img{
        max-height: 45px;
    }
}
.nobg{
    background: none !important;
}
.bg-lightblue{
    background-color: #E5F1FF !important;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.form-header-sub{
    background: rgb(206,214,255);
    background: linear-gradient(0deg, rgba(206,214,255,1) 0%, rgba(195,223,255,1) 100%);
    padding: 15px 0;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-top: solid 7px #ffffff;
    margin-bottom:15px;
    h4{
        color: #A0A0A0;
        span{
            color: #000;
        }
    }
    h5{
        color: #000;
        font-size: 18px;
        font-weight: 600;
    }
    p{
        font-size: 16px;
        color: #424242;
        margin: 0px;
        font-weight: 600;
        margin: 10px auto;
    }

    img{
        max-height: 60px;
        border-radius: 7px;
        -webkit-box-shadow: -4px 16px 5px -16px rgba(0,0,0,0.5);
        -moz-box-shadow: -4px 16px 5px -16px rgba(0,0,0,0.5);
        box-shadow: -4px 16px 5px -16px rgba(0,0,0,0.5);
    }
}

.regdetailcol{
    background-color: #FFFFFF;
    border: solid 1px #D1D1D1;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 25px;
    border-radius: 10px;
    p{
        font-size: 13px;
        margin: 0px !important;
    }
    .green-text{
        color: #137717;
    }
    h3{
        font-size: 16px !important;
        font-weight: 600;
        margin: 0px 0 20px !important;
        text-align: left;
        img{
            margin-right: 15px;
            max-height: 30px;
        }
    }
    h4{
        font-size: 16px !important;
        img{
            max-height: 30px;
    margin-right: 15px;
        }
    }
}


.material-textfield {
    position: relative;
    margin-top:15px;
    margin-bottom:10px;

    &:before {
        @media screen and (max-width: 768px) {top: 29px;}
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left:0px;
        color: #0A0A0A;
        background: #0267C1 url(../../img/TCGL_V_A1/reg_uk.svg) no-repeat center center;
        -webkit-background-size: 15px 25px;
        -moz-background-size: 15px 25px;
        -o-background-size: 15px 25px;
        background-size: 15px 25px;
        width: 40px;
        height:59px;
        content: '';
        z-index:99;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
        label {
            position: absolute;
            font-size: 16px;
            font-weight: 800;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #6e6e6e;
            padding: 3px 9px 3px 44px;
            margin: 0 0.5rem;
            transition: .1s ease-out;
            transform-origin: left top;
            pointer-events: none;
            border-radius: 5px;
            @media screen and (max-width: 768px) {font-size: 14px;     top: 29px;}
        }
        input {
            font-size: 19px;
            outline: none;
            border: 2px solid #B1B1B1;
            border-radius: 10px !important;
            height: 58px;
            transition: 0.1s ease-out;
            padding: 0 45px;
            text-transform: uppercase;
            font-weight: 700;
            background: #FFFFFF;
            box-shadow: none;
            margin: 0px !important;
            @media screen and (max-width: 768px) {border-radius: 5px !important;}
            &:focus {
                border-color: #afafaf;  
            }
            &:focus + label {
                color: #6e6e6e;
                top: -11px;
                transform: translateY(-50%) scale(0.9);
                padding: 11px 9px 13px 9px;
                margin-left: 45px;
                font-weight: 700;
                font-size: 15px;
            }
            &:not(:placeholder-shown) + label {
                top: -11px;
                transform: translateY(-50%) scale(.9);
                padding: 3px 9px 3px 9px;
                margin-left: 45px;
            }
            @media  (max-width: 510px){

            }
        }
}//material-textfield

.reg_lookup_but{
display: inline-block;
background:#303030;
color: #fff !important;
padding:0px;
border-radius: 10px !important;
border: 0;
width:23%;
font-size:16px;
font-weight: 600;
margin-left: 10px !important;
cursor: pointer;
transition: all .3s ease-in-out;
text-align: center;
@media screen and (max-width: 768px) {
}
&:hover{
    background: #01b636;
}
}

.regButton {
background-image: url(../../img/TCGL_V_A1/right-chevron.svg) !important;
background-size: 17% 42px !important;
background-repeat: no-repeat !important;
background-position: 100% !important;
display: inline-block;
background: #303030;
color: #fff !important;
padding: 15px 0px;
border-radius: 30px;
border: 0;
font-size: 20px;
width: 50%;
// max-width: 200px;
font-weight: 600;
cursor: pointer;
transition: all .3s ease-in-out;
text-align: center;
&:hover{
    background: #01b636;
}
}
.btn-back{
    background: #fff;
    border-radius: 30px;
    color: #1F1F1F;
    border: 1px solid #2145DD;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    text-align: center;
    margin: 10px 0 20px;
    width: 100% !important;
    display: inline-block;
}

.form-header {
    padding: 15px 0;
    border-bottom: solid 1px #BABABA;
    margin-bottom: 35px;

    h4 {
        color: #A0A0A0;

        span {
            color: #000;
        }
    }

    h5 {
        color: #000;
        font-size: 18px;
        font-weight: 600;
    }

    p {
        font-size: 14px;
        color: #000;
        margin: 0px;
    }

    .bor-l {
        border-left: solid 1px #BABABA;
        padding: 10px 15px;
    }

    img {
        max-height: 60px;
    }
}

.remove-but{
    border: 0px;
    background: #fff;
    position: absolute;
    z-index: 999;
    right: 5px;
    top: 12px;
    cursor: pointer;
}

.choose_date{
	display: flex;
	width: 100%;
	padding: 0 0px;
    flex-direction: column;


	@media (max-width:768px){
		flex-direction: column;
		gap: 4px;
	}
/////////////////////////// form check /////////////////////////////////
.form-check {
    width: 100%;
	margin-bottom: 10px;
	[type="radio"] {
		display: none;
	}

	label {
		display: block;
		padding: 13px 60px;
		background: #fff;
		border: 2px solid #1A3ECA;
		box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);
		border-radius: 10px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		color: #1A3ECA;
		white-space: nowrap;
		position: relative;
		transition: 0.4s ease-in-out 0s;
		@media (max-width: 768px){
			display: block;
			padding: 13px 10px 13px 50px;
			width: 100%;
			white-space: inherit;
		}
		&:after{
			height: 19px;
			width: 19px;
			border: 2px solid #1A3ECA;
			left: 19px;
			top: calc(50% - 10px);
		}
		&:before {
			background: #1A3ECA;
			height: 11px;
			width: 11px;
			left: 23px;
			top: calc(50% - 6px);
			transform: scale(5);
			opacity: 0;
			visibility: hidden;
			transition: 0.4s ease-in-out 0s;
		}
	}
	[type="radio"]:checked {
		& ~ label {
			border-color: #1A3ECA;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			background: #1A3ECA;
			color: #ffffff;
			&::before {
				background: #fff;
				opacity: 1;
				visibility: visible;
				transform: scale(1);
			}
			&::after{
				border: 2px solid #fff;
			}
		}
	}
}
.form-check label:after,
.form-check label:before {
	content: "";
	position: absolute;
	border-radius: 50%;
}
///////////////////////// form check /////////////////////////////////
}//choose_bw

.carregform-modal {
    .modal-body{
        h3 {
            font-size: 20px !important;
            font-weight: 700;
            margin: 10px 0 20px;
            text-align: center;
        }
    }
    
}
@media screen and (max-width: 768px) {
    .form-header-sub{
        img{
            max-height: 35px;
        }
    }


}
}