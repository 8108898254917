// @import "personal-details.scss";
// @import "signature.scss";
// @import "lenders.scss";
// @import "car-registration.scss";
// @import "id-upload.scss";
// @import "thankyou.scss";
// @import "loader.scss";
// @import "credit-analysis.scss";
// @import "chart.scss";
// @import "edit-details.scss";
// @import "try_again.scss";
// @import "selected-lenders.scss";
// @import "slider.scss";

/*
*******************************************
Template Name: Lookup Finance
Author: Abhilash
Date: 28-09-2024
Template By: Vandalay Designs
Copyright 2024-2025 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$color1: #2145dd;
$color2: #f3d766;
$color3: #111111;

$black-color: #000;
$white-color: #fff;

$font_family_1: "Poppins", sans-serif !important;

/*================================================
02 - Sass Mixins
==================================================*/
@mixin btn-style1 {
  background: #0c6c0a;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  max-width: 275px;
  display: block;
  text-align: center;
  -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  margin: 10px auto 20px;
}

/*================================================
03 - BODY STYLES
==================================================*/

$color_1: #fff;
$background-color_1: #2145dd;

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

body {
  font-family: $font_family_1;
  color: $black-color;
}

.TCGL_V_A1 {
  ul,
  li {
    list-style-type: none;
  }

  a {
    text-decoration: none !important;
  }

  /*================================================
04 - HEADER 
==================================================*/

  @mixin clearfix() {

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  header {
    background: #ffffff;
    -webkit-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
    position: relative;
    z-index: 999;
    padding: 10px 0;
  }

  .reviews-io-rating {
    align-items: center;
    display: flex;
    flex-direction: row;
    grid-gap: 0.25em;
    gap: 0.25em;
    justify-content: center;
    pointer-events: all;
    width: auto;

    .reviews-io-rating--logo {
      filter: invert(1);
      display: none;
    }
  }

  .video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the video scales and covers the full area */
    z-index: -1;
    /* Places the video behind content */
  }

  .bner {
    min-height: 110vh !important;
    margin-bottom: -150px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1399px) {
    .bner {
         min-height: 125vh !important;
         margin-bottom: -158px;
     }
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .video_play {
          position: relative;
          top: -55px;
  }
}

  .banner {
    background: #ffffff;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-weight: 900;
      color: #000;
      // font-size: 35px;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 28px;

      &::after {
        width: 40%;
        content: " ";
        height: 5px;
        top: 2px;
        background-color: #f3d766;
        position: relative;
        display: block;
        border-radius: 5px;
        -webkit-animation: bounce-x 3s infinite linear;
        animation: bounce-x 3s infinite linear;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin: 15px 0;
    }

    p {
      strong {
        color: #2145dd;
        font-weight: bolder;
      }
    }

    @keyframes bounce-x {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      50% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
      }

      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }

    .nowin {
      background-color: #f3d766;
      color: #594906;
      font-size: 20px;
      border-radius: 30px;
      text-align: center;
      font-weight: 600;
      display: inline-block;
      min-width: 280px;
      padding: 15px 10px 15px 62px;
      margin-bottom: 25px;
      position: relative;

      span {
        background-color: #2145dd;
        border-radius: 100px;
        position: absolute;
        left: 0;
        padding: 15px;
        top: 0;
        width: 62px;
      }
    }
  }

  /// STICKY SCROLL ANIMATION DESIGN

  .news-ticker {
    background: #dddddd;
    color: #2f2f2f;
    overflow: hidden;
    height: 50px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      height: 35px;
    }
  }

  .ticker-content {
    display: flex;
  }

  .logos {
    display: none;
    margin-right: 20px;
  }

  .logos.active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .logos .GuardianLogo {
    max-width: 130px;
    background: #d4d4d4;
    position: relative;
    z-index: 4;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }

  .logos .bbcLogo {
    max-width: 234px;
    background: #d4d4d4;
    position: relative;
    z-index: 4;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 127px;
    }
  }

  .logos .telegraphLogo {
    max-width: 169px;
    background: #d4d4d4;
    position: relative;
    z-index: 4;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }

  .contenty {
    color: #000;
    white-space: nowrap;
    /* Ensures content stays in a single line */
    overflow: hidden;
    /* Hides any overflowing content */
    text-overflow: ellipsis;
    /* Adds ellipsis (...) if content is too long */
    animation: ticker-animation 15s linear infinite;
  }

  @keyframes ticker-animation {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .pos_stick {
    z-index: 4;
    transition: transform 0.5s ease-in-out;
    top: 0;
    position: absolute;
    width: 100%;
  }

  .smooth_bu {
    position: relative;
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
    /* Updated transition */
  }

  //

  .stepsec {
    background-color: #2145dd;
    padding: 30px 15px;
    color: #ffffff;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;

    h2 {
      font-size: 20px;
    }

    img {
      max-height: 90px;
      transition: transform 0.7s ease-in-out;
    }

    &:hover {
      img {
        transform: rotate(360deg);
      }
    }
  }

  .you-claim {
    background-color: #e7f8ff;
    padding: 60px 15px;
    color: #0e0e0e;
    position: relative;

    &:hover {
      .youcliam-icons {
        transform: rotate(360deg);
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    h5 {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      margin: 0 0 15px;
    }

    .youcliam-icons {
      height: 80px;
      width: 80px;
      margin: 15px auto;
      background-color: #2145dd;
      padding: 10px;
      border-radius: 100px;
      align-items: center;
      justify-content: center;
      display: flex;
      transition: transform 0.7s ease-in-out;

      img {
        transition: transform 0.7s ease-in-out;

        &:hover {
          transform: rotate(360deg);
        }
      }
    }

    .blue-color {
      color: #2145dd !important;
    }

    .bounce-y {
      -webkit-animation: bounce-y 10s infinite linear;
      animation: bounce-y 10s infinite linear;
    }

    .experience {
      position: absolute;
      left: 50px;
      bottom: 100px;
      min-width: 220px;
      background: #2145dd;
      padding: 15px 30px 25px;
      border-radius: 10px 10px 0 10px;
      -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
      color: #fff;

      &::before {
        position: absolute;
        right: -20px;
        bottom: 0;
        border-left: 20px solid #2145dd;
        border-top: 20px solid transparent;
        content: "";
      }
    }

    @keyframes bounce-y {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }

      50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }

      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }

  .faqsection {
    position: relative;

    h2 {
      color: #2145dd;
      font-weight: 600;
    }
  }

  // BUTTON

  .btn-web {
    @include btn-style1;

    &:hover {
      background: #35c730;
      transform: translate(0px, -10px) scale(1.1);
      -webkit-transform: translate(0px, -10px) scale(1.1);
      -moz-transform: translate(0px, -10px) scale(1.1);
      -ms-transform: translate(0px, -10px) scale(1.1);
      -o-transform: translate(0px, -10px) scale(1.1);
      transition: all ease-in-out 300ms;
    }
  }

  .btn-banner {
    @include btn-style1;
    margin: 10px 0 20px;
    border: 0px;
    min-width: 350px;
    font-size: 22px;
    display: inline-block;

    &:hover {
      background: #35c730 !important;
    }

    span {
      margin-left: 20px;
    }
  }

  .zoom-in-zoom-out {
    animation: zoom-in-zoom-out 2s ease-out infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(0.8, 0.8);
    }

    50% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(0.8, 0.8);
    }
  }

  .fadein-eff {
    opacity: 0;
    transition: all 1.5s;
    transform: translate(0, 100px);
  }

  .fadein-eff.visible {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media screen and (max-width: 579px) {}

  @media screen and (max-width: 991px) {}

  /* animation class and keyframes */
  .overflow-hidden {
    overflow: hidden;
  }

  .drop-in {
    animation: drop-in 1s ease 200ms backwards;
  }

  .drop-in-2 {
    animation: drop-in 1200ms ease 500ms backwards;
  }

  .drop-in-3 {
    animation: drop-in 2000ms ease 100ms backwards;
  }

  @keyframes drop-in {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  /*================================================
 HEADER END
==================================================*/

  /*================================================
04 - BANNER
==================================================*/

  .pulse {
    animation: pulse-animation 2s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(41, 148, 209, 0.5);
    }

    50% {
      box-shadow: 0 0 0 20px rgba(41, 148, 209, 0.2);
    }

    100% {
      box-shadow: 0 0 0 0px rgba(41, 148, 209, 0);
    }
  }

  /*================================================
footer start
==================================================*/
  footer {
    background: $color3;
    color: $color_1;
    font-size: 0.8rem;

    .link-list {
      color: #fff;
      margin-bottom: 10px !important;

      li a {
        color: #fff;
      }
    }

    ul {
      li {
        display: inline-block;

        a {
          padding: 0 15px 0 0;
        }
      }
    }

    p {
      a {
        color: #1c33ff;
        text-decoration: underline;
      }
    }
  }

  .btm_sec ._29ahV {
    z-index: 999;
    padding: 70px 0 10px;
    color: #fff;
    background: url(../../img/TCGL_V_A1//dots.svg), linear-gradient(90deg, #1a1a1a, #0a0e0c);

    .fot_cont {
      font-size: 11px;
    }

    img {
      width: 220px !important;

      @media (max-width: 798px) {
        width: 200px !important;
      }
    }

    .br-top {
      border-top: 2px solid rgb(161, 161, 161);
    }
  }

  /*================================================
footer end
==================================================*/

  /*================================================
07 - MEDIA QUERY 
==================================================*/
  @media screen and (min-width: 1200px) {}

  @media screen and (max-width: 1200px) {}

  @media screen and (max-width: 991px) {}

  @media screen and (max-width: 768px) {
    header {
      .header-logo {
        height: 24px;
        margin-top: 5px;
      }

      .header-logo-2 {
        height: 15px;
      }

      .header-logo-3 {
        height: 35px;
      }
    }

    .bner {
      min-height: 85vh !important;
      margin-bottom: 0px;
      padding-bottom: 0px !important;
    }

    .comm-head {

      h2 {
        font-size: 55px !important;
      }
    }

    .banner {
      min-height: 75vh;
      text-align: center;
      padding-bottom: 160px;

      h2 {
        font-size: 16px;

        &::after {
          height: 4px;
          width: 54%;
          left: 20%;
        }

        sup {
          font-size: 12px;
          top: -12px;
          margin-left: 2px;
        }
      }

      .nowin {
        font-size: 16px;
        padding: 10px 10px 10px 62px;

        span {
          padding: 7px;
          width: 45px;
          text-align: center;
        }
      }
    }

    .banner-subpage {
      min-height: 95vh;
    }

    .stepsec {
      img {
        max-height: 45px;
      }

      h2 {
        font-size: 14px;
        padding: 0 10px;
      }
    }

    .you-claim {
      text-align: center;

      h3 {
        font-size: 22px;
      }

      h4 {
        font-size: 17px;
      }
    }

    .faqsection {
      &::before {
        background-size: cover;
      }

      .title-sec {
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }

  //end media query //

  .acdn {
    .accordion {
      .accordion-item {
        border: #02a1e5 1px solid;
        margin-bottom: 7px;
        padding: 5px;

        .accordion-body {
          border-radius: 24px;
          background-color: #fff;
          text-align: left;
          font-size: 13px;
          border: none;
        }

        border-radius: 26px;

        &:first-of-type {
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
        }

        &:last-of-type {
          border-bottom-right-radius: 26px;
          border-bottom-left-radius: 26px;

          .accordion-button {
            &.collapsed {
              border-bottom-right-radius: inherit;
              border-bottom-left-radius: inherit;
            }
          }
        }

        .accordion-header {
          display: flex;
          align-items: center;
          padding: 0px;
          background-color: #0031ff00;
          border-radius: 26px;
          border: none;
        }

        .accordion-button {
          position: relative;
          display: flex;
          width: 100%;

          font-size: 14px;

          text-align: left;

          border: 0;
          border-radius: 26px;
          overflow-anchor: none;
          transition: var(--bs-accordion-transition);
          flex-direction: row;
          align-items: center;
          padding: 10px;
        }
      }
    }
  }

  .soft-info-thumb {
    position: relative;
    padding-left: 134px;
    padding-top: 0px;
  }

  .go-premium {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -75px;
    box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.101961);
    max-width: 330px;
  }


  .bg_wrap {
    .go-premium {
      span {
        display: inline-block;
        color: #ed6843;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 15px;
      }

      h5 {
        color: #000 !important;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 20px;
      }
    }

    .user-lists {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-top: 30px;

      li {
        margin-left: -15px;
      }

      img {
        height: 50px;
        width: 50px;
        border: 2px solid #fff;
        border-radius: 50% !important;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        display: inline-block;
        background: #fff;
        text-align: center;
        line-height: 46px;
        color: #5153ff;
      }
    }

    .btn {
      &.btn-theme.secondary {
        color: #fff;
        border: none;
        background: #ed6843;
        border-radius: 20px;
      }
    }
  }













  .btn-web {
    max-width: 300px !important;
  }

  .vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
  }

  @-webkit-keyframes mover {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes mover {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  .comm-head {
    position: relative;

    h2 {
      font-size: 70px;
      font-weight: bolder;
      color: #daf0f9 !important;
      margin-top: -44px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 0;
    }

    h1 {
      font-weight: 700;
      color: #02a1e5 !important;
      position: relative;
      z-index: 1;
      font-size: 2.4rem;
    }
  }


  .flx {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}