.TCG_L_B {
  
/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 18-01-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - HEADER
05 - RANGE SLIDER
06 - form-part
07 - MEDIA QUERY

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========
$font_family_1: "Poppins", sans-serif !important;
body {
  font-family: $font_family_1;
}


  // @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

  $color1: #063783;
  $color2: #ec6842;
  $color3: #D9D9D9;
  $color4: #1FCCF2;

  $black-color: #000;
  $white-color: #fff;



  $font_family_1: "Poppins", sans-serif !important;



  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $font-w600: 600;
  $font-w700: 700;


  /*================================================
02 - Sass Mixins
==================================================*/


  body {
    font-family: $font_family_1;
  }
  .error_msg {
    color: white;
    margin-bottom: 2px;
    width: 100%;
    padding: 1px 5px;
    background: #ff0000;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
  }
  .blink_red {
    animation: mymovecheckRed 1s infinite !important;
  }

  .blink_green {
    animation: mymovecheckGreen 1s infinite !important;
  }


  ul {
    li {
      list-style-type: none;
    }
  }


  .btn-comm {
    background: $color2;
    padding: 20px 40px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: $white-color;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    border: none;

    &:disabled {
      background: #b1b0b0;
    }

    &:hover {
      background: $black-color;
      color: $white-color;

      &::after {
        transform: translateX(0%);
        transition: all ease-in 0.3s;
      }


    }
  }

  /////////////////////////////////////////

  .logo_img {
    width: 220px;

    @media (max-width: 510px) {
      width: 200px;
    }
  }

  .logo_img_ty {
    width: 195px;

    @media (max-width: 510px) {
      width: 200px;
    }
  }
  
  .header-wrap {
    background: $color1;

    .logo {
      img {
        height: 30px;
      }
    }
  }


  .dashboad-top {
    background: $color1;

    .user-profile {
      .dp-inner {
        border-radius: 20px;

        h2 {
          font-size: 1.2rem;
          font-weight: 600;
        }

        .age {
          font-size: 14px;
          color: #505050;
        }

        .address {
          font-size: .8rem;
        }
      }

      .dp {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 3px solid $color3;

        img {
          border-radius: 50%;
          width: 70px;
          height: 70px;
        }
      }


    }
  }

  .homebanner {
    background: $color1;
    color: $white-color;
  }

  .dashboad-middle {
    background: $color1;

    .middle-sec {
      background: $color2;
      padding: 15px;
      border-radius: 20px;
      border: 2px solid #FFE6A4;

      h1 {
        font-size: 1.3rem;
      }

      .dis-icon {
        gap: 0 15px;

        li {

          .lops {
            line-height: 20px;
            line-height: 22px;

            .fs {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .wrap-lops {
      position: relative;
    }
  }

  .close-btn {
    font-size: 26px;
    position: absolute;
    right: 19px;
    top: 15px;
    cursor: pointer;
  }

  .dashboad-bottom {
    background: $color1;

    .inner {
      background: $white-color;
      border-radius: 20px 20px 0 0;

      //  min-height: 500px;
      h2 {
        font-size: 1.2rem;
      }

      .tastk-items {
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        border-radius: 15px;

        h5 {
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0;

        }

        &.flip {
          -webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
          animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
        }

        @-webkit-keyframes flip-horizontal-bottom {
          0% {
            opacity: 0;
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }

          100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
          }
        }

        @keyframes flip-horizontal-bottom {
          0% {
            opacity: 0;
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }

          100% {
            opacity: 1;
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
          }
        }

        ///////////////////////



      }
    }
  }

  .list {
    li {
      display: inline-block;
      font-size: 14px;
      list-style-type: circle;
      position: relative;
      padding-left: 20px;

      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $color2;
        border-radius: 50%;
        margin-right: 5px;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }

  .list-highlight {
    display: flex;

    gap: 10px 20px;

    li {
      display: inline-block;
      width: 33%;
      border: 1px solid #d6d6d6;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 10px 20px;
      border-radius: 10px;

      &:last-child {
        border-right: 0;
      }

      .icons {

        i {
          font-size: 2rem;
          color: $color2;
        }
      }

    }
  }
  

  .title {
    font-size: 1.2rem;
    font-weight: 600;
  }


  .wraper-option {
    font-size: 1.2rem;
    position: relative;

    .loader {
      background: #ffffffbf;
      align-items: center;
    }

    &.bank-home {
      display: flex;
      flex-wrap: wrap;
      gap: 4px 14px;
      justify-content: center;
      .option-btn {
        background: $white-color;
        width: 24%;
        padding-left: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      }
    }
    .check_btn1{
      background: #ec6842 !important;
      color:#fff;
    }

    .option-btn {
      padding: 10px 10px;
      // background: $color3;
      position: relative;
      border-radius: 10px;
      border: 1px solid $color1;
      padding-left: 20px;
      cursor: pointer;
      text-align: center;

      &.bg-btn {
        background: $color1;

        width: 50%;
      }

      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        input {
          opacity: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &.current {
        background: $color1;
        border: 1px solid $color1;
        color: $color3;

        .check {
          border: 1px solid $color1;
          background: $color2 url(../../img/tick03.png) no-repeat center 6px;
          background-size: 12px;

        }
      }

      .check {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $color1;
        margin-right: 10px;
        position: relative;
        top: 5px;

      }
    }

    &.banklist {
      .option-btn {
        font-size: 16px;
        text-align: start;
        display: flex;
        align-items: center;

        .check {
          top: 0;
        }

        .textcont {
          width: 80%;
        }
      }
    }
  }

  .skip-btn {
    border: none;
    background: transparent;
  }

  .homebanner {
    min-height: 250px;
    background: $color1 url("../../img/CSPC096/667a62d8bf90faa4ef72b96e2537a778.png");
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;

    h2 {
      font-weight: 600;
      font-size: 2rem;
      line-height: 2rem;
    }

    .subtext {
      font-size: 1.1rem;
    }

    .claim-value {
      font-size: 1.8rem;
      font-weight: 600;
      color: $white-color;

      .amount {
        position: relative;
        padding: 5px 8px;
        display: inline-block;

        em {
          position: relative;
          z-index: 1;
        }

        &::before {
          background: $color2;
          content: "";
          position: absolute;
          left: 0;
          top: 7px;
          height: 40px;
          width: 0;
          z-index: 0;
          animation: animateThis 2s ease-in infinite;
          -webkit-animation: animateThis 2s ease-in infinite;
          animation-fill-mode: forwards infinite;
          -webkit-animation-fill-mode: forwards infinite;

        }

        @keyframes animateThis {
          0% {
            width: 0;

          }

          50% {
            width: 100%;

          }

          100% {
            width: 100%;

          }
        }

        @-webkit-keyframes animateThis {
          0% {
            width: 0;

          }

          50% {
            width: 100%;

          }

          100% {
            width: 100%;

          }
        }
      }
    }
  }

  .drop-down-multi {
    position: relative;
    text-align: left;
    z-index: 8;

    .choices__input {
      background: transparent;
    }

    .choices__inner {
      background: $white-color;
      border-radius: 10px;
      padding: 10px;

    }

    .choices__list--multiple {
      .choices__item {
        font-size: 14px;
        background: $color2;
        border-color: $color2;
      }
    }
  }

  .faqsection {

    background: $color1 url(../../img/bg-55.png) no-repeat;
    background-position: left bottom;
    padding: 70px 0;

    h2 {
      color: $white-color;
    }

    .accordion-button {
      font-weight: bold;
      background-color: $color1;
      color: $white-color;
      font-weight: 500;

      &::after {
        background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>");
        ;
      }

      &:not(.collapsed) {
        background: $color1;
        color: $white-color;
      }
    }

    .accordion-item {
      background: $color1;
      color: $white-color;
    }

    .accordion-body {
      a {
        color: #ebe184;
      }
    }
  }


  footer {
    background: $white-color;
    color: $color1;
    font-size: 1rem;

    p {
      font-size: .9rem;
    }

    ul {
      li {
        display: inline-block;

        a {
          color: $color1;
          padding: 0 15px 4px 0;
          font-size: 1rem;
        }
      }
    }
  }

  .sign-sec {
    h5 {
      font-size: 16px;
    }

    .clearbutton {
      background: #eee;
      border: none;
      outline: 0;
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 5px;
    }


    .signbox {
      width:100% !important;
      div{
      width: 100% !important;
        canvas {
          width: 100%;
          height: 350px;
          // max-width: 100% !important;
          border: 1px solid #b1b1b1;
          border-bottom: 2px dotted $black-color;
          background: $white-color url(../../img/signhere.png) no-repeat center center;
        }
      }




    }

    .sign-check {
      .checkinput {
        width: 20px;
        height: 20px;
        position: relative;
        top: 6px;

      }

      label {
        font-size: 14px;
      }

      text-align: justify;
    }

    p {
      font-size: 13px;
    }

    .list-point {
      .li_class {
        padding-bottom: 6px;
        font-size: 13px;
        position: relative;
        padding-left: 26px;

        &::before {
          content: "";
          background: url("../../img/arrows_more_up.png")no-repeat;
          background-size: 18px;
          content: "";
          display: block;
          width: 25px;
          height: 25px;
          position: absolute;
          left: 0;

        }
      }
    }

            		//sub -Unordered list styles
            		.sub_ul {
            		  list-style: disc; 
            		  padding-left: 20px !important; 
        
            		  .sub_li {
            		    list-style-type: circle;
            		  }
            		}

  }

  .all-slides {
    position: relative;


  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    z-index: 99;
    background: $white-color;
    display: flex;
    align-items: start;
    justify-content: center;
  }

  .wrap-number {
    p {
      font-size: 12px;
    }

    h1 {
      font-size: 18px;
      line-height: 24px;

      .lendername {
        color: $color2;
      }
    }

    padding-top: 1rem;


    .car-img {
      position: relative;

      img {
        height: 99px;
        position: absolute;
        top: -98px;
        right: 0;
      }
    }


    .number-plate {
      border: 2px solid $black-color;
      border-radius: 10px;
      background: $white-color;

      .flag {
        width: 15%;
        background: #2145DD url(../../img/flag.png) no-repeat center center;
        border-radius: 8px 0px 0px 8px;
        background-size: 20px;
      }

      .input-wrap {
        width: 100%;

        input {
          height: 55px;
          border-radius: 0 10px 10px 0px;
          font-size: 1rem;
          border: none;
          box-shadow: none;
        }
      }

      .add-more {
        width: 15%;
        background: $black-color;
        color: $white-color;
        text-align: center;
        font-size: 1rem;
        border-radius: 5px;
        height: 32px;
        margin-top: 10px;
        line-height: 33px;
        margin-right: 6px;
        cursor: pointer;
      }

    }
  }



  .uploads-section {
    margin-top: 20px;

    h1 {
      font-size: 22px;
    }

    h5 {
      font-size: 16px;
    }


    .uploads {
      border: 2px solid $black-color;
      padding: 15px;
      border-radius: 5px;
      font-size: 1.1rem;
      font-weight: 500;

    }

    .btn_id1{
      width:100%;
      text-align:left;
      background: white;
      img{
        height:30px;
      }
    }

    .upl-btn {
      padding: 10px 2px;
      background: $color1;
      border: 0;
      border-radius: 5px;
      position: relative;
      font-size: 1rem;
      color: $white-color;

      input {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        right: 0;
        opacity: 0;
      }


    }

    .up-wrap {
      border: 3px dashed $color1;
      border-radius: 20px;
      height: 210px;
      position: relative;
      text-align: center;
      display: flex;
      font-size: 3rem;
      justify-content: center;
      align-items: center;

      .upload-text {
        font-size: 1.2rem;
      }

      img {
        border-radius: 10px;
      }

      .imgloader {
        position: absolute;
        background: #ffffff57;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .progress {
          width: 80%;
          margin-top: 9rem;


          .progress-bar {
            animation: profill 2s ease-in 100ms both;
          }

          @keyframes profill {
            0% {
              width: 0%;
            }

            50% {
              width: 100%;
            }
          }
        }




      }
    }
  }

  .slide {
    padding: 15px 15px;
    border: 2px solid #eee;
    border-radius: 20px;
  }

  .form-style {

    fieldset {
      border: 1px solid $color3;
      border-radius: 10px;


      legend {
        float: none;
        display: inline-block;
        background: transparent;
        width: initial;
        padding: 0 10px;
        text-align: left;
        margin-bottom: 0;
        font-size: 1.2rem;
      }
    }


    .drop-down-multi {
      .choices__list--multiple {
        .choices__item {
          background: $color1;
          border-color: #d19e18;
        }
      }

      box-shadow: none;

      .choices__inner {
        padding: 0 10px;
        border-radius: 10px;
        background: $white-color;
        border: 1.5px solid $color1;

        .choices__input {
          background: transparent;
        }

        input {
          border: none;
          padding: 0;
          height: 55px;
          margin-bottom: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }

    }

    &.form-body {
      padding: 100px;
    }



    input,
    select {
      border: 1px solid $color1 ;
      font-size: 1rem;
      height: 55px;
      border-radius: 10px;
      padding: 10px 15px;

      &:focus {
        box-shadow: rgba(247, 176, 44, 0.3) 0px 0px 0px 3px;
      }
    }

    .contactinfo {
      input {
        background: $white-color url("../../img/guaranteedprivacy.png") no-repeat right center;
        background-size: 90px;
      }
    }

    .sign-sec {
      h5 {
        font-size: 16px;
      }

      .clearbutton {
        background: $color1;
        border: none;
        outline: 0;
        font-size: 14px;
        padding: 6px 15px;
        border-radius: 5px;
      }


      .signbox {
        width:100% !important;
        div{
          width: 100% !important;
                  canvas {
                    width: 100%;
                    height: 350px;
                    // max-width: 100% !important;
                    border: 1px solid #b1b1b1;
                    border-bottom: 2px dotted $black-color;
                    background: $white-color url(../../img/signhere.png) no-repeat center center;
                  }
                }


      }

    }

    .sign-check {
      .checkinput {
        width: 20px;
        height: 20px;
        position: relative;
        top: 6px;

      }

      label {
        font-size: 1rem;
      }

      text-align: justify;
    }

    p {
      font-size: 13px;
    }

    .list-point {
      li {
        padding-bottom: 6px;
        font-size: 13px;
        position: relative;
        padding-left: 26px;

        &::before {
          content: "";
          background: url("../../img/arrows_more_up.png")no-repeat;
          background-size: 18px;
          content: "";
          display: block;
          width: 25px;
          height: 25px;
          position: absolute;
          left: 0;

        }
      }
    }


    .lookup-btn {
      height: 55px;
      background: #2C2C2C;

      span {
        color: $white-color;
        font-size: 1rem;
      }
    }
  }

  .text-data-guarantee {
    font-size: 14px;
  }

  .success {
    min-height: 300px;

    h1 {
      font-size: 2rem;
    }
  }


	.modal {
		background: rgba(0, 0, 0, 0.86);

		// padding-right: 0 !important;
		.modalContent_sml {
			min-height: 35vh !important;
		}
    .car-mdl{
      min-height: 0 !important;
    }
		.modal-content {
			display: flex;
			border-radius: 20px 20px 20px 20px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
			padding: 5%;
			background-image: url(../../img/bubble_bg.png);
			background-position: center right;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 60vh;

			.modal-header {
				border: 0;
				justify-content: center;

				.modal-title {
					img {
						width: 220px;
					}
				}
			}

			.modal-body {
				padding: 10px 0;

				h2 {
					color: #000 !important;
					font-weight: 700;
					font-size: 26px;
				}
			}

			.modal-footer {
				display: block;
				flex-shrink: 0;
				flex-wrap: wrap;
				align-items: center;
				justify-content: start;
				padding: 0;
				border-top: 0;
			}
		}

	}

  .progress-wrap {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 25px;
    margin: 20px 0;
    position: relative;

    .progress-bar {
      width: 0;
      height: 20px;
      background-color: $color2;
      border-radius: 25px;
      transition: width 0.25s;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      #progress-value {
        color: white;
        position: absolute;
        width: 100%;
        text-align: center;
      }
    }
  }
  .back-but {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    // background: url(../../img/left-arrow.png)no-repeat center left 10px;
    background-size: 14px;
    background-color: #cccccc;
    font-weight: 700;
    outline: none;
    border: 0;
    height: auto !important;
    max-width: 100px !important;
    @media (max-width: 510px) {
      // background: url(../../img/left-arrow.png)no-repeat center left 80px;
      background-size: 14px;
      background-color: #cccccc;
      max-width: unset !important;
      font-size: 20px;
    }
  }
  .remove-area {
    .btn {
      font-size: 13px;
    }
  }

  .pulse {
    animation: pulse-animation 2s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgb(25, 87, 243);
    }

    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }


  .btn-sticky {
    position: sticky;
    bottom: 0;
    z-index: 99;
  }

  .prev-dtls {
		.form-popupdetails {

			@media (max-width: 510px) {
				margin-top: 20px;
			}

			.personal_detail {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;

				@media (max-width: 510px) {
					grid-template-columns: 1fr;
					gap: 0px;
				}
			}

			.choose_Y_N {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 10px;

				@media (max-width: 510px) {
					grid-template-columns: 1fr;
				}
			}

			.options {
				display: flex;
				clear: both;
				width: 100%;
				flex-wrap: wrap;

				li {
					width: auto;
					display: block;
					// float: left;
					margin: 0 10px 10px 0;
				}
			}

			.options.two {
				li {
					width: calc(50% - 10px);
					// float: left;
				}
			}

			//.options.two
			@media only screen and (min-width: 480px) and (max-width: 768px) {
				.options.two.lender li {
					width: 100%;
				}
			}

			@media only screen and (max-width: 480px) {

				.options li,
				.options.two li {
					width: 100%;
					margin: 0 0 10px 0;
				}
			}

			@media only screen and (min-width: 960px) and (max-width: 1200px) {
				.options.two.lender li {
					width: 100%;
				}
			}

			.additional_lenders {
				margin: -9px 0 5px 0;
				display: block;
				text-align: center;
				color: #1f5652 !important;
				cursor: pointer;
				font-size: 18px;
				text-decoration: underline;
				font-weight: 500;

				@media (max-width: 510px) {
					font-size: 16px;
				}
			}

			//additional_lenders

			.anotherlenderoptions {
				label {
					span {
						padding: 0;
						font-size: 14px !important;
					}
				}
			}

			.margin_use {
				margin: 0 10px 10px 0 !important;

				@media (max-width: 510px) {
					margin: 0 0px 6px 0 !important;
				}
			}

			.custom-checkbox {
				display: none;
			}

			label {
				color: #1b1b1b;
				font-size: 22px;
				font-weight: 600;
				display: block;
				clear: both;
				margin-bottom: 13px;
				line-height: 140%;

				@media (max-width: 510px) {
					font-size: 20px;
				}

				span {
					font-size: 18px;
					display: block;
					line-height: 160%;
					padding: 5px 0 0 0;
					clear: both;

					@media (max-width: 510px) {
						font-size: 17px;
					}
				}

			}

			.custom-checkbox:checked+label {
				background: #FFFFFF;
				background: #EBFFEB;
				border: 2px solid #00A400;
			}

			.custom-checkbox:checked+label:before {
				border: 2px solid #00A400;
				// background-color: #00A400;
				content: '';
				background: #00A400 url(../../img/check.png) no-repeat center center;
				background-size: 80%;
			}

			.custom-checkbox+label {
				position: relative;
				cursor: pointer;
				outline: none;
				margin: 0;
				border-radius: 5px;
				width: 100%;
				font-size: 14px;
				font-weight: 600;
				background: #FFFFFF;
				border: 2px solid #080D21;
				padding: 10px 20px 10px 50px;
				height: 50px;
				display: flex;
				align-items: center;
				color: #FFFFFF;
				color: #080D21;

				&:before {
					border: 2px solid #080D21;
					background: #FFFFFF;
					width: 18px;
					height: 18px;
					content: '';
					margin: 0;
					position: absolute;
					top: 13px;
					left: 17px;
					display: block;
					-webkit-border-radius: 100%;
					-moz-border-radius: 100%;
					border-radius: 100%;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
				}

				&:active {
					-webkit-box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
					box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
				}
			}

			//.custom-checkbox + label
			input,
			#idpc_button,
			select,
			textarea {
				width: 100%;
				height: 50px;
				outline: none;
				border-radius: 5px;
				-webkit-appearance: none;
				appearance: none;
				padding: 0 20px;
				font-size: 16px;
				font-weight: 500;
				color: #080D21;
				border: 2px solid #080D21;
				z-index: 30;
			}

			select {
				background: #FFFFFF url(../../img/select.svg) no-repeat right 20px center;
				background-size: 15px 15px;
				-webkit-background-size: 15px 15px;
				-moz-background-size: 15px 15px;
				-o-background-size: 15px 15px;
				padding: 0 50px 0 20px;
			}

			.input-group-text {
				display: flex;
				align-items: center;
				padding: 0.375rem 0.75rem;
				height: 50px;
				margin-bottom: 0;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: #495057;
				text-align: center;
				white-space: nowrap;
				background-color: #e9ecef;
				border-left: 0;
				border: 1px solid #080d21;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}


			// ==============================
			// BUTTON DESIGN
			// ==============================


			.next-but {
				display: inline-block;
				padding: 18px 50px;
				color: #FFFFFF !important;
				font-size: 24px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin: 7px auto !important;
				min-width: 250px !important;
				cursor: pointer;
				text-align: center;
				font-weight: 700;
				background: url(../../img/right-arrow.png)no-repeat center right 44px;
				background-size: 21px;
				background-color: #02a1e5;
				outline: none;
				border: 0;
				height: auto !important;

				@media (max-width: 510px) {
					background: url(../../img/right-arrow.png)no-repeat center right 70px;
					background-size: 21px;
					background-color: #02a1e5;
					max-width: unset !important;
					padding: 18px 40px;
					font-size: 20px;
				}

				&:hover {
					background-color: #021458
				}
			}



		}

		.modal {
			background: rgba(0, 0, 0, 1);

			// padding-right: 0 !important;
			.modal-popupdetails {
				display: flex;
				border-radius: 20px 20px 20px 20px;
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
				padding: 5%;
				background-image: url(../../img/bubble_bg.png);
				background-position: center right;
				background-repeat: no-repeat;
				background-size: contain;

				.modal-header {
					border: 0;
					justify-content: center;

					.modal-title {
						img {
              // height: 35px;
              // width: 220px;
            }
					}
				}

				.modal-body {
					padding: 10px 0;

					h2 {
						color: #02a1e5 !important;
						font-weight: 700;
						font-size: 24px;
						margin-bottom: 20px;
						text-align: center;

						@media (max-width: 768px) {
							font-size: 18px;
							text-align: center;
						}
					}

					.fs-6 {
						font-size: 1rem !important;
						margin: 10px 0;
					}

					.name-col {
						width: 100%;
						background: #fff url(../../img/name_bg.png) no-repeat left top;
						background-size: 20px;
						background-position: 5px 20px;
						padding: 20px;
						padding-left: 35px;
						border-radius: 10px;
						display: inline-block;
						box-shadow: 0 3px 40px 0 #0000001a;
						margin: 10px 0;

						p {
							margin: 0px;
						}
					}

					.address-col {
						width: 100%;
						background: #fff url(../../img/loc_bg.png) no-repeat left top;
						background-size: 25px;
						background-position: 5px 20px;
						padding: 20px;
						padding-left: 35px;
						border-radius: 10px;
						display: inline-block;
						box-shadow: 0 3px 40px 0 #0000001a;
						margin: 10px 0;

						p {
							margin: 0px;
						}
					}
				}

			}

		}
	}
	.next-but {
    display: inline-block;
    padding: 18px 50px;
    color: #FFFFFF;
    font-size: 24px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 7px auto !important;
    // max-width: 250px !important;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    background: url(../../img/right-arrow.png)no-repeat center right 44px;
    background-size: 21px;
    background-color: #02a1e5;
    outline: none;
    border: 0;
    height: auto !important;

    @media (max-width: 510px) {
      background: url(../../img/right-arrow.png)no-repeat center right 70px;
      background-size: 21px;
      background-color: #02a1e5;
      max-width: unset !important;
      padding: 18px 40px;
      font-size: 20px;
    }

    &:hover {
      background-color: #021458
    }

  }

  ////////////////////////////////////////////////////////
  @media screen and (max-width: 1200px) {
    .wraper-option {
      &.bank-home {

        .option-btn {
          width: 32%;
        }
      }
      .check_btn1{
        background: #ec6842 !important;
        color:#fff;
      }
    }

  }

  @media screen and (max-width: 990px) {
    .wraper-option {
      &.bank-home {

        .option-btn {
          width: 32%;
        }
      }
      .check_btn1{
        background: #ec6842 !important;
        color:#fff;
      }
    }

  }


  @media screen and (max-width: 960px) {

    .wraper-option {
      &.bank-home {
        gap: 4px 10px;

        .option-btn {
          width: 30%;
        }
      }
      .check_btn1{
        background: #ec6842 !important;
        color:#fff;
      }
    }




  }



  @media screen and (max-width: 790px) {
    .dashboad-middle {
      .middle-sec {
        h1 {
          font-size: 1.2rem;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .wraper-option {
      &.bank-home {
        .option-btn {
          width: 48%;
        }
      }
      .check_btn1{
        background: #ec6842 !important;
        color:#fff;
      }
    }

    .list-highlight {
      flex-wrap: wrap;

      li {
        width: 100%;
        font-size: .9rem;
      }
    }
     
    .homebanner {
      h2 {
        font-size: 1.5rem;
      }

      .claim-value {
        font-size: 1.2rem;

        span {
          &::before {
            height: 30px;
          }
        }
        .amount{
          &::before {
            top: 0;
          }
        }
      }
    }

    .faqsection {
      .accordion-body {
        font-size: .8rem;
      }
    }


  }




  @media screen and (max-width: 470px) {

    .dashboad-top {
      .user-profile {
        .dp {
          width: 50px;
          height: 50px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .dp-inner {
          h2 {
            font-size: 1rem;
          }

          .age {
            font-size: 12px;
          }
        }

      }
    }

    .list-highlight {
      li {
        padding: 5px 20px;
      }
    }

    .dashboad-middle {
      .middle-sec {
        h1 {
          font-size: .9rem;
        }

        p {
          font-size: 10px;
        }

        .dis-icon {
          li {
            .lops {
              font-size: 9px;
              line-height: 15px;

              .fs {
                font-size: 10px;
              }

              img {
                height: 30px;
              }
            }
          }
        }

        .wrap-lops {
          .dis-icon {
            position: absolute;
          }
        }
      }

    }

    .dashboad-bottom {
      .inner {

        .tastk-items {
          h5 {
            font-size: 15px;
          }
        }
      }

    }

    .title {
      font-size: 1rem;
    }

    .wraper-option {

      &.bank-home {
        gap: 4px;
      }

      &.banklist {
        .option-btn {
          font-size: 14px;
          width: 100%;
        }
      }
      .check_btn1{
        background: #ec6842 !important;
        color:#fff;
      }
    }

    footer {
      ul {
        li {
          display: block;
        }
      }
    }


  }



  @media screen and (max-width: 390px) {
    .wrap-number {
      h1 {
        .lendername {
          display: block;
        }
      }
    }
  }


  .css-1rhbuit-multiValue{
    border-color: #ec6842 !important;
    background: #ec6842 !important;
    border-radius: 20px !important;
    padding: 3px 5px;
    color: #f1f1f1;
  }

  .css-tj5bde-Svg{
    border-left: 1px solid #008fa1;
    cursor: pointer;
  }

  .css-12jo7m5{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .css-1hb7zxy-IndicatorsContainer{
    display: none !important;
  }


}