/*
*******************************************

Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

.authorise {
  .display-block{
    display: block;
  }
  .error_msg {
    color: white;
    margin-bottom: 2px;
    width: 100%;
    padding: 1px 5px;
    background: #ff0000;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
  }
  .check_box_agree {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 0 5px 0 0;
    // padding: 0px 27px 0px;
  
    .form-check-input {
      width: 1.5em;
      height: 1.5em;
      padding: 0;
      margin-top: .25em;
      vertical-align: top;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid rgba(0, 0, 0, 0.25);
      appearance: none;
    }
  
    .form-check-input {
      float: left;
      margin-left: -1.5em;
      animation: mymovecheck 1s infinite;
  
      &:checked {
        background-color: #021458;
        border-color: #021458;
      }
  
      &:focus {
        border-color: #021458;
        outline: 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  
    .blink_red {
      animation: mymovecheckRed 1s infinite !important;
    }
  
    .blink_green {
      animation: mymovecheckGreen 1s infinite !important;
    }
  
  
    label {
      display: inline-block !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      margin-bottom: 10px !important;
    }
  
    @keyframes mymovecheck {
      50% {
        box-shadow: 0px 0px 40px #063783;
      }
    }
  
    @keyframes mymovecheckRed{
      50% {
        box-shadow: 0px 0px 10px 4px  red;
      }
    }
  
    @keyframes mymovecheckGreen{
      50% {
        box-shadow: 0px 0px 10px 4px #12cf1c;
      }
    }
  }
  ul {
    list-style-type: none;
  }
  .list-itemsc {
    >li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 10px;
  
      &::before {
        width: 26px;
        height: 26px;
        content: "";
        background: url("../../img/TCG_L_D/tick033.png") center center no-repeat;
        background-size: 22px;
        border-radius: 10px;
        display: block;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
  
  .list-itemsb {
    li {
      list-style-type: circle;
    }
  }
  .nextbtn2 {
    background: #ed6843;
    padding: 20px 40px;
    border: none;
    position: relative;
    z-index: 1;
    color: #fff;
    border-radius: 5px;
    outline-color: none;
  }
}