
$background_color_1:#ffffff;
$background_color_2:#063783;
$background_color_3:#f0f2f9;

$color_1:#ffffff;
$color_2:#063783;
$color_3: #000;

.pulse {
  animation: pulse-animation 2s infinite;
}
.blink_red {
  animation: mymovecheckRed 1s infinite !important;
}

.blink_green {
  animation: mymovecheckGreen 1s infinite !important;
}
.cls-opcty{
  opacity: 1 !important;
  z-index: 0 !important;
}
.signature{
  a {
    color: #063783;
    text-decoration: none;
  }
    background: #e8e8e8 url(../../TCG_L_A/img/signature_bg.png) no-repeat center left;
    padding: 50px 0 80px;
	background-size: cover;
    background-attachment: fixed;
    .signwrapper{
        padding: 15px 25px 30px;
        background: #fff;
        border-radius: 7px;
        border-top:5px solid $color_2;
        border-bottom:5px solid $color_2;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    h2{
        font-size: 44px;
        line-height: 52px;
        font-weight: 600;
        color: $color_2;
        margin-bottom: 20px;
    }
    p{
        font-size: 14px;
        a{
            color: $color_2 !important;
            text-decoration: underline;
            &:hover{
                color:#01b636 !important;
            }
        }
    }
    .ul_class{
        margin: 0px;
        padding: 0px;
        .li_class{
            background-image: url(../../TCG_L_A/img/list_ico.png) !important;
            background-size: 25px 29px !important;
            background-repeat: no-repeat !important;
            list-style: none;
            padding:0px 0 15px 35px;
            font-size: 14px;
        }

                    		//sub -Unordered list styles
                        .sub_ul {
                          list-style: disc;
                          padding-left: 20px !important;

                          .sub_li {
                            list-style-type: circle;
                          }
                        }
    }




// signature checkbox


$color-opacity: 0.3;
$color-white: #ecf0f1;
$color-grey: #555;
/* Checkbox */
input[type="checkbox"] {
  opacity: 0;
  z-index: -1;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 2em;
  height:auto;
  line-height: 1.5;
  cursor: pointer;
  font-size: 14px;
}

input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  animation: mymovecheck 1s infinite;
}

input[type="checkbox"] + label::before {
  content: " ";
  border: 2px solid $color_2;
  border-radius: 20%;
  width: 1.5em;
  height: 1.5em;
}

input[type="checkbox"] + label::after {
  content: " ";
  border: 2px solid $color-white;
  border-top: none;
  border-right: none;
  line-height: 1.5;
  height: 0.4rem;
  left: 0.3rem;
  top: 0.4rem;
  width: 1rem;
}

/* :checked */
input[type="checkbox"]:checked + label::before {
  background:#01b636;
  border-color:#01b636;
}

input[type="checkbox"] + label::after {
  transform: scale(0);
}

input[type="checkbox"]:checked + label::after {
  transform: scale(1) rotate(-45deg);
}

/* Transition */
label::before,
label::after {
  transition: 0.25s all ease;
}

@keyframes mymovecheck {
  50% {box-shadow: 0px 0px 40px #063783;}
}

.sign-check {
  .checkinput {
    width: 20px;
    height: 20px;
    position: relative;
    top: 6px;

  }

  label {
    font-size: 14px;
  }

  text-align: justify;
}

// checkbox end
.sign-pad{
    h5{
        background-color: $background_color_2;
        color: $color_1;
        padding: 10px 25px;
        margin: 0px;
        border-radius: 7px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        margin-top: 20px;
    }
    .icodown {
        animation: MoveUpDown 1s linear infinite;
        position: relative;
        left: 0;
        bottom: 0;
      }

      @keyframes MoveUpDown {
        0%, 100% {
          bottom: -10px;
        }
        50% {
          bottom: 5px;
        }
      }
}

#canvas_signature{
  width: 100%;
  background: #eef5ff;
  /* max-height: 350px; */
  height: 350px;
  border-radius: 5px;
  margin: 0 0 10px;
  border: 2px dashed #063783;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
}


.signButton {
	background-image: url(../../TCG_L_A/img/right-chevron.svg) !important;
	background-size: 17% 42px !important;
	background-repeat: no-repeat !important;
	background-position: 100% !important;
	display: inline-block;
	background: $background_color_2;
	color: #fff !important;
	padding: 16px 0px;
	border-radius: 7px;
	border: 0;
    font-size: 22px;
    width: 50%;
    // max-width: 200px;
	font-weight: 600;
	box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
	cursor: pointer;
	transition: all .3s ease-in-out;
    text-align: center;
	&:hover{
		background: #01b636;
	}
}
.clearButton{
    background: #fff;
    padding: 10px 20px;
    border: dashed 2px #ddd;
    border-radius: 7px;
    display: inline-block;
    margin-bottom: 15px;
    color: #9b9898 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

#signbutmain{
  position: sticky;
    bottom: 0px;
    z-index: 9999;
}
.abutdiv{
    display: none;
}
}


// animated fixed but
@media screen and (max-width: 768px) {
    .signwrapper{
        .abutdiv{
            display: block;
        }

        .sign-pad{
            h5{
                padding: 10px 20px;
                font-size: 18px;
                text-align: center;
                display: inline-block;
                margin-top: 20px;
                width: 100%;
            }
        }

    }

    .fixed_but{
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 99;
        width: 100% !important;
    }
    .but_anim{
        animation: mymove 1s infinite;
    }

    @keyframes mymove {
      50% {box-shadow: 0px 0px 50px #063783;}
    }
    }
    // animated fixed but end

}



    @media screen and (max-width: 768px) {

        .signature{
            .signwrapper{
                h2{
                    font-size:32px;
                }
            }
        }

    }
