.try_again_page {
    .primary-text{
        font-size: 19px !important;
    }
    .secondary-text{
        font-size: large !important;
    }
    .custom-vh {
        min-height: 88vh;
    }

    .tryagain {
        background: #E1EEFF;
        background: linear-gradient(180deg, #E1EEFF 0%, #7B93F6 100%);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding: 15px;
        // border-top: 7px solid #ffffff;
        min-height: 88vh;
        // margin-top: 40px;

        h3 {
            margin: 15px 0;
            font-size: 18px;
        }

        h4 {
            margin: 15px 0 25px;
            font-size: 22px;
            color: #000000;
        }

        img {
            margin: 15px 0;
        }
    }

    .loader-try {
        position: relative;
        margin: 50px 0;

        .loading-spinner-try {
            padding-top: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100px;
                height: 100px;
                margin-top: -50px;
                margin-left: -50px;
                border-radius: 50%;
                border-top: 5px solid #005398;
                border-right: 5px solid transparent;
                animation: animation-loading 0.50s linear infinite;
            }

            @keyframes animation-loading {
                0% {
                    transform: rotate(0deg)
                }

                100% {
                    transform: rotate(360deg)
                }
            }

        }

    }

    .tryagain-but {
        display: inline-block;
        background: #1732a2;
        color: #fff !important;
        padding: 15px 5px;
        border-radius: 30px !important;
        border: 0;
        width: 46%;
        min-width: 150px;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 400;

        cursor: pointer;
        transition: all .3s ease-in-out;
        text-align: center;

        @media screen and (max-width: 768px) {}

        &:hover {
            background: #01b636;
        }
    }

    .notificationtry {
        font-size: 13px;
        padding: 10px;
        border: solid 1px #ce0606;
        background: #e0e6ff;
        border-radius: 7px;
        color: #ff0000;
        display: flex;
        align-content: center;
        align-items: center;
    }

    .text-r {
        color: #e60000 !important;
        font-size: 16px !important;
    }

    @media screen and (max-width: 768px) {
        .tryagain-but {
            width: 90%;
        }

        .tryagain {
            img {
                // max-height: 75px;
            }
        }

        .text-r {
            color: #e60000;
            font-size: 13px !important;
        }
    }
}