/*
*******************************************

Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

.aditionallender_b {
	a {
		color: #007BFF; // Default link color
		text-decoration: none; // Remove underline from links

		&:hover {
			text-decoration: underline; // Underline on hover
		}

		&:focus {
			outline: none; // Remove outline on focus
			box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); // Add a focus ring
		}
	}

	// Unordered list styles
	ul {
		list-style: none; // Remove bullets
		padding-left: 0 !important; // Remove default padding

		li {
			list-style-type: none;
			//padding-left: 20px; // Add some padding to list items
			//   position: relative;
		}
	}

	// Additional common elements
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 10px 0; // Margin for headings
		font-weight: 700; // Bold headings
		color: #ffffff !important; // Heading color
	}

	p {
		margin: 0 0 15px 0; // Margin for paragraphs
	}

	img {
		max-width: 100%; // Make images responsive
		height: auto;
	}

	.wrapper {
		font-family: "Poppins", sans-serif;
	}








	.form-popupdetails {

		@media (max-width: 510px) {
			margin-top: 20px;
		}

		.personal_detail {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
				gap: 0px;
			}
		}

		.choose_Y_N {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
			}
		}

		.options {
			display: flex;
			clear: both;
			width: 100%;
			flex-wrap: wrap;

			li {
				width: auto;
				display: block;
				// float: left;
				margin: 0 10px 10px 0;
			}
		}

		.options.two {
			li {
				width: calc(50% - 10px);
				// float: left;
			}
		}

		//.options.two
		@media only screen and (min-width: 480px) and (max-width: 768px) {
			.options.two.lender li {
				width: 100%;
			}
		}

		@media only screen and (max-width: 480px) {

			.options li,
			.options.two li {
				width: 100%;
				margin: 0 0 10px 0;
			}
		}

		@media only screen and (min-width: 960px) and (max-width: 1200px) {
			.options.two.lender li {
				width: 100%;
			}
		}

		.additional_lenders {
			margin: -9px 0 5px 0;
			display: block;
			text-align: center;
			color: #1f5652 !important;
			cursor: pointer;
			font-size: 18px;
			text-decoration: underline;
			font-weight: 500;

			@media (max-width: 510px) {
				font-size: 16px;
			}
		}

		//additional_lenders

		.anotherlenderoptions {
			label {
				span {
					padding: 0;
					font-size: 14px !important;
				}
			}
		}

		.margin_use {
			margin: 0 10px 10px 0 !important;

			@media (max-width: 510px) {
				margin: 0 0px 6px 0 !important;
			}
		}

		.custom-checkbox {
			display: none;
		}

		label {
			color: #1b1b1b;
			font-size: 22px;
			font-weight: 600;
			display: block;
			clear: both;
			margin-bottom: 13px;
			line-height: 140%;

			@media (max-width: 510px) {
				font-size: 20px;
			}

			span {
				font-size: 18px;
				display: block;
				line-height: 160%;
				padding: 5px 0 0 0;
				clear: both;

				@media (max-width: 510px) {
					font-size: 17px;
				}
			}

		}

		.custom-checkbox:checked+label {
			background: #FFFFFF;
			background: #EBFFEB;
			border: 2px solid #00A400;
		}

		.custom-checkbox:checked+label:before {
			border: 2px solid #00A400;
			// background-color: #00A400;
			content: '';
			background: #00A400 url(../../img/check.png) no-repeat center center;
			background-size: 100%;
		}

		.custom-checkbox+label {
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			border-radius: 5px;
			width: 100%;
			font-size: 14px;
			font-weight: 600;
			background: #FFFFFF;
			border: 2px solid #080D21;
			padding: 10px 4px 10px 41px;
			height: 60px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			color: #080D21;

			&:before {
				border: 2px solid #080D21;
				background: #FFFFFF;
				width: 18px;
				height: 18px;
				content: '';
				margin: 0;
				position: absolute;
				top: 18px;
				left: 17px;
				display: block;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			}

			&:active {
				-webkit-box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
				box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
			}
		}

		//.custom-checkbox + label
		input,
		#idpc_button,
		select,
		textarea {
			width: 100%;
			height: 50px;
			outline: none;
			border-radius: 5px;
			-webkit-appearance: none;
			appearance: none;
			padding: 0 20px;
			font-size: 16px;
			font-weight: 500;
			color: #080D21;
			border: 2px solid #080D21;
			z-index: 30;
		}

		select {
			background: #FFFFFF url(../../img/select.svg) no-repeat right 20px center;
			background-size: 15px 15px;
			-webkit-background-size: 15px 15px;
			-moz-background-size: 15px 15px;
			-o-background-size: 15px 15px;
			padding: 0 50px 0 20px;
		}

		.input-group-text {
			display: flex;
			align-items: center;
			padding: 0.375rem 0.75rem;
			height: 50px;
			margin-bottom: 0;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border-left: 0;
			border: 1px solid #080d21;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}


		// ==============================
		// BUTTON DESIGN
		// ==============================


		.next-but {
			display: inline-block;
			padding: 18px 50px;
			color: #FFFFFF !important;
			font-size: 24px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			margin: 7px auto !important;
			min-width: 250px !important;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			background: url(../../img/right-arrow.png)no-repeat center right 44px;
			background-size: 21px;
			background-color: #02a1e5;
			outline: none;
			border: 0;
			height: auto !important;

			@media (max-width: 510px) {
				background: url(../../img/right-arrow.png)no-repeat center right 70px;
				background-size: 21px;
				background-color: #02a1e5;
				max-width: unset !important;
				padding: 18px 40px;
				font-size: 20px;
			}

			&:hover {
				background-color: #021458
			}
		}


	.next-butt {
		display: inline-block;
		padding: 18px 50px;
		color: #FFFFFF !important;
		font-size: 24px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		margin: 7px auto !important;
		min-width: 250px !important;
		cursor: pointer;
		text-align: center;
		font-weight: 700;
		background: url(../../img/right-arrow.png)no-repeat center right 44px;
		background-size: 21px;
		background-color:#ec6842;
		outline: none;
		border: 0;
		height: auto !important;

		@media (max-width: 510px) {
			background: url(../../img/right-arrow.png)no-repeat center right 70px;
			background-size: 21px;
			background-color: #ec6842;
			max-width: unset !important;
			padding: 18px 40px;
			font-size: 20px;
		}

		&:hover {
			background-color: #021458
		}
	}



	}

	.error_msg {
		color: white;
		margin-bottom: 2px;
		width: 100%;
		padding: 1px 5px;
		background: #ff0000;
		display: inline-block;
		font-size: 13px;
		border-radius: 5px;
	}

	.modal {
		.select_search{
			input {
				display: block !important;
			}
		}
		background: rgba(0, 0, 0, 0.86);

		input:checked+label {
			color: black !important;
			background: none !important;
		}

		// padding-right: 0 !important;
		.modal-popupdetails {
			display: flex;
			border-radius: 20px 20px 20px 20px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
			padding: 5%;
			background-image: url(../../img/bubble_bg.png);
			background-position: center right;
			background-repeat: no-repeat;
			background-size: contain;

			.modal-header {
				border: 0;
				justify-content: center;

				.modal-title {
					img {}
				}
			}

			.modal-body {
				padding: 10px 0;

				h2 {
					color: #02a1e5 !important;
					font-weight: 700;
					font-size: 24px;
					margin-bottom: 20px;
					text-align: center;

					@media (max-width: 768px) {
						font-size: 18px;
						text-align: center;
					}
				}

				.fs-6 {
					font-size: 1rem !important;
					margin: 10px 0;
				}

				.name-col {
					width: 100%;
					background: #fff url(../../img/name_bg.png) no-repeat left top;
					background-size: 20px;
					background-position: 5px 20px;
					padding: 20px;
					padding-left: 35px;
					border-radius: 10px;
					display: inline-block;
					box-shadow: 0 3px 40px 0 #0000001a;
					margin: 10px 0;

					p {
						margin: 0px;
					}
				}

				.address-col {
					width: 100%;
					background: #fff url(../../img/loc_bg.png) no-repeat left top;
					background-size: 25px;
					background-position: 5px 20px;
					padding: 20px;
					padding-left: 35px;
					border-radius: 10px;
					display: inline-block;
					box-shadow: 0 3px 40px 0 #0000001a;
					margin: 10px 0;

					p {
						margin: 0px;
					}
				}
			}

		}

	}


	.modal-backdrop {
		z-index: 0 !important;
	}

	.add-lndr-skip {
		display: inline-block;
		padding: 5px 10px;
		cursor: pointer;
		text-align: center;
		background-size: 14px;
		background-color: #cccccc;
		font-weight: 500;
		outline: none;
		border: 0;
		height: auto !important;
		max-width: 100px !important;
		border-radius: 6px;
	}
}