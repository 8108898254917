.review-slider {
  $reviews-io-top-height: 300px;
  $reviews-io-list-height: 200px;
  $reviews-io-list--rating-size: 20px;
  $reviews-io-list--comment-size: 12px;
  $reviews-io-list--author-size: 14px;
  $reviews-io-list--logo-width: 120px;


    margin: 0 auto;
    min-height: 200px;
    overflow: hidden;
    padding: 8px;
    pointer-events: all;
    position: relative;
    width: 56%;
    margin-top: 20px;
    float: left;
    min-height: 220px;
    color: #000;
  

  .reviews-io-logo-wrapper {
    color: #fff;
    display: flex;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    width: $reviews-io-list--logo-width;
    .reviews-io-logo {  
      width: 133px;
  } 

   
  }

  .review-io-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    justify-content: space-between;
    margin-top: 4px;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }

    p.review-io-item--comment {
      flex: 1 1;
      font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica, Arial, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
      font-size: $reviews-io-list--comment-size;
      font-weight: 400;
      line-height: 1.1;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    p.review-io-item--name {
      font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica, Arial, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
      font-size: $reviews-io-list--author-size;
      font-weight: 700;
      text-align: center;
    }
  }

  .review-io-item--rating {
    .reviews-io-stars {
      text-align: center;
      i {
        color: #fcd015;
        font-size: 0.8125em;
      }
    }
  }
}

@media screen and (max-width: 768px) {
.review-slider {
  margin: 0 auto;
  min-height: 200px;
  overflow: hidden;
  padding: 8px;
  pointer-events: all;
  position: relative;
  width: 100%;
  margin-top: 40px;
  float: left;
  min-height: 220px;
  color: #000;
}

}