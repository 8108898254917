.followup_method_trigger{

*, *::after, *::before {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    color: #283040;
    text-decoration: none;
}

.TGGtcg{
    font-family: 'Poppins', sans-serif;

    .bg_clor{
        background: linear-gradient(to right bottom, #0e43a8, #2879b5, #0d182c);
        background-size: cover;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        position: relative;
        border-bottom-right-radius: 10rem;
        @media (max-width: 768px) {
            border-bottom-right-radius: 2rem;
        }
    }

    .bg_grade{
        position: relative;

        #header{
            position:absolute;
            top:1.2rem;
            width:100%;
            z-index: 5;
            transition:0.5s;
            display: flex;
            @media (max-width: 968px) {
                text-align: center;
            }
            img{
                height: 40px;
                 border-radius: 5px;
                 @media (max-width: 780px) {
                    height: 35px;
                }
             }//logo
        }
    }

    .section_create{
        height: 100%;
        position: relative;

          
        @media screen and (max-width: 768px) {
            .content {
                // background: url(../img/income-pana.png) no/
                background-position: 50%;
                padding-top: 10px !important;
            }
        } 
        .form-control {
            border-radius: 5px;
            padding: 15px 15px;
            font-size: 16px;
            border-radius: 8px;
            border: solid 3px #d7d7d7;
            background: #ffff !important;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        }
        fieldset{
            border: solid 1px #4ca5e9;
            border-radius: 10px;
            padding: 0 20px;
            legend{
                float: none;
                display: inline-block;
                background: transparent;
                width: initial;
                padding:  10px;
                text-align: left;
                margin-bottom: 0;
                font-size: 20px;
                @media (max-width:768px) {
                    font-size: 18px;
                }
                color: #fff;
 
            }
        }
        


    }


    .zoom1 {
        animation: zoom-in-zoom-out-new 1s ease-in-out infinite;
      }
      
      @keyframes zoom-in-zoom-out-new {
        0% {
          transform: scale(0.99, 0.99);
         
        }
      
        50%{
            transform: scale(1, 1);
           
        }
      
        100% {
          transform: scale(0.99, 0.99);

        }
      }
      

      


// ======== main button design =============    
.main-btn {
    padding:12px 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ffa58c;
    border-bottom: 3px #ffa58c solid;
    background-color: #ed6843;
    color: #fff;
    margin: auto;
    transition: .25s ease;
    display: inline-block;
    margin-bottom: 15px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    &:hover{
        background-color: #e23a0b;
    }
}

.sml_text{
    font-size: 11px !important;
}




  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


.banner-wrap{
    img{
        max-width: 420px;
    }
}






footer {
    img {
        height: 40px;
        @media (max-width: 568px) {
            height: 30px;
        }
    }
    p {
        font-size: 13px;
    }
    ul{
        list-style: none;
        li{
            list-style-type: none;
            a {
                font-size: 12px;
                color: #1b1b1b;
                text-decoration: underline !important;
                color: #0056a0;
            }
        }
    }
}


}


}
