
.TheClaimsGuys_ver_A {
	font-family: "Inter", sans-serif !important;
/*
*******************************************
Template Name:  The Claims Guys
Author: Abhilash GP
Date:07-06-2024
Template By: Vandalay Designs
Copyright 2024-2025 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - HEADER
05 - Questionnaie

08 - FOOTER
09 - MODAL
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

$background_color_1:#ffffff;
$background_color_2:#063783;
$background_color_3:#f0f2f9;


$color_1:#ffffff;
$color_2:#063783;
$color_3: #000;

$font-normal:normal;


// ======border===========



/*================================================
	BODY STYLES
==================================================*/
*, ::after, ::before {
    box-sizing: border-box;
}

*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	// color: unset !important;
}

em {
    font-style: normal;
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

.pre_name_section{
	input{
		width: 100%;
    height: 56px;
    outline: none;
    border-radius: 5px;
    appearance: none;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
    color: #063783;
    border: 2px solid #063783;
		box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4)
	}
	.prev-field-btn{
    color: white;
    background-color: #063783;
    padding: 8px;
    border-radius: 8px;
		border-style: none;
	}
}

.pre_address_section{
	input{
		display: block !important;
		width: 100%;
    height: 50px;
    outline: none;
    border-radius: 5px;
    appearance: none;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
    color: #080D21;
    border: 2px solid #080D21;
	}
	.prev-field-btn{
    color: white;
    background-color: #063783;
    padding: 8px;
    border-radius: 8px;
		border-style: none;
	}
	.prev-find-address{
		background: #063783;
    color: white;
	}
}

	//error msg color
	.error_msg{
	background: red;
    border-radius: 4px;
    width: 100%;
    margin-left: 0px;
    margin-top: 5px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
		display: block;
	}

	.error_msg1 {
		background: red;
		border-radius: 4px;
		width: 96%;
		margin: auto;
		color: white;
		display: block;
		@media screen and (max-width: 512px) {
			width: 90%;
		}
	}
	// pre loader design
	#preloader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color:$background_color_2;
		z-index: 999999;
		#loader {
			display: block;
			position: relative;
			left: 50%;
			top: 50%;
			width: 150px;
			height: 150px;
			margin: -75px 0 0 -75px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #fff;
			-webkit-animation: spin 2s linear infinite;
			animation: spin 2s linear infinite;
			&:before {
				content: "";
				position: absolute;
				top: 5px;
				left: 5px;
				right: 5px;
				bottom: 5px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #6984b4;
				-webkit-animation: spin 3s linear infinite;
				animation: spin 3s linear infinite;
			}
			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 15px;
				right: 15px;
				bottom: 15px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #fff;
				-webkit-animation: spin 1.5s linear infinite;
				animation: spin 1.5s linear infinite;
			}
		}//loader
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}//@keyframes spin
		h3{
			color: $color_1;
			font-weight: 600;
			font-size: 20px;
			text-align: center;
		}
		h6{
			color: $color_1;
			font-weight: 600;
			font-size: 16px;
			text-align: center;
		}
	}//preloader

	// sticky banner design

	//header design
	header{
		width: 100%;
		transition: .4s all;
		background: $background_color_2;
		@media screen and (max-width: 992px) {

		}

		.logo_img {
			width: 220px;

			@media (max-width: 510px) {
			  width: 200px;
			}
		  }

		.navbar{
			justify-content: space-between;
			// background: $background_color_1;
			display: inline-block !important;
			padding: 10px 10px 5px 10px;
			margin: 10px 0;
			border-radius:5px;
			@media screen and (max-width: 768px) {
				justify-content: space-between;
				display: flex;
				align-content: center;
				align-items: center;
				width: 100%;
			}
			@media screen and (max-width: 992px) {

			}
			.navbar-brand{
				margin: 0px;
				img{
					max-height:38px;
					@media screen and (max-width: 576px) {
						max-height:30px;
					}
				}
			}//navbar-brand


		}//navbar
	}//header

.banner_part{
	background: $background_color_3 url(../../img/banner-bga.png) no-repeat right top;
	background-position: 100% 0;
	padding: 50px 0 45px;

	.form-area{
		h2{
			font-size: 44px;
			line-height: 52px;
			font-weight: 600;
			color: $color_2;
			margin-bottom: 20px;
		}
		h3{
			font-size: 22px;
			line-height: 1.5;
			font-weight: 600;
			color: $color_2;
			margin-bottom: 20px;
		}
		h4{
			font-size: 18px;
			line-height: 1.5;
			font-weight: 600;
			color: $color_2;
			margin-bottom:15px;
		}
		.questionwrapper{
			padding: 15px 15px 30px;
			background: #fff;
			border-radius: 7px;
			border-top: 5px solid #063783;
			border-bottom: 5px solid #063783;
			box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

		}

		.contacthead{
			font-size: 24px;
			line-height: 1.5;
			font-weight: 600;
		}
		fieldset {
			border: 1px solid #063783;
			border-radius: 10px;
		}
		legend {
			float: none;
			display: inline-block;
			background: transparent;
			width: initial;
			padding: 0 10px;
			text-align: left;
			margin-bottom: 0;
			font-size: 16px;
			line-height: 1.5;
			font-weight: 600;
			color: #063783;
		}
		.form-control {
			padding: 15px;
			color: #063783;
			border: 2px #063783 solid;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
		}
		.input-group-text{
			background: #063783;
			color: #fff;
			font-size: 30px;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
			border: 1px solid #063783;
		}
		.form-select {
			padding: 15px;
			border: 2px #063783 solid;
			color: #063783;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
		}
		// selectoptions

.selectoptions{
	input[type=checkbox]+label {
		background: url(../../img/uncheck.png) no-repeat left center;
		background-position:15px;
		background-size: 20px;
		border-radius: 3px;
		background-color: $background_color_1;
		color: #063783;
		cursor: pointer;
		border: 2px $color_2 solid;
		cursor: pointer;
		display: inline-block;
		// height: 40px;
		height: auto;
		padding:15px 0 ;
		width: 100%;
		border-radius:0px;
		text-align: center;
		margin-bottom: 15px;
		box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);
		transition: 0.4s ease-in-out 0s;
		@media (min-width: 769px){

			width: 49%;
			margin:0px 1% 15px 0;
			float: left;
		}
		@media screen and (max-width: 768px) {
			margin-bottom:5px;
			border-radius: 7px;

		}

	}
	input[type=checkbox]:checked+label {
		background: url(../../img/check.png) no-repeat left center;
		background-position:15px;
		background-size: 20px;
		border: 2px $color_2 solid;
		cursor: pointer;
		display: inline-block;
		background-color: $background_color_2;
		color: #fff;
	}
	label {
		// float: left;
	}
	input[type=checkbox] {
		display: none;
	}
	.option-btn{
		font-size: 16px;
		text-align: start;
		display: flex;
		align-items: center;
		border: 2px solid #063783;
		padding: 10px 10px;
		width: 46%;
		min-height: 70px;
		margin: auto;
		position: relative;
		@media screen and (max-width: 768px) { width: 96%; min-height: inherit;}
		.check{
			width: 22px;
			height: 22px;
			border-radius: 0;
			display: inline-block;
			border: 1px solid #063783;
			margin-right: 10px;
			position: relative;
		}
		.textcont {
			width: 80%;
		}
		label {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			input {
				opacity: 0;
			}
		}
	}
	.current {
		background: #063783;
		border: 1px solid #063783;
		color: #ffffff;
		.check {
			border: 1px solid #063783;
			background: #063783 url(../../img/check.png) no-repeat center 3px;
			background-size: 22px;
			height: 30px;
			width: 25px;
	}
}
}

.option {
	float: left;
    height: auto;
    margin: 0 0 0px 0;
    width: 100%;
    padding: 0 0px;
}
.option_title {

    float: left;
    font-size: 16px;
    font-weight:500;
    height: auto;
    // line-height: 18px;
    margin-left: 45px;
    margin-top: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    top: 0;
    z-index: 120;

	@media (max-width: 768px){
		font-size: 15px;
	}
}
// selectoptions




// custom select dropdown

/*the container must be positioned relative:*/
.custom-select {
	position: relative;
	font-family: Arial;
	display: inline-block;
	 border:solid 2px #063783;
	 width:100%;
	 margin-bottom: 15px;
  }

  .custom-select select {
	display: none; /*hide original SELECT element:*/
  }

  .select-selected {
	background-color: #fff;
	border-radius:0px;
	box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);

  }

  .select-selected-bottom-rounded {
	border-radius: 0px;
  }

  .select-selected-bottom-square {
	border-radius: 0px;
  }

  /*style the arrow inside the select element:*/
  .select-selected:after {
	position: absolute;
	content: "";
	top: 45%;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #063783 transparent transparent transparent;
  }

  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
	border-color: transparent transparent #063783 transparent;
	top: 25%;
  }

  /*set the styling and height for the list when the select box is open (active), the overflow-y property controls the scrolling:*/
  .select-items{
	border-radius: 0px 0px 0px 0px;
	height: 110px;
	overflow-y: auto;
	position: absolute;
	background-color: #fff;
	top:48px;
	left: 0;
	right: 0;
	z-index: 99;
  }

  /*style the items (options), including the selected item:*/
  .select-items div,.select-selected {
	color: #063783;
	padding:15px 16px;
	border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
	user-select: none;
  }

  /*hide the items when the select box is closed:*/
  .select-hide {
	display: none;
  }

  .select-items div:hover, .same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
  }

  /* scrollbar width */
  .select-items::-webkit-scrollbar {
	width: 5px;
  }

  /* scrollbar track */
  .select-items::-webkit-scrollbar-track {
	background:  #063783;
	border-radius: 0px;
  }

  /* scrollbar handle */
  .select-items::-webkit-scrollbar-thumb {
	background:  #ec6842;
	border-radius: 4px; /* optional rounded handle */
  }

  /* scrollbar handle on hover */
  .select-items::-webkit-scrollbar-thumb:hover {
	background: #6cb6ff;
  }

// select dropdown end

.button {
	background-image: url(../../img/right-chevron.svg) !important;
	background-size: 17% 42px !important;
	background-repeat: no-repeat !important;
	background-position: 100% !important;
	display: inline-block;
	background: $background_color_2;
	color: #fff;
	padding: 16px 0px;
	border-radius: 7px;
	border: 0;
    font-size: 22px;
    width: 50%;
    // max-width: 200px;
	font-weight: 600;
	box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
	cursor: pointer;
	transition: all .3s ease-in-out;
	&:hover{
		background: #01b636;
	}
}
.green_color_but{
	background-image: url(../../img/right-chevron.svg) !important;
	background: #01b636 ;
	transition: all .3s ease-in-out;
	&:hover{
		background: #ec6842 ;
	}
}

.backmain_but{
	background-image: url(../../img/left-chevron.svg) !important;
	background-position: 10% !important;
}

.button_lookup {
	background-image: url(../../img/location.png) !important;
    background-size: 15px 22px !important;
    background-repeat: no-repeat !important;
    background-position: 90% !important;
	font-size: 18px;
}

.choose_Y_N{
	display: flex;
	width: 100%;
	padding: 0 0px;
	gap: 8px;

	@media (max-width: 768px){
		flex-direction: column;
		gap: 8px;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		flex-direction: column;
		gap: 8px;
	}
/////////////////////////// form check /////////////////////////////////
.form-check {
	margin-bottom: 10px;
	@media (max-width: 768px){
		width: 100% !important;
	}
	[type="radio"] {
		display: none;
	}
	[type="checkbox"] {
		display: none;
	}
	// & + .form-check {
	//  width: 50%;
	// }
	label {
		display: block;
		padding: 13px 60px;
		background: #fff;
		border: 2px solid #063783;
		box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);
		border-radius: 5px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		color: #063783;
		white-space: nowrap;
		position: relative;
		transition: 0.4s ease-in-out 0s;
		@media (max-width: 768px){
			display: block;
			padding: 13px 10px 13px 50px;
			width: 100%;
			white-space: inherit;
		}
		&:after{
			height: 19px;
			width: 19px;
			border: 2px solid #063783;
			left: 19px;
			top: calc(50% - 10px);
		}
		&:before {
			background: #004d8b;
			height: 11px;
			width: 11px;
			left: 23px;
			top: calc(50% - 6px);
			transform: scale(5);
			opacity: 0;
			visibility: hidden;
			transition: 0.4s ease-in-out 0s;
		}
	}
	[type="radio"]:checked {
		& ~ label {
			border-color: $color_2;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			background: $background_color_2;
			color: #ffffff;
			&::before {
				background: #fff;
				opacity: 1;
				visibility: visible;
				transform: scale(1);
			}
			&::after{
				border: 2px solid #fff;
			}
		}
	}
	[type="checkbox"]:checked {
		& ~ label {
			border-color: $color_2;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			background: $background_color_2;
			color: #ffffff;
			&::before {
				background: #fff;
				opacity: 1;
				visibility: visible;
				transform: scale(1);
			}
			&::after{
				border: 2px solid #fff;
			}
		}
	}
}
.form-check label:after,
.form-check label:before {
	content: "";
	position: absolute;
	border-radius: 50%;
}
///////////////////////// form check /////////////////////////////////
}//choose_bw

// animated fixed but
@media screen and (max-width: 768px) {
.fixed_but{
	bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
}
.but_anim{
	animation: mymove 1s infinite;
}

@keyframes mymove {
  50% {box-shadow: 0px 0px 50px #063783;}
}
}
// animated fixed but end



}

.top_right_sec{
	.fixed {
        display: flex;
        flex-direction: column;

        position: -webkit-sticky;
        position: sticky;
        right:0px;
        top: 0;
    }
	.rating{
		margin-bottom:15px;
		img{
			height: 20px;
		}
	}
	.potential-claim {
		background: #ec6842 url(../../img/bg-claim.png) no-repeat;
		background-size: cover;
		border-radius: 0px;
		color: #fff;
		margin: 20px auto 10px;
		position: relative;
		width: 100%;
		padding: 25px 15px;
		text-align: center;
		box-shadow: 0 25px 25px rgba(5,28,49,.14);
		-webkit-box-shadow:0 25px 25px rgba(5,28,49,.14);
		&::before{

				background: url(../../img/left_ar.png) no-repeat;
				background-size: 100% 100%;
				content: "";
				height: 34px;
				left: -20px;
				position: absolute;
				top: 50px;
				width: 20px;

		}
		@media screen and (max-width: 768px) {
			&::before{ display: none;}
		&::after {
			background: url(../../img/bot_ar.png) no-repeat;
			content: "";
			height: 24px;
			position: absolute;
			bottom: -23px;
			left: 45%;
			width: 30px;
			background-size: contain;
		}
	}



		h4{
			font-size: 20px;
			font-weight: 400;
			span{
				font-size: 64px;
       			 line-height: 64px;
				 font-weight: 800;
				 margin-top: 15px;
				 display: block;

			}
		}
	}

	.useful-facts {
		background: #ffe500  url(../../img/bg-claim2.png) no-repeat;
		background-size: 100% 100%;
		border-radius: 0px;
		color: $color_2;
		margin: 20px auto 10px;
		position: relative;
		width: 100%;
		padding: 15px;
		box-shadow: 0 25px 25px rgba(5,28,49,.14);
		-webkit-box-shadow:0 25px 25px rgba(5,28,49,.14);

		&::before{

				background: url(../../img/left_ar_2.png) no-repeat;
				background-size: 100% 100%;
				content: "";
				height: 34px;
				left: -20px;
				position: absolute;
				top: 50px;
				width: 20px;

		}
		@media screen and (max-width: 768px) {
			margin: 35px auto 10px;
			border-radius: 7px;
			&::before{ display: none;}
		}
		h4{
			font-size: 20px;
			font-weight: 600;
			text-align: center;
		}

		ul{
			margin: 0px;
			padding: 0px;
			padding-left: 15px;
			li{
				background: url(../../img/fact_ico.png) no-repeat center left;
				background-size: 24px;
				list-style: none;
				padding: 10px 0;
				padding-left: 35px;
			}
		}

	}






}


}

.prev-dtls {
	.form-popupdetails {

		@media (max-width: 510px) {
			margin-top: 20px;
		}

		.personal_detail {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
				gap: 0px;
			}
		}

		.choose_Y_N {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;

			@media (max-width: 510px) {
				grid-template-columns: 1fr;
			}
		}

		.options {
			display: flex;
			clear: both;
			width: 100%;
			flex-wrap: wrap;

			li {
				width: auto;
				display: block;
				// float: left;
				margin: 0 10px 10px 0;
			}
		}

		.options.two {
			li {
				width: calc(50% - 10px);
				// float: left;
			}
		}

		//.options.two
		@media only screen and (min-width: 480px) and (max-width: 768px) {
			.options.two.lender li {
				width: 100%;
			}
		}

		@media only screen and (max-width: 480px) {

			.options li,
			.options.two li {
				width: 100%;
				margin: 0 0 10px 0;
			}
		}

		@media only screen and (min-width: 960px) and (max-width: 1200px) {
			.options.two.lender li {
				width: 100%;
			}
		}

		.additional_lenders {
			margin: -9px 0 5px 0;
			display: block;
			text-align: center;
			color: #1f5652 !important;
			cursor: pointer;
			font-size: 18px;
			text-decoration: underline;
			font-weight: 500;

			@media (max-width: 510px) {
				font-size: 16px;
			}
		}

		//additional_lenders

		.anotherlenderoptions {
			label {
				span {
					padding: 0;
					font-size: 14px !important;
				}
			}
		}

		.margin_use {
			margin: 0 10px 10px 0 !important;

			@media (max-width: 510px) {
				margin: 0 0px 6px 0 !important;
			}
		}

		.custom-checkbox {
			display: none;
		}

		label {
			color: #1b1b1b;
			font-size: 22px;
			font-weight: 600;
			display: block;
			clear: both;
			margin-bottom: 13px;
			line-height: 140%;

			@media (max-width: 510px) {
				font-size: 20px;
			}

			span {
				font-size: 18px;
				display: block;
				line-height: 160%;
				padding: 5px 0 0 0;
				clear: both;

				@media (max-width: 510px) {
					font-size: 17px;
				}
			}

		}

		.custom-checkbox:checked+label {
			background: #FFFFFF;
			background: #EBFFEB;
			border: 2px solid #00A400;
		}

		.custom-checkbox:checked+label:before {
			border: 2px solid #00A400;
			// background-color: #00A400;
			content: '';
			background: #00A400 url(../../img/check.png) no-repeat center center;
			background-size: 80%;
		}

		.custom-checkbox+label {
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			border-radius: 5px;
			width: 100%;
			font-size: 14px;
			font-weight: 600;
			background: #FFFFFF;
			border: 2px solid #080D21;
			padding: 10px 20px 10px 50px;
			height: 50px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			color: #080D21;

			&:before {
				border: 2px solid #080D21;
				background: #FFFFFF;
				width: 18px;
				height: 18px;
				content: '';
				margin: 0;
				position: absolute;
				top: 13px;
				left: 17px;
				display: block;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			}

			&:active {
				-webkit-box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
				box-shadow: 0px 0px 0px 5px rgba(8, 13, 33, 0.15);
			}
		}

		//.custom-checkbox + label
		input,
		#idpc_button,
		select,
		textarea {
			width: 100%;
			height: 50px;
			outline: none;
			border-radius: 5px;
			-webkit-appearance: none;
			appearance: none;
			padding: 0 20px;
			font-size: 16px;
			font-weight: 500;
			color: #080D21;
			border: 2px solid #080D21;
			z-index: 30;
		}

		select {
			background: #FFFFFF url(../../img/select.svg) no-repeat right 20px center;
			background-size: 15px 15px;
			-webkit-background-size: 15px 15px;
			-moz-background-size: 15px 15px;
			-o-background-size: 15px 15px;
			padding: 0 50px 0 20px;
		}

		.input-group-text {
			display: flex;
			align-items: center;
			padding: 0.375rem 0.75rem;
			height: 50px;
			margin-bottom: 0;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border-left: 0;
			border: 1px solid #080d21;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}


		// ==============================
		// BUTTON DESIGN
		// ==============================


		.next-but {
			display: inline-block;
			padding: 18px 50px;
			color: #FFFFFF !important;
			font-size: 24px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			margin: 7px auto !important;
			min-width: 250px !important;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			background: url(../../img/right-arrow.png)no-repeat center right 44px;
			background-size: 21px;
			background-color: #02a1e5;
			outline: none;
			border: 0;
			height: auto !important;

			@media (max-width: 510px) {
				background: url(../../img/right-arrow.png)no-repeat center right 70px;
				background-size: 21px;
				background-color: #02a1e5;
				max-width: unset !important;
				padding: 18px 40px;
				font-size: 20px;
			}

			&:hover {
				background-color: #021458
			}
		}



	}
	.next-but {
		display: inline-block;
		padding: 18px 50px;
		color: #FFFFFF !important;
		font-size: 24px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		margin: 7px auto !important;
		min-width: 250px !important;
		cursor: pointer;
		text-align: center;
		font-weight: 700;
		background: url(../../img/right-arrow.png)no-repeat center right 44px;
		background-size: 21px;
		background-color: #02a1e5;
		outline: none;
		border: 0;
		height: auto !important;

		@media (max-width: 510px) {
			background: url(../../img/right-arrow.png)no-repeat center right 70px;
			background-size: 21px;
			background-color: #02a1e5;
			max-width: unset !important;
			padding: 18px 40px;
			font-size: 20px;
		}

		&:hover {
			background-color: #021458
		}
	}



	.back-but {
		display: inline-block;
		padding: 5px 10px;
		cursor: pointer;
		text-align: center;
		// background: url(../../img/left-arrow.png)no-repeat center left 10px;
		background-size: 14px;
		background-color: #cccccc;
		font-weight: 700;
		outline: none;
		border: 0;
		height: auto !important;
		max-width: 100px !important;

		@media (max-width: 510px) {
			background: url(../../img/left-arrow.png)no-repeat center left 80px;
			background-size: 14px;
			background-color: #cccccc;
			max-width: unset !important;
			font-size: 20px;
		}
	}
	.modal {
		background: rgba(0, 0, 0, 1);

		// padding-right: 0 !important;
		.modal-popupdetails {
			display: flex;
			border-radius: 20px 20px 20px 20px;
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
			padding: 5%;
			background-image: url(../../img/bubble_bg.png);
			background-position: center right;
			background-repeat: no-repeat;
			background-size: contain;

			.modal-header {
				border: 0;
				justify-content: center;

				.modal-title {
					img {}
				}
			}

			.modal-body {
				padding: 10px 0;

				h2 {
					color: #02a1e5 !important;
					font-weight: 700;
					font-size: 24px;
					margin-bottom: 20px;
					text-align: center;

					@media (max-width: 768px) {
						font-size: 18px;
						text-align: center;
					}
				}

				.fs-6 {
					font-size: 1rem !important;
					margin: 10px 0;
				}

				.name-col {
					width: 100%;
					background: #fff url(../../img/name_bg.png) no-repeat left top;
					background-size: 20px;
					background-position: 5px 20px;
					padding: 20px;
					padding-left: 35px;
					border-radius: 10px;
					display: inline-block;
					box-shadow: 0 3px 40px 0 #0000001a;
					margin: 10px 0;

					p {
						margin: 0px;
					}
				}

				.address-col {
					width: 100%;
					background: #fff url(../../img/loc_bg.png) no-repeat left top;
					background-size: 25px;
					background-position: 5px 20px;
					padding: 20px;
					padding-left: 35px;
					border-radius: 10px;
					display: inline-block;
					box-shadow: 0 3px 40px 0 #0000001a;
					margin: 10px 0;

					p {
						margin: 0px;
					}
				}
			}

		}

	}
}
// modal
.modal {background-color: rgba(0,0,0,0.75);}
.form-modal{
	.modal-header{
		background: $background_color_2;
		text-align: center;
		display: block;
		img{
			max-height: 35px;
		}
		@media screen and (max-width: 767px){
			img{
				max-height: 30px;
			}
		}

	}
	.modal-body{
		color: $color_2;
		h3{
			font-size: 36px !important;
			font-weight: 900;
		}
		p{
			font-size: 16px;
			font-weight:500;
		}
		label{
				font-size: 22px;
				font-weight:600;
		}
		.form-control{
			padding: 15px;
			background: $background_color_1;
			color: $color_2;
			border: 2px #063783 solid;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
		}
		.input-group-text{
			background: #063783;
			color: #fff;
			font-size: 30px;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
			border: 1px solid #063783;
		}

	}
	.modal-footer{
		background: $background_color_2;
		p{
			font-size: 13px;
			color: $color_1;
		}
		img{
			filter: invert(1);
		}
	}
	@media screen and (max-width: 767px){
		.modal-body{
			h3{
				font-size: 26px !important;
			}
			p{
				font-size: 14px;
			}
			label{
					font-size:18px;
			}

		}
	}
}

//
/*--------------------------------------------------------------
10. accordion
----------------------------------------------------------------*/
.faq_area{
    background: #e8e8e8 url(../../img/faq_bg.png) no-repeat center left;
    padding: 50px 0 80px;
	background-size: cover;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	h2{
	font-weight: 600;
    color: $color_2;
    text-align: center;
    margin-bottom: 15px;
	}
	.accordion {
		width: 100%;
		h2{
			font-size: 26px;
			@media screen and (max-width: 768px) {
				font-size: 22px;
			}
		}
		  .accordion-item {
			margin-bottom: 10px;
			border-radius: 7px;
			input[type="checkbox"] {
			  display: none;
			}
			h2{
			color:$color_1;
			font-weight: 600;
			margin: 0;
			font-size: 16px !important;
				.accordion-button{
					background:$background_color_2;
					border-radius: 7px;
					font-weight: 600;
					font-size: 16px !important;
					color:$color_1;
					outline:none;
					box-shadow: none;
					&::after{
						filter: brightness(0) invert(1);
					}
				}
			}
		}
		.accordion-body{
			background: #fff;
			border-radius: 7px;
			color:$color_2;
			border: 1px solid #eee;
			font-size: 16px !important;
		}


			.content {
			  width: 100%;
			  transition: all 0.3s ease;
			  opacity: 0;
			  pointer-events: none;
			  padding: 8px 16px;
			  position: relative;
			  z-index: 1;
			  padding-left: 20px;
			  max-height: 0;
			  margin-top: -38px;
			  color: #717171;
			  padding: 25px 25px 25px 25px;
			  border: 1px solid #eee;
			  font-size: 16px !important;
			  &.show {
				margin: 0px 0 13px;
				max-height: fit-content;
				opacity: 1;
				pointer-events: all;
				background: #fff;
			  }
			  p {
				margin-bottom: 8px;
				color: gray;
			  }
			}

	  }




}


/* ==================================
    FOOTER
================================== */

footer{
    padding: 25px 0;
    background:$background_color_1;
    p{
        color: $color_2 !important;
        font-size: 14px;
    }
    ul{
        padding: 0; margin: 0; margin-bottom: 15px;
        @media screen and (max-width: 767px){
            text-align: center;
            padding: 0;
        }
        li {
        list-style: none;
        display: inline;
        color: $color_2 !important;
            a {
                font-size: 14px;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}//footer


@keyframes pulse-animationhome {
    0% {
      box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  .pulsehome {
    animation: pulse-animationhome 1.5s infinite;
  }


  @media screen and (max-width: 768px) {
	.banner_part{
		padding: 15px 0 45px;
		.form-area{
			h2{
				font-size: 24px;
				line-height: 30px;
				margin: 15px 0;
			}
			h3 {
				font-size: 18px !important;
				margin-bottom: 15px;
			}
			h4{
				font-weight: 700;
				font-size: 18px;
			}
			.button {
				width: 98% !important;
				max-width: inherit;
				margin-top: 15px;
			}
		}

		.top_right_sec {
			.potential-claim{
				margin: 0px auto 10px;
				padding: 10px 0px;
				border-radius: 7px;
				h4{
					font-size: 16px;
					span{
						font-size: 24px;
						line-height: 23px;
						margin-top: 0px;
						display: inline-block;
					}
				}
			}
		}

	}

	.faq_area{
		padding: 40px 0;
	}

	.back-btn{
		width: 98% !important;
		margin-top: 15px !important;
	}

  }



  .bck-btn{
	border: none;
	border-radius: 7px;
	padding: 5px 15px;
	background: #063783;
	color: #fff;
	font-weight: 600;
	background-image: url(../../img/left-chevron.svg) !important;
	background-size: 35% 45px !important;
    background-repeat: no-repeat !important;
    background-position: 10% !important;
    display: inline-block;
    padding-left: 35px;
	&:hover{
		background: #ec6842;
	}
}

.nxt-btn{
	width: 46% !important;
	margin: auto;
}
.back-btn{
	display: inline-block;
    background: #063783;
    color: #fff;
    padding: 16px 0px;
    border-radius: 7px;
    border: 0;
    font-size: 22px;
    width: 46%;
	margin: auto;
    font-weight: 600;
    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all .3s ease-in-out;
	background-image: url(../../img/left-chevron.svg) !important;
	background-size: 15% 45px !important;
    background-repeat: no-repeat !important;
    background-position: 0 !important;
    display: inline-block;
	&:hover{
		background: #ec6842;
	}

}

.check_btn2 {
	background: #063783 !important;
	border-color: #063783 !important;
	color: rgb(243, 240, 240) !important;

	&::before {
		background: #fff !important;
		opacity: 1 !important;
		visibility: visible !important;
		transform: scale(1) !important;
		z-index: 99;
	}

	&::after {
		border: 2px solid #fff !important;
		background: #063783 !important;
	}
}



}//The Claims Guys
