// @import "animate.min";

*, *::after, *::before {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    color: #0d6efd;
    text-decoration: none;
}

.TGGtcg{
    font-family: 'Poppins', sans-serif;

    .bg_clor{
        background: linear-gradient(to right bottom, #0e43a8, #2879b5, #0d182c);
        background-size: cover;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        position: relative;
        border-bottom-right-radius: 10rem;
        @media (max-width: 768px) {
            border-bottom-right-radius: 2rem;
        }
    }

    .bg_grade{
        position: relative;

        #header{
            position:absolute;
            top:1.2rem;
            width:100%;
            z-index: 5;
            transition:0.5s;
            display: flex;
            @media (max-width: 968px) {
                text-align: center;
            }
            img{
                height: 40px;
                 border-radius: 5px;
                 @media (max-width: 780px) {
                    height: 35px;
                }
             }//logo
        }
    }

    .section_create{
        height: 100%;
        position: relative;

        .top_pad_banner {
            height: 100%;
            width: 100%;
            padding: 110px 0 0px;
            position: relative;
            @media (max-width: 1300px) {
                padding: 90px 0 0px;
            }
            @media (max-width: 1199px) {
                padding: 90px 0;
            }
            @media (max-width: 980px) {
                padding: 90px 0 0px;
            }
            @media (max-width: 780px) {
                padding: 90px 0 0px;
            }
            @media (max-width: 510px) {
                padding: 80px 0 0px;
                width: 95%;
                margin: 0 auto;
            }
            @media (min-width: 1730px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media only screen and (max-width: 1730px) and (min-width: 1198px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }     
            @media only screen and (max-width: 1730px) and (min-width: 1398px) {
                padding: 90px 0 0px;
            }
            @media (max-width: 1730px) {
                display: flex;
                align-items: center;
                justify-content: center;
            } 
        }
          
        @media screen and (max-width: 768px) {
            .content {
                // background: url(../img/income-pana.png) no/
                background-position: 50%;
                padding-top: 10px !important;
            }
        } 

        .card {
          display: flex;
          flex-direction: column;
          background-color: white;
          width: 100%;
          height: auto;
          align-items: center;
          padding: 30px 20px;
          border-radius: 30px;
          border: 0;
          box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
          @media (max-width: 968px) {
              width: 100%
          }
          & img{
              width: 80px;
          }
        
          & h2 {
              padding-top: 15px;
              font-size: 22px;
              font-weight: 600;
              color: #ff0000;
              @media (max-width: 768px) {
                  font-size: 20px;
              }
          }
        
          & .card-text {
            font-size: 16px;
            text-align: center;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        
          & span {
            color: #01c90b;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            @media (max-width: 768px) {
              font-size: 18px;
            }
          }
      }
      h1{
        color: #ffffff;
        font-size: 28px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 10px;
        // padding-top: 40px;
        @media (max-width: 768px) {
            font-size: 23px;
            font-weight: 700;
            // padding-top: 40px;
            line-height: 120%;
            text-align: center;
        }
        @media (max-width: 568px) {
            // padding-top: 40px;
        }
    }


        h4{
            color: #ffffff;
            font-size: 26px;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                font-size: 20px;
                font-weight: 700;
                line-height: 120%;
                text-align: center;
            }
        }
        .banner-subtext {
            font-size: 1rem !important;
            color: #ffffff;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                font-size: 1rem;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        .subtext2 {
            font-size: 1.1rem !important;
            color: #ffffff;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                font-size: 1rem;
                margin-bottom: 10px;
                text-align: center;
            }
        }

        .slide_author{
            border: solid 1px #d6effe;
            padding: 15px;
            border-radius: 7px;
            position: relative;
            bottom: -35px;
            background: #ebf7ff;
        }

        .slide_header{
            // border: solid 1px #d6effe;
            // padding: 15px;
            border-radius: 7px;
            position: relative;
            // bottom: -35px;
            // background: #c7e8fe;
            img{
                max-height: 60px;
                margin-bottom: 15px;
            }
            p{
                font-size: 16px;
                font-weight: 700;
            }
            .demo{ 
              font-weight: 400; 
              font-size: 13px;
            }
            
            .yellow{
                background-color: yellow;
                padding: 2px;
                border-radius: 3px;
               }
               .p500{
                font-weight: 500 !important;
               }
               .docbut{
                text-decoration: none;
                padding: 10px;
                display: block;
                border: solid 1px #fff;
                border-radius: 5px;
                margin-bottom: 15px;
                background: #d6f0ff;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                &:hover{
                    background-color: #0056a0;
                    color: #fff;
                }
               }
        }
        .ess_info {
            display: flex;
            // gap: 32px;
            flex-direction: row;
            border-radius: 16px;
            background: #fff;
            padding: 20px 15px;
            box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.04);
            border: none;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            z-index: 0;
            width: 100%;
            margin-top: 20px;
            color: #224;
            backdrop-filter: blur(15px);
            h5 {
                font-weight: 600;
                margin: 0px 0 16px;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            ul {
                text-align: justify;
                padding-left: 15px;
                li {
                    color: #000;
                    font-weight: 400 !important;
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
            .w-100{width:100% !important}

            .choose_Y_N{
                display: flex;
                width: 100%;
                padding: 0 0px;
                gap: 8px;
            
                @media (max-width: 768px){
                    flex-direction: column;
                    gap: 8px;
                }
            /////////////////////////// form check /////////////////////////////////
         
            .form-check {
                margin-bottom: 10px;
                @media (max-width: 768px){
                    width: 100% !important;
                }
                [type="radio"] {
                    display: none;
                }
                [type="checkbox"] {
                    display: none;
                }
                // & + .form-check {
                //  width: 50%;
                // }
                .lender_pad{
                   padding: 13px 10px 13px 45px !important;
                   font-size: 15px;
                }
                label {
                    display: block;
                    padding: 13px 60px;
                    background: #fff;
                    border: 2px solid #0056a0;
                    box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    color: #0056a0;
                    white-space: nowrap;
                    position: relative;
                    transition: 0.4s ease-in-out 0s;
                    @media (max-width: 768px){
                        display: block;
                        padding: 13px 10px 13px 50px;
                        width: 100%;
                        white-space: inherit;
                    }
                    &:after{
                        height: 19px;
                        width: 19px;
                        border: 2px solid #0056a0;
                        left: 19px;
                        top: calc(50% - 10px);
                    }
                    &:before {
                        background: #0056a0;
                        height: 11px;
                        width: 11px;
                        left: 23px;
                        top: calc(50% - 6px);
                        transform: scale(5);
                        opacity: 0;
                        visibility: hidden;
                        transition: 0.4s ease-in-out 0s;
                    }
                }
                [type="radio"]:checked {
                    & ~ label {
                        border-color:#0056a0;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        background: #0056a0;
                        color: #ffffff;
                        &::before {
                            background: #fff;
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                        &::after{
                            border: 2px solid #fff;
                        }
                    }
                }
                [type="checkbox"]:checked {
                    & ~ label {
                        border-color: #0056a0;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        background: #0056a0;
                        color: #ffffff;
                        &::before {
                            background: #fff;
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                        &::after{
                            border: 2px solid #fff;
                        }
                    }
                }
            }
            .form-check label:after,
            .form-check label:before {
                content: "";
                position: absolute;
                border-radius: 50%;
            }
            ///////////////////////// form check /////////////////////////////////
            }//choose_bw
       
       
            p{
                font-size: 14px;
                font-weight:normal;
            }
       
            .signbox{
                canvas {
                    width: 100%;
                    border: 1px solid #cfcfcf;
                    min-height: 350px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    background: #f0f9fe;
                    @media (max-width: 768px){
                        min-height: 250px;
                    }
                }
            }
            
            .signimg {
                background: #f0f9fe url(../../img/followup/sign.jpg) center no-repeat

            }
            .clearbutton {
                padding: 10px;
                background: #d5d5d5;
                border: none;
                padding: 12px 30px;
                border-radius: 5px;
                color: #161616;
                margin-bottom: 15px;
                display: block;
            }

        }



        h5.card_head {
            font-weight: 600;
            color: #fff;
            margin: 16px 0 16px;
            @media (max-width: 768px) {
                text-align: center;
            }
        }


        .card_info {

            background-color: #fff;
            width: 100% !important;
            margin: 10px 0;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            position: relative;
        
            .icon {
                flex-shrink: 0;
                width: 50px;
                height: 50px;
                background-color: #e0e0e070;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
        
                img {
                    width: 36px;
                    height: 36px;
                }
            }
        
            .content {
                flex-grow: 1;
                @media screen and (max-width: 768px) {
                    padding-top: 0px !important;
                } 
        
                h3 {
                    margin: 0 0 5px;
                    font-size: 17px;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    } 
                }
        
                p {
                    margin: 0;
                    font-size: 15px;
                    color: #666;
                    @media screen and (max-width: 768px) {
                        font-size: 13px;
                    } 
                }
            }
        
            .ribbon {
                position: absolute;
                top: 0px;
                right: 10px;
                width: 20px;
                height: 20px;
                background-color: red;
                border-radius: 2px;
        
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -9px;
                    left: 0;
                    width: 0;
                    transform: rotate(180deg);
                    height: 0;
                    border-left: 10px solid red;
                    border-right: 10px solid red;
                    border-top: 10px solid transparent;
                }
            }
        }

.form_drop{
    color: #fff;
}

    }

    .form-select{
        border: 2px solid #0056a0;
        box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);



        .css-yk16xz-control{
            border: 0; 
            outline: 0;
            box-shadow: none !important;
            &:active , &:focus , &:focus-visible{ 
                border: 0;
                outline: 0;
                box-shadow: none !important;
            }
        }
    }
    .zoom {
        animation: zoom-in-zoom-out 1s ease-in-out infinite;
      }
      
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(0.9, 0.9);
          box-shadow: 0 0 0 0 rgba(255,109,74,0.7),  0 0 0 0 rgba(255,109,74,0.7);
        }
      
        50%{
            transform: scale(1, 1);
            box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
        }
      
        100% {
          transform: scale(0.9, 0.9);
          box-shadow: 0 0 0 0 rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
        }
      }
      

    .list-itemsc {
        list-style: none;
        >li {
          position: relative;
          padding-left: 38px;
          margin-bottom: 10px;
          font-size: 14px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
          &::before {
            width: 26px;
            height: 26px;
            content: "";
            background: url("../../img/followup/tick033.png") center center no-repeat;
            background-size: 22px;
            border-radius: 10px;
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
          }
        }
      }
      
      .list-itemsb {
        li {
          list-style-type: circle;
        }
      }

// ======== main button design =============    
.main-btn {
    padding: 8px 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ed684300;
    background-color: #ed6843;
    color: #fff;
    margin: auto;
    transition: .25s ease;
    &:hover{
        background-color: #e23a0b;
    }
}
.add-lnder-btn{
    background-color: #00b90f !important;
    &:hover{
        background-color: #029b0e !important;
    }
}
.sml_text{
    font-size: 11px !important;
}

.clr_btn {
    border: 1px solid #02a1e5;
    background-color: #02a1e5;
    transition: .25s ease;
    &:hover{
        background-color: #021458;
        border: 1px solid #021458;
    }
}

.stickybut{
    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    bottom: -15px;
}

svg#freepik_stories-money-income:not(.animated) .animable {
    opacity: 0;
  }
  svg#freepik_stories-money-income.animated
    #freepik--background-complete--inject-4 {
    animation: 2.6s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn,
      3s Infinite linear floating;
    animation-delay: 0s, 2.6s;
  }
  svg#freepik_stories-money-income.animated #freepik--Character--inject-4 {
    animation: 3s Infinite linear floating;
    animation-delay: 0s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes floating {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  

.banner-wrap{
    img{
        max-width: 420px;
    }
}

.check_box_agree {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 0 5px 0 0;
    // padding: 0px 27px 0px;
  
    .form-check-input {
      width: 1.5em;
      height: 1.5em;
      padding: 0;
      margin-top: .25em;
      vertical-align: top;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid rgba(0, 0, 0, 0.25);
      appearance: none;
    }
  
    .form-check-input {
      float: left;
      margin-left: -1.5em;
      animation: mymovecheck 1s infinite;
  
      &:checked {
        background-color: #021458;
        border-color: #021458;
      }
  
      &:focus {
        border-color: #021458;
        outline: 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  
    .blink_red {
      animation: mymovecheckRed 1s infinite !important;
    }
  
    .blink_green {
      animation: mymovecheckGreen 1s infinite !important;
    }
  
  
    label {
      display: inline-block !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      margin-bottom: 10px !important;
    }
  
    @keyframes mymovecheck {
      50% {
        box-shadow: 0px 0px 40px #063783;
      }
    }
  
    @keyframes mymovecheckRed{
      50% {
        box-shadow: 0px 0px 10px 4px  red;
      }
    }
  
    @keyframes mymovecheckGreen{
      50% {
        box-shadow: 0px 0px 10px 4px #12cf1c;
      }
    }
  }


  
.sign-blw-text {
    font-size: 13px;
    .fw-click-here {
    text-align: center;
    border: 2px solid #478aff;
    padding: 6px;
    /* margin-bottom: 13px; */
    display: inline-block;
    width: 100%;
    margin-bottom: 12px !important;
    }
  }



  

.check-tik{
    width: 100%;
    text-align: center;
    display: flex;
    padding-top: 60px;
    justify-content: center;
  }
  
  .circle,
  .circle-border {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
  .circle {
    z-index: 1;
    position: relative;
    background: #00ec27;
    transform: scale(1);
    animation: success-anim 2s ease;
  }
  .circle-border {
    z-index: 0;
    position: absolute;
    background: #00ec27;
    transform: scale(1.1);
    animation: circle-anim 1.5s ease;
  }
  
  .success::before,
  .success::after {
    content: "";
    display: block;
    height: 9px;
    background: #ffffff;
    position: absolute;
  }
  
  .success::before {
    width: 28px;
    top: 56%;
    left: 18%;
    transform: rotateZ(37deg);
  }
  
  .success::after {
    width: 40px;
    top: 47%;
    left: 36%;
    transform: rotateZ(309deg);
  }
  
  @keyframes success-anim {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
  }
  
  @keyframes circle-anim {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1.1);
    }
  }

  .name_detail{
    label{
        font-size: 12px;
        font-weight: 700;
    }
    select {
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        flex: 1;
        height: auto;
        padding: 20px 15px;
        color: #7f7f7f;
        border-radius: 8px;
        cursor: pointer;
        font-size: 13px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        &:focus{
            border-color: #18478b;
            outline: 0;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        @media (max-width: 510px){
            font-size: 13px;
        }
    }//select
    .form-control{
        border-radius: 5px;
        padding: 15px 15px;
        font-size: 13px;
        border-radius: 8px;
        background: #ffff !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        @media (max-width: 768px) {
            padding: 20px 15px;
        }
        &::placeholder{
            color: #666666;	
        }
        &:focus{
            border-color: #18478b;
            outline: 0;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

.enter_postcode_add{
    padding-top: 10px;
    text-align: start;
        .postcode_wrap {
            display: flex;
            width: 100%;
            .form-control{
                border-radius: 0;
                background: #ffff !important;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 15px 15px;
                width: 70%;
                font-size: 13px;
                @media (max-width: 768px) {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    padding: 18px 15px;
                    border-radius: 8px;
                }
                &::placeholder{
                    color: #666666;	
                }
                &:focus{
                    border-color: #18478b;
                    outline: 0;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }//form-control
            }//form-control
            .button {
                width: 30%;
                outline: none;
                border: 0;
                background: none;
                padding: 13px 0;
                font-size: 15px;
                font-weight: 700;
                position: relative;
                display: block;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                @media (max-width: 768px){
                    border-radius: 8px;
                    padding: 18px 0;
                }
            }//button
            .bg_add_click{
                background: #141414;
                color: #ffffff;
                transition: cubic-bezier(0.075, 0.82, 0.165, 1);	
                &:hover {
                    background: #070707;
                }								
            }//bg_add_click
        }//postcode_wrap
    #selcetAdd{
        display: none;
        #addSelect{
            position: relative;
            top: 0;
        }//addSelect
        .form-select{
            border-radius: 5px;
            font-size: 13px;
            padding: 13px 10px;
            width: 100%;
            border-radius: 8px;
            color: block;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            &:focus{
                border-color: black;
                outline: 0;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;;
            }
            & place_dummi{
                color: #666666;
            }						
        }//form-select
        .ADD_detail{
            text-transform: uppercase;
            background: #ffcbd0;
            color: rgb(11, 19, 124);
            // display: none;
            margin: 20px 0;
            text-align: left;
            line-height: 140%;
            .ADD_na_top{
                font-size: 13px;
                font-weight: 700;
                margin-bottom: 5px;
            }//ADD_na_top
            .ADD_clr_typ{
                font-size: 12px;
                margin-bottom: 0;
                font-weight: 700;
            }//ADD_clr_typ
        }//ADD_detail
        .return_back{
            font-size: 10px;
            font-weight: 700;
            p{
                display: flex;
                display: flex;
                justify-content: space-between;
            }
            span{
                text-decoration: underline;
            }
        }///return_back
    }//selcetAdd
}//enter_postcode_add
@media (max-width: 510px) {
    .enter_postcode_add{
        .postcode_wrap {
            display: flex !important;
            flex-wrap: wrap;
            width: 100% !important;
            .form-control{
                border-radius: 8px;
                margin-bottom: 15px;
                width: 100% !important;}//form-control
            .button {
                width: 100% !important;
                border-radius: 5px;
            }//button
        }//postcode_wrap			
}//enter_postcode_add
}


footer {
    img {
        height: 40px;
        @media (max-width: 568px) {
            height: 30px;
        }
    }
    p {
        font-size: 13px;
    }
    ul{
        list-style: none;
        li{
            list-style-type: none;
            a {
                font-size: 12px;
                color: #1b1b1b;
                text-decoration: underline !important;
                color: #0056a0;
            }
        }
    }
}

.error_msg {
    color: #ffffff;
    width: 100%;
    float: left;
    background: #f00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
}

#unsubscribemodal{
    .modal-content{
        overflow: hidden;
    }
    .modal-body{
        padding: 0px 0 20px;
       
        h3{
            background: linear-gradient(to right bottom, #0e43a8, #2879b5, #0c44a1);
            background-size: cover;
            text-align: center;
            font-size: 18px;
            padding: 15px;
            color: #fff;
            img{
                display: block;
                height: 30px;
                margin: 0 auto 15px;
            }
        }
        .modal-btn {
            padding: 15px 20px;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ed684300;
            background-color: #ed6843;
            color: #fff;
            margin: auto;
            transition: .25s ease;
            &:hover{
                background-color: #e23a0b;
            }
        }
        .form-control{
            min-height: 50px;
        }
    }
    input{
        display: block;
    }
}

}



.loading-step{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #F7F7F7;
        opacity: 0.95;
        .showBefore_add{
            font-size: 22px;
            padding: 0 30px;
            display: flex;
            text-align: center;
            margin-top: 128px;
            height: inherit;
            align-items: center;
            justify-content: center;
            color: #00115a !important;
        }
    }
}
.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
        border-radius: 50%;
        border-top: 5px solid #005398;
        border-right: 5px solid transparent;
        animation: animation-loading 0.50s linear infinite;
    }
    img.loader_logo {
        width: 200px;
        position: relative;
        margin-top: -287px;
    }

}

@keyframes animation-loading {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}