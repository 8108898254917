.PersonalDetails {

    .error_msg {
        color: #ffffff;
        width: 100%;
        float: left;
        background: #f00;
        font-size: 13px;
        padding: 0px 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        margin-bottom: 10px;
    }

    .form-header {
        padding: 15px 0;
        border-bottom: solid 1px #BABABA;
        margin-bottom: 35px;

        h4 {
            color: #A0A0A0;

            span {
                color: #000;
            }
        }

        h5 {
            color: #000;
            font-size: 18px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            color: #000;
            margin: 0px;
        }

        .bor-l {
            border-left: solid 1px #BABABA;
            padding: 10px 15px;
        }

        img {
            max-height: 60px;
        }
    }

    .form-caption {
        background-color: #BFEAFF;
        padding: 10px;
        border-radius: 10px;
        -webkit-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
        box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.23);
        margin: 15px;
        margin-top: 25px !important;
        width: 96% !important;

        img {
            height: 65px;
            margin-right: 20px;
        }

        p {
            margin: 0px;
            font-size: 13px;
        }
    }

    .form-control {
        border-radius: 5px;
        padding: 15px 15px;
        font-size: 14px;
        // border: 1px solid #2145DD;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        height: 55px;
    }

    .form-select {
        border-radius: 5px;
        padding: 15px 10px !important;
        font-size: 14px !important;
        // border: 1px solid #2145DD;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        height: 55px;
    }

    .btn-back {
        background: #fff;
        border-radius: 30px;
        color: #1F1F1F;
        border: 1px solid #2145DD;
        font-size: 18px;
        font-weight: 500;
        padding: 15px;
        text-align: center;
        margin: 10px 0 20px;
        width: 100% !important;
        display: inline-block;

        &:hover {
            background: #fbda53;
        }
    }

    .btn-fullwidth {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .btn-lookup {
        background: #F7522A;
        border-radius: 10px;
        color: #fff;
        border: 0px;
        font-size: 16px;
        font-weight: 500;
        padding: 15px;
        display: block;
        text-align: center;
        width: 100%;

        &:hover {
            background: #2145DD;
        }
    }



    .pr-0 {
        padding-right: 0px !important;
    }

    .pr-guard {
        padding-right: 110px !important;
    }

    .privacyg {
        background-image: url("../../img/TCGL_V_A1/pguard.png");
        background-position: right;
        background-repeat: no-repeat;
        background-size: 110px;
    }

    @media screen and (max-width: 768px) {
        .bor-l {
            text-align: left;
        }

        .form-select {
            margin-bottom: 20px;
        }

        .form-control {
            margin-bottom: 20px;
        }

        .form-caption {
            width: 94% !important;
        }

        .btn-banner {
            width: 100%;
            max-width: 100% !important;
        }

    }

    .form-check-input {
        width: 1.2em !important;
        height: 1em;

    }

}