/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========
.TCG_L_D{
$color1: #06285c;
$color2: #1891a5;
$color3: #a4c6fb;
$color4: #1FCCF2;
$color5: #DF4514;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #ed6843;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;



$font_family_1: 'Poppins', sans-serif !important;



$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;

/*================================================
02 - Sass Mixins
==================================================*/

@mixin radius_18 {
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

@mixin shadow_1 {
  -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

@mixin shadow_2 {
  -webkit-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
}

@mixin shadow_in {
  -moz-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

@mixin commButton {
  align-items: center;
  background-clip: padding-box;
  background-color: $color8;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3.8rem;
  padding: calc(1rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:focus {
    background-color: $color8;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  }

  &:hover {
    background-color: $color8;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-2px);
  }

  &:active {
    background-color: $color8;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }

  i {
    margin-left: 10px;
  }
}

@mixin commbg1 {
  background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
  background-repeat: no-repeat;
  background-position: bottom center;

}

.css-26l3qy-menu{
  position: relative;
  z-index: 1;
  color: #3f3f3f;
}

.error_msg {
  color: white;
  margin-bottom: 2px;
  width: 100%;
  padding: 1px 5px;
  background: #ff0000;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
}

/*================================================
03 - BODY STYLES
==================================================*/
.com-button {
  @include commButton;

}

.error_msg{
  background: red;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 5px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
    display: block;
  }

.btn-comm {
  background: $color8;
  padding: 20px 40px;
  border-radius: .4rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: $white-color;
  font-size: 18px;
  position: relative;
  overflow: hidden;

  &.next-btn {
    &::after {
      content: " Next >>";
      background: #f03583;
      transform: translateX(-100%);
      transition: all ease-in 0.3s;
    }
  }

  &.back-btn {
    &::after {
      content: "<< Back";
      background: #000;
      transform: translateX(100%);
      transition: all ease-in 0.3s;
    }
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: 24px;
    padding-top: 8px;
  }

  span {
    top: 0px;
    transition: all ease-in 0.3s;
  }

  &:hover {
    &:hover {
      color: $white-color;
    }

    &::after {
      transform: translateX(0%);
      transition: all ease-in 0.3s;
    }

    span {
      position: relative;
      top: 30px;
      transition: all ease-in 0.3s;
    }
  }
}

$color_1: #fff;
$background-color_1: #663dff;

/* 15 */
.btn-comm2 {
  background: $color8;
  padding: 20px 40px;
  border: none;
  position: relative;
  z-index: 1;
  color: $white-color;
  border-radius: 5px;
  outline-color: none;

  span {
    position: relative;
    z-index: 4;
  }


  // &:after {
  //   position: absolute;
  //   content: "";
  //   width: 0;
  //   height: 100%;
  //   top: 0;
  //   right: 0;
  //   background-color: #ff912b;
  //   transition: all 0.3s ease;
  //   z-index: 3;
  //   color: #ffffff;
  //   border-radius: 5px;
  // }

  &:hover {
    color: $color_1;
    background-color: #ff912b;
    transition: all 0.3s ease;

    &:after {
      left: 0;
      width: 100%;

    }
  }

  &:active {
    top: 2px;
  }
}

.btn-comm3 {
  @extend .btn-comm2;
  background: $color5;

  &:after {
    background: #f35624;
  }
}

.btn-caption {
  font-size: 13px !important;
  margin-top: 10px;
}


.optionBtn {
  position: relative;
  //@extend .btn-comm2 ; 
  border: 2px solid $white-color;
  background: #ffffff14;
  border-radius: 8px;
  
  &::after {
    z-index: -1;
  }

  span {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid $white-color;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked+label {
    background: $color8 ;
    border-radius: 6px;
    font-size: 14px;

    span {
      background: #306fce url("../../img/TCG_L_D/tick.png") center no-repeat;
      background-size: 20px;
    }
  }


  label {
    cursor: pointer;

  }

  &.otheroption {
    background: transparent;
    border: 2px solid #a2d0f9;
  }

  &.blackoption {
    border-color: $color5;

    span {

      border-color: $color5;
      position: relative;
      top: 4px;

    }

    &:checked+label {
      color: $white-color;

    }

    input:checked+label {
      color: $white-color !important;

      span {
        background: url(../../img/TCG_L_D/tick02.png) no-repeat;
        background-size: 21px;
      }
    }
  }
}





.selectoptions {
  .optionBtn {
    width: 100%;
    margin-bottom: 10px;
  }
  margin-bottom: 10px;
}

.banklist {
  li {
    width: 49%;

    .optionBtn {

      &.blackoption {
        height: 60px;

        label {
          font-size: 13px;
        }
      }
    }
  }
}



html {
  scroll-behavior: smooth;
  font-size: 1rem;
}


body {
  font-family: $font_family_1;
  color: $black-color;
  @include commbg1;

}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;

}

/*================================================
04 - HEADER 
==================================================*/
fieldset.ques1 {
  border: 2px solid $color11;
  border-radius: 10px;
  padding: 10px 20px 10px;
  // background: $white-color;


  legend {
    float: none;
    display: inline-block;
    background: transparent;
    width: initial;
    padding: 0 10px;
    text-align: left;
    margin-bottom: 0;
    font-size: 1.2rem;
  }



}

@mixin clearfix() {

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}


.testimonial-wrap {
  @include clearfix();
  margin: 10px auto;
}

.testimonial {
  border-radius: 10px;
  box-shadow: 0 4px 16px 0px #00000052;
  background-color: white;
  border-top: 5px solid #0100a0;
  position: relative;
  color: #666;
  padding: 20px;
  margin-bottom: 18px;

  .date {
    font-size: 0.875rem;
    color: #6c757d;

    .stars {
      display: inline-block;
      float: right;

      .star-rating {
        width: 100px;
      }
    }
  }

  &:before,
  &:after {
    position: absolute;
    visibility: visible;
    width: 0;
    height: 0;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
  }

  &:before {
    border-width: 17px;
    border-top-color: rgba(1, 1, 1, 0.15);
    bottom: -17px;
    left: 14px;
  }

  &:after {
    border-width: 15px;
    border-top-color: #fff;
    bottom: -13px;
    left: 16px;
  }

  strong {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #000000;
  }
}

.testimonial-author {
  display: flex;
  line-height: 1.2;
  align-items: center;

  .name {
    color: #fff;
  }

  strong {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: white;
  }

  .name,
  .stars {
    display: block;
    // float: left;
  }

}



@media screen and (max-width: 579px) {
  .btn-comm4 {
    padding: 15px 10px !important;
  }
}




.top-strip {
  background:  #00b90f  ;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 6;
  font-size: 15px;
}



.review-card {
  max-width: 100%;
  // margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #000;

  .star-rating {
    width: 100px;
  }
}

.review-card .star {
  color: #ffc107;
  /* Bootstrap's warning color for stars */
}

.review-card .date {
  font-size: 0.875rem;
  color: #6c757d;
}


.banner {
  background: linear-gradient(to right bottom, #0e43a8, #2879b5, #0d182c);
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  padding-top: 25px;

  .caption-top {
    background-color: #01b636;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    -webkit-box-shadow: 1px 9px 7px -8px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 1px 9px 7px -8px rgba(0, 0, 0, 0.30);
    box-shadow: 1px 9px 7px -8px rgba(0, 0, 0, 0.30);

    p {
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 4px 0;
      margin-bottom: 0px;
    }
  }

  .review {
    position: absolute;
    z-index: 99;
    background: $white-color;
    border-radius: 5px;
    right: 0%;
    bottom: -18rem;
    top: auto;
    padding: 10px;

    img {
      border-radius: 10px;
      height: 25px;
    }
  }

  .container {
    margin-bottom: auto;
  }

  .banner-inner {
    // height: calc(100vh - 11vh);
    padding-top: 40px;
    display: flex;
    align-items: center;

    .bannerimage-wraper {
      position: relative;
      width: 270px;


    }

    .bannerimg {
      position: absolute;
      top: 0px;
      max-height: 500px;
      height: 400px;
      transform: translate(18px, 0);
    }

    .banner-caption {
      color: $white-color;

      .hilighttext-homepage {
        background-color: #01467157;
        padding: 6px;
        padding-bottom: 6px !important;
        margin-bottom: 16px;
        display: inline-block;

        p {
          margin-bottom: 0px;
          text-align: center;
          letter-spacing: .5px;
        }
      }

      .legent-car {
        border: 1px solid $color_1;
        border-radius: 10px;

        legend {
          float: none;
          width: auto;
          padding: 0 10px;
          font-size: 1rem;

        }

      }

      .subtext {
        max-width: 80%;
      }

      .payout {
        font-size: 1.5rem;

        .amount {
          position: relative;
          color: yellow;

          svg {
            position: absolute;
            top: -53px;
            left: -8px;
          }
        }
      }



    }

    .btn-comm2 {
      font-size: 1rem;

      &:hover {
        i {
          transform: translate(5px, 0);

          transition: ease-in 0.2s;
        }
      }

      i {
        transform: translate(0px, 0);
        display: inline-block;
        transition: ease-in 0.2s;

      }

    }


    .payout {
      span {
        color: yellow;
      }
    }
  }
}



.welcome-highlights {
  background: linear-gradient(to right bottom, #2b77af, #061a2a, #0d2342);
  color: $white-color;

  h2 {
    font-size: 1.2rem;
    line-height: 27px;
    max-width: 88%;
    margin: 16px auto;
  }

  p {
    font-size: .8rem;
  }

}

.faqsection {
  position: relative;

  &::before {
    content: "";
    background: url(../../img/TCG_L_D/curve-bg.png) no-repeat;
    width: 100%;
    height: 107px;
    background-position: top center;
    position: absolute;
    top: -103px;
    left: 0;
    background-size: 100%;
  }
}


.form-body {
  overflow: auto;
  scroll-behavior: smooth;
  height: 100vh;
}

.form-style {

  .title {
    font-size: 1.2rem;
  }

  input,
  select {
    border-color: #95ccff;
    font-size: .9rem;
    height: 42px;
  }

  select {
    height: 42px;
  }

  .car-number {
    // background: white url("../img/number-plate.png") no-repeat;
    background-size: 40px;
    padding-left: 25px;
    border: 2px solid $black-color;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 2rem;
    line-height: 0;
    text-align: center;
    height: 60px;

    &::placeholder {
      font-size: 1rem;
      font-weight: initial;
      line-height: 0;
      position: relative;
      top: -5px;
    }
  }


  fieldset {
    border: 1px solid #95ccff;
    border-radius: 5px;

    legend {
      float: none;
      width: auto;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  .sign-sec {
    text-align: center;
    h5 {
      font-size: 13px;
    }

    .clearbutton {
      background: #eee;
      border: none;
      outline: 0;
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 5px;
    }


    .signbox {
      text-align: center;
      .border-div{
        background: #02a1e5;
        border-radius: 7px;
        padding: 10px 0;
        min-width: 320px;
        width: 60%;
        margin: 0 auto 20px;
        display: grid;
        justify-content: center;
    }
      h5{
        position: relative;
        top: 15px;
        background-color: #00b90f;
        color: #fff;
        padding: 10px 25px;
        margin: 0px;
        border-radius: 7px;
        // border-bottom-left-radius: 0px;
        // border-bottom-right-radius: 0px;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        // margin-top: 20px;
        }
        .icodown {
            animation: MoveUpDown 1s linear infinite;
            position: relative;
            left: 0;
            bottom: 0;
          }
          
          @keyframes MoveUpDown {
            0%, 100% {
              bottom: -10px;
            }
            50% {
              bottom: 5px;
            }
          }
      
      @keyframes MoveUpDown {
        0%, 100% {
          bottom: -10px;
        }
        50% {
          bottom: 5px;
        }
				}
      canvas {

        // width: 100%;
        // background: #e4e4e4;
        // height: 200px;
        width: 250px;
		  	height: 160px;
        border: 2px dashed #063783;
        // border: 1px solid #d5d5d5;
        // border-bottom: 2px dotted $black-color;
        background: #eef5ff url(../../img/TCG_L_D/signhere.png) no-repeat center center;
      }
    }

    .chicon {
      font-size: 18px;
      width: 30px;
      height: 30px;
      background: #b4d6f5;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
    }

  }

  .sign-check {
    .checkinput {
      width: 20px;
      height: 20px;
      position: relative;
      top: 6px;
    }

    font-size: 13px;

  }

  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      a {
        background: #cdeeff;
        display: block;
        border-radius: 20%;
        padding: 5px 10px;
        border-radius: 35px;
        font-size: 12px;
        text-decoration: underline !important;
      }
    }
  }

}

.bg-pophead {
  background: $color1;
}

.signpopip {
  font-size: 13px;
  min-height: 300px;

  .signsec {

    .signbox {

      canvas {

        width: 100%;
        border: 1px solid #cfcfcf;
        min-height: 350px;
        box-sizing: border-box;
        border-radius: 5px;

        &.signimg {
          background: #fff url(../../img/TCG_L_D/signature.jpg) center no-repeat;
        }
      }
    }

    .clearbutton {
      padding: 10px;
      background: #d5d5d5;
      border: none;
      padding: 12px 30px;
      border-radius: 5px;
      color: #161616;
    }
  }

  .checkinput {
    width: 20px;
    height: 20px;
  }
}

.lenderslide {
  max-width: 150px;
  margin: 0 auto;
  filter: blur(3px);
  border: 1px solid #9f9f9f;
  border-radius: 50%;
  overflow: hidden;
}

.card-wrap {
  position: relative;

  input {
    width: 0;
    position: absolute;

    &:checked+label {
      background: $color1;
      color: $white-color;
      border-color: $color1 ;
    }
  }

  .card-item {
    border: 1px solid $color13;
    font-size: .7rem;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    line-height: 16px;
    color: $color13;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    i {
      font-size: 2rem;
    }


  }
}

@media screen and (max-width: 991px) {
  .banner .banner-car .car-img {
    height: 170px;
  }
}




@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.btn-eff {
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  small {
    &:nth-child(1) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, #e4e4e418, $color10);
      animation: animate1 2s linear infinite;
    }

    // &:nth-child(2) {
    // 	position: absolute;
    // 	top: 0;
    // 	right: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to bottom, #e4e4e418,  $color10);
    // 	animation: animate2 2s linear infinite;
    // 	animation-delay: 1s;
    // }
    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to left, #e4e4e418, $color10);
      animation: animate3 2s linear infinite;
    }

    // &:nth-child(4) {
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to top, #e4e4e418,  $color10);
    // 	animation: animate4 2s linear infinite;
    // 	animation-delay: 1s;
    // }
  }
}



/* animation class and keyframes */
.overflow-hidden {
  overflow: hidden;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
}

.drop-in-3 {
  animation: drop-in 2000ms ease 100ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


.connect-account {
  padding-bottom: 30px;

  .btn-comm2 {
    max-width: 350px;
    display: block;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    span {
      font-size: 1.2rem;
    }
  }

  .title {
    font-size: 1.5rem;
  }


}

.bglock {
  background: url("../../img/TCG_L_D/bg-lock.png") no-repeat center bottom;
}


.btfooter {
  padding-top: 2rem;
  background: #C1E8FF url("../../img/TCG_L_D/border-img.png") repeat-x top;
  position: relative;

  p {
    font-size: 14px;
    color: #013959;
  }

  &::before {
    content: "";
    width: 58px;
    height: 58px;
    background: url("../../img/TCG_L_D/secure-img.png");
    display: inline-block;
    position: absolute;
    top: -26px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}





.form-section {
  .form-control {
    height: 50px;
    border-color: $color11;
  }

  .title {
    font-size: 1.2rem;

  }

  fieldset {
    border: 1px solid $color11;
    border-radius: 10px;
    background: $white-color;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .btn-confrm {
    max-width: initial;
  }

  .poscode {
    .btn-postcode {
      background: $color3;
      color: $white-color;
      max-width: 250px;
    }
  }
}

.id-uploads {
  .body-id {
    border: 2px solid #cacaca;
  }

  .btn {
    font-size: 1rem;
  }

  .accordion-item {
    .accordion-button {
      background: #35C6F4;
      border-radius: 5px !important;
      color: $white-color;
      font-weight: 600;
      font-size: 1.2rem;

      &.btn-licence {
        background: $color9;
      }

      &.btnpassport {
        background: $color12;
      }
    }

    .btn-uploads {
      .btn {
        font-size: 1.1rem;

        i {
          font-size: 1.4rem;
        }
      }
    }
  }

  .brows {
    position: relative;

    input[type="file"] {
      position: absolute;
      opacity: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

}


.page-loader {
  padding-top: 10rem;
}

.kycupload {
  .text-start {
    font-size: 1.2rem;
    line-height: 20px;
  }

  .id-uploads {

    .accordion {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

  }

  .btn-comm2 {
    display: inline-block;
  }
}


.back-btn {
  font-size: 1.2rem;
}




.sidesection {
  max-width: 650px;
  height: 100%;
  background: $white-color;
  position: fixed;
  right: 0px;
  bottom: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  transform: translateX(650px);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
}

.is-open {
  transition: all ease-in 0.4s ;
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  background: #c9e5ff;
}

.credimodal {
  max-width: 400px !important;
  margin: 0 auto !important;
}


.nextbtn2 {

  @extend .btn-comm2;

  &::after {
    display: none;
  }

  font-size: 1.3rem !important;

}

.side-box {
  .optionBtn {
    border-color: #6eb9ff;
    background: $white-color;

    label {
      font-size: 13px;
    }

    input:checked+label {
      background: #73b0e9;

    }

    span {
      border-color: #659fd5;
      display: block;

    }
  }
}

.sticky {
  position: sticky;
  bottom: 0;

}

.list-itemsc {
  >li {
    position: relative;
    padding-left: 38px;
    margin-bottom: 10px;

    &::before {
      width: 26px;
      height: 26px;
      content: "";
      background: url("../../img/TCG_L_D/tick033.png") center center no-repeat;
      background-size: 22px;
      border-radius: 10px;
      display: block;
      position: absolute;
      left: 0;
      top: 0px;
    }
  }
}

.list-itemsb {
  li {
    list-style-type: circle;
  }
}



/*================================================
 HEADER END
==================================================*/

/*================================================
04 - BANNER
==================================================*/




.arrows path {
  stroke: #2994D1;
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}


.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0.5);
  }

  50% {
    box-shadow: 0 0 0 20px rgba(41, 148, 209, 0.2);
  }

  100% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0);
  }
}

.shadow2 {
  box-shadow: 0px 0px 1px 1px #79b7e1;
}

.pulse2 {
  animation: pulse-animation2 1.5s infinite;
}

@keyframes pulse-animation2 {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.forms-fieldset {
  background: rgb(247, 247, 255);

  .headline {
    font-size: 18px;
    font-weight: 700;
  }
}

.list-style {
  li {
    list-style-type: disc !important;
  }
}

.notesec {
  font-size: 13px;
}

ol li {
  list-style-type: decimal;
}

.bg-gray {
  background-color: #F7F7FF;
}

.anchor {
  scroll-margin-top: 100px;
}

.top-head {

  h2,
  .h2 {
    font-size: 2.2rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}



.agree-scroll {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
  padding: 20px;
  background-color: #fff;
}


.stickyBtn {
  position: fixed;
  width: 100%;
  z-index: 238;
  bottom: 0;
  background: #000;
  padding: 20px 0;
}

/*================================================
footer start
==================================================*/

._29ahV{ 
  padding: 70px 0 10px;
}
footer {
  background: $color1;
  color: $color_1;
  font-size: .8rem;

  ul {
    li {
      display: inline-block;

      a {
        padding: 0 15px 0 0;
      }
    }
  }

  p,
  a {
    color: $color3;
  }
}


/*================================================
footer end
==================================================*/



.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.quastion-area {
  margin-top: 80px;
  margin-bottom: 80px;

  .accordion-item {
    border: 1px solid #000;

    &:not(:first-of-type) {
      border: 1px solid #000;
    }
  }

  .accordion-button {
    font-size: 20px;
    color: $black-color;
    text-align: center;

    &::after {
      background: url(../../img/TCG_L_D/down-arrow.png) no-repeat center;
      width: 26px;
      background-size: 15px;
    }

    &:not(.collapsed) {
      color: $white-color;
      background: $color1;

      &::after {
        background: url(../../img/TCG_L_D/down-arrow.png) no-repeat center;
        width: 26px;
        background-size: 15px;
      }
    }
  }

  .accordion-body {
    p {
      font-size: 18px;
    }
  }
}

.review-section {
  background: #ebf0ff;
  padding: 80px 0 40px 0;

  .swiper-wrapper {
    padding: 10px 30px 30px 0px;
  }

  .feefo-review {
    background: $color1;
    border-radius: 10px;
    padding: 20px;
  }

  .review-box {
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: $color1;
    border-radius: 10px;
    height: 180px;
    transition: all ease-in 0.2s;

    &:hover {
      border-bottom: 5px solid $color1;
      transform: rotate(-1deg);
      transition: all ease-in 0.2s;
    }

    h3 {
      font-size: 16px;
    }

    .starimg {
      height: 25px;
    }
  }
}

.overlay {
  .loader-logo {
    position: absolute;
    top: 3rem;
    width: 300px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;


  }

  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .loader-box {
    .loader {
      -webkit-animation: rotate-center 0.8s linear infinite both;
      animation: rotate-center 0.8s linear infinite both;
      transform-origin: center;
      width: 80px;
      height: 80px;
    }
  }
}

.creditpopup {

  .form-control,
  .form-select {
    border-color: $color8;
  }

  .optionBtn {
    label {
      font-size: 18px;
    }
  }

  .side-box {
    .optionBtn {
      label {
        font-size: 15px;
      }
    }
  }

  .btn-comm2 {
    font-size: 18px;
  }
}

.loadersign {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}


.check_box_agree {
  font-size: 15px;
  color: #000;
  font-weight: 400;
  margin: 0 5px 0 0;
  // padding: 0px 27px 0px;

  .form-check-input {
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
  }

  .form-check-input {
    float: left;
    margin-left: -1.5em;
    animation: mymovecheck 1s infinite;

    &:checked {
      background-color: #021458;
      border-color: #021458;
    }

    &:focus {
      border-color: #021458;
      outline: 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  .blink_red {
    animation: mymovecheckRed 1s infinite !important;
  }

  .blink_green {
    animation: mymovecheckGreen 1s infinite !important;
  }


  label {
    display: inline-block !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
  }

  @keyframes mymovecheck {
    50% {
      box-shadow: 0px 0px 40px #063783;
    }
  }

  @keyframes mymovecheckRed{
    50% {
      box-shadow: 0px 0px 10px 4px  red;
    }
  }

  @keyframes mymovecheckGreen{
    50% {
      box-shadow: 0px 0px 10px 4px #12cf1c;
    }
  }
}

//check_box_agree


// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 120px;
$howManySteps: 100; //this needs to be even.



// End Progress

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-vertical-fwd {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-in {
  -webkit-animation: fade-in 2s ease-in both;
  animation: fade-in 2s ease-in both;
}

.shake-vertical {
  -webkit-animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

/*================================================
Keyframe
==================================================*/

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

/*================================================
07 - MEDIA QUERY 
==================================================*/
@media screen and (min-width: 1200px) {
  .form-style {
    .select1 {
      padding-right: 1rem;
      font-size: .8rem;
      padding-left: 0.5rem;
    }
  }

}

@media screen and (max-width: 1200px) {
  .welcome-highlights {
    h2 {
      font-size: 1.2rem;
      max-width: initial;
    }
  }
}

@media screen and (max-width: 991px) {

  .brand {
    text-align: center;

    .logo {
      img {
        height: 32px;
      }
    }
  }

  .banner {
    .banner-inner {
      .bannerimg {
        position: initial;
        height: initial;
        transform: translate(0);
        max-width: 400px;

      }

      .banner-caption {
        .subtext {
          max-width: 100%;
        }
      }
    }
  }


}

@media screen and (max-width: 760px) {

  .banner {
    .banner-inner {
      padding-top: 0;
      height: initial;
      margin-top: 2.2rem;

      .bannerimg {

        max-width: 380px;

      }

      .banner-caption {
        h1 {
          font-size: 2rem;
        }

        .subtext {
          font-size: 17px;
        }

        img {
          // margin-top: 15px;
        }
      }

    }
  }

  .faqsection {
    &::before {
      background-size: cover;
    }

    .title-sec {
      h2 {
        font-size: 1.2rem;
      }
    }
  }

  .form-style {
    .sign-sec {
      .signbox {
        canvas {
          max-width: 100%;
        }
      }
    }
  }

  .card-wrap {
    .card-item {
      font-size: 1rem;
      font-weight: 500;
    }
  }

}


@media screen and (min-device-width: 470px) and (max-device-width: 768px) {}





@media screen and (max-width: 470px) {

  html {
    font-size: .9rem;
  }

  .logo-two {
    img {
      height: 50px;
    }
  }

  .banner {
    min-height: 100vh;
    padding-top: 10px;
  }

  .ft-logo {
    img {
      height: 35px;
    }
  }

  .form-body {
    height: 98vh;
  }

  .welcome-highlights {
    .items {
      max-width: 75%;
      margin: 0 auto;
    }
  }

  .widmob {
    width: 90% !important;
  }

  .optionBtn {
    input:checked+label {
      span {
        background-size: 16px;
      }
    }

    span {
      width: 20px;
      height: 20px;

    }

    &.blackoption {
      input:checked+label {
        span {
          background-size: 16px;
        }
      }
    }
  }

  .banklist {
    li {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .top-strip {
    font-size: 13px;
  }

  
}
.display-block{
  display: block;
}

 .loader-set{
   padding-top: 20%;
 }
@media only screen and (max-width: 991px) and (min-width: 790px) {
  .loader-set{
    padding-top: 30%;
  }
}

@media only screen and (max-width: 790px) and (min-width: 540px) {
  .loader-set{
    padding-top: 40%;
  }
}

@media only screen and (max-width: 540px) {
  .loader-set{
    padding-top: 50%;
  }
}


$background_color_1:#ffffff;
$background_color_2:#00b0ef;
$background_color_3:#f0f2f9;


$color_1:#ffffff;
$color_2:#00b0ef;
$color_3: #000;




    .multylenderwrapper{

    .accordion-body{
        @media screen and (max-width: 768px) {
            padding: 15px;
        }
    }
    .accordion-item{
      background: none;
        margin-bottom:20px;
        border-radius:7px !important;
        border:none;
        .form-check{
          border: solid 2px #a2d0f9;
            padding:8px 0px 10px 35px;
            width: 100%;
            border-radius: 7px;
            cursor: pointer;
            margin-left: 0px;
            label{
                width: 96%;
                margin-top: 5px;
                margin-left: 7px;
                cursor: pointer;
                color:#fff;
                font-weight: normal;
            }
        }
        .form-check-input{
            border: 2px solid #00b0ef;
            width:25px;
            height: 25px;
            border-radius: 25px;
            padding: 10px;
            &:focus{
                box-shadow: none;
            }
        }
        .form-check-input:checked{
            border: 2px solid #00b0ef;
            background-color: $background_color_2;
            border-radius:25px;
        }
        
      }
      
      .accordion-button[aria-expanded="true"]{
        background-color:none;
        color:#404040;
      /*   box-shadow:0px 1px 2px 2px #fffff7; */
      }
      .accordion-button{
        border: solid 2px #a2d0f9;
        border-radius: 7px !important;
        outline: none !important;
        background: none;
        box-shadow: none !important;
        label {
            width: 100%;
            cursor: pointer;
            margin-left: 10px;
            color: #fff;
            margin-top: 5px;
        }
        &:focus{
            box-shadow: none;
        }
      }
      .accordion-body{
        border: dashed 2px #a2d0f9;
        border-top: 0px;
        border-radius: 7px;
      }
      .accordion-button:after{
        color:#404040;
      }











}














    @media screen and (max-width: 768px) {

   
            // padding: 10px 0 40px;
            .multylenderwrapper{
                margin-top: 20px;
                h2{
                    font-size:24px;
                    margin: 15px 0 0;
                }
                h3{
                    margin-top: 15px;
                }
            }
       

    }
    #lenderdropdown {
      background: #2472b3;
      border: 2px solid #a2d0f9;
      width: 100%;
      margin: 50px auto 0 auto;
      color: #fff;
      border-radius: 7px;
      overflow: hidden;
      h3 {
          background: #195bae;
          margin: 0px;
          a {
              padding: 0 10px;
              font-size: 16px;
              line-height:50px;
              display: block;
              color: #fff;
              text-decoration: none;
          }
          &:hover {
              background: #ed6843;
                          border-left: 7px solid #fea68d;
          }
      }
      li {
          list-style-type: none;
      }
      ul {
          margin: 0px;
          padding: 0px;
          ul {
              li {
                  a {
                      font-size: 16px;
                      line-height: 50px;
                      display: block;
                      padding: 0 15px;
                      transition: all 0.15s;
                      position: relative;
                      border-top: dotted 1px #4798db;
                      &:hover {
                          background: #ed6843;
                          border-left: 7px solid #fea68d;
                      }
                  }
              }
              display: none;
              ul {
                  margin: 15px;
                  background-color: #0e67b1;
              }
          }
      }
      li.active {
          >ul {
              display: block;
          }
      }
      a {
          &:not(:only-child) {
              &:after {
                  content: "";
                //  background-image:url('../../img/TCG_L_D/down.png');
                  background-repeat: no-repeat;
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  right: 10px;
                  top: 15px;
              }
          }
      }
      .active {
          >a {
              &:not(:only-child) {
                  &:after {
                      content: "";
                      transform: rotate(180deg);
                  }
              }
          }
      }
      i {
          float: right;
          height: 16px;
          width: 16px;
      }
  }

  .animate{
    animation: animation-blue-flash 1.25s linear infinite normal;
  }


  .sublender-div{
    background: #2569a3;
    width: 100%;
    padding: 10px;
    border: solid 2px #a2d0f9;
    border-radius: 7px;
    position: relative;
    margin-top: -15px;
    border-top: 0;
    padding-top: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 15px;
    p{
      font-size: 14px;
      margin-bottom: 4px;
      border-bottom: dashed 1px #5b9fd9;
      padding-bottom: 4px;
    }
    .b-0{
      border: 0px;
    }
  }
  .sublender-pop{
    background: #edae9d;
    margin-top: -22px;
    border: solid 2px #df4514;
    border-top: 0px;
  }
  
  .otherlend{
    background: #2569a3 !important;
    font-size: 14px;
  }
  
  .selected-defendant{
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  .group-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 12px;
    padding-right: 15px;
    position: relative;
    float: right;
    // margin-top: -30px;
    // right: 10px;
    font-weight: 500;
    cursor: pointer;
    z-index: 99;
  &::after{
    background: url("../../img/TCG_L_D/down-arrow-l.png") no-repeat;
    background-size: contain;
    content: "";
    height: 17px;
    position: absolute;
    right: -2px;
    top: 2px;
    transition: all .15s ease;
    width: 17px;
    cursor: pointer;
  
  }
  }
  .group-button-pop{

  }

  .group-active{
  .group-button{
&:active{
  &::after {
    top: 2px;
    transform: rotate(180deg) !important;
  }
}


  }

  }
  }
  .plender{
    width: 90%;
    margin-bottom: 0px;
  }

  .font-11{
    font-size: 11px;
  }
  .pop-p{
    margin-bottom:0px;
    width: 80%;
  }


}