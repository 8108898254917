.duplicate{


/*
*******************************************
Template Name: CAR REGISTRATION
Author: SHAREEF
Date: 12-06-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$color1: #1F1F1F;
$color2: #bfbfbf;
$color3: #D1D1D1;
$color4: #DF4514;

/**/
//////////////////////////Fonts*/////////

$font_family_1: "Poppins", sans-serif !important;
$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

$shdow1 : rgba(0, 0, 0, 0.24) 0px 3px 8px;



/*================================================
02 - Sass Mixins
==================================================*/

@mixin commbg1 {
  background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
  background-repeat: no-repeat;
  background-position: bottom center;

}

header{
  padding: 15px 0;
  margin-bottom: 20px;
  img {
    height: 40px;
    border-radius: 5px;
}
}
.thankyou-div{
  position: relative;
  display: flex;
  text-align: center;
  flex-wrap: nowrap;
  flex-direction: column;
  img{
    margin: 0 auto;
  }
  // justify-content: center;
  h1{
    margin: 20px 0;
    color: #00115a !important;
    font-weight: 600;
  }
  h2{
    margin-bottom: 20px;
  }
}
.loading-step.fade-out {
    opacity: 0;
  }

.loading-step {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  transition: opacity 0.5s ease-out;
 .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #F7F7F7;
    opacity: 0.95;
    .showBefore_add {
      font-size: 22px;
      padding: 0 30px;
      display: flex;
      text-align: center;
      margin-top: 128px;
      height: inherit;
      align-items: center;
      justify-content: center;
      color: #00115a !important
    }
  }
  

  
}



.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      margin-top: -50px;
      margin-left: -50px;
      border-radius: 50%;
      border-top: 5px solid #005398;
      border-right: 5px solid transparent;
      animation: animation-loading 0.50s linear infinite
    
  }
  img.loader_logo {
    width: 200px;
    position: relative;
    margin-top: -287px
  }
}

@keyframes animation-loading {
  0% {
      transform: rotate(0deg)
  }

  100% {
      transform: rotate(360deg)
  }
}
}