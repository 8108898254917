.tankyouV1{

  @import url('./animate.min.css');
$background_color_1:#ffffff;
$background_color_2:#063783;
$background_color_3:#f0f2f9;


$color_1:#ffffff;
$color_2:#063783;
$color_3: #000;


.thankyouwrapper{
    background: #e8e8e8 url(../../img/signature_bg.png) no-repeat center left;
    padding: 70px 0 70px;
	background-size: cover;
    background-attachment: fixed;

    .thankyou_div{
        padding: 15px 15px 30px;
        background: #fff;
        border-radius: 7px;
        border-top:5px solid $color_2;
        border-bottom:5px solid $color_2;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    h2{
        font-size: 30px;
        font-weight: 600;
        color: $color_2;
        margin: 20px 0 0;
        @media screen and (max-width: 768px) {
            font-size: 25px; 
        }
    }

    .cs_hero_title {
        color: transparent;
        font-size: 48px;
        font-weight: 800;
        line-height: 1.1em;
        margin-bottom: 20px;
        background-image: linear-gradient(
          91.1deg,
          #063783,
          #ec6842,
          #ec6842,
          #063783
        );
        background-size: 200% 200%;
        background-clip: text;
        -webkit-background-clip: text;
        animation: move 5s linear infinite;
      //   max-width: 900px;
        @media (max-width: 1700px) {
          font-size: 45px;
        }
        @media (max-width: 1199px) {
          font-size: 36px;
        }
        @media (max-width: 1199px) {
          margin-bottom: 20px;
        }
        @media (max-width: 568px) {
          font-size: 26px !important;
          text-align: center;
        }
      }
  
      @-webkit-keyframes move {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
        @-moz-keyframes move {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
        @keyframes move {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
  
    h3{
        font-size: 18px;
        line-height: 1.5;
        font-weight: 600;
        color: #063783;
        margin-bottom: 15px;
        span{
            background: #01b636;
            color: #fff;
            padding: 5px;
            font-size: 17px;
            line-height: 35px;
        
        }
    }
    p{
        font-size: 16px;
        a{
            color: $color_2 !important;
            text-decoration: underline;
            &:hover{
                color:#01b636 !important;
            }
        }
    }

    .check-container {
        width: 9.25rem;
        height: 10.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 568px) {
            width: 7.25rem;
            height: 8.5rem;
        }
      
        .check-background {
          width: 100%;
          height: calc(100% - 1.25rem);
          background: linear-gradient(to bottom right, #063783, #0257dc);
          box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
          transform: scale(0.84);
          border-radius: 50%;
          animation: animateContainer 0.75s ease-out forwards 0.75s;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
      
          svg {
            width: 55%;
            transform: translateY(0.25rem);
            stroke-dasharray: 80;
            stroke-dashoffset: 80;
            animation: animateCheck 0.35s forwards 1.25s ease-out;
          }
        }
      
        .check-shadow {
          bottom: calc(-15% - 5px);
          left: 0;
          border-radius: 50%;
          background: radial-gradient(closest-side, #6849da, transparent);
          animation: animateShadow 0.75s ease-out forwards 0.75s;
        }
      }
      
      @keyframes animateContainer {
        0% {
          opacity: 0;
          transform: scale(0);
          box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }
        25% {
          opacity: 1;
          transform: scale(0.9);
          box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }
        43.75% {
          transform: scale(1.15);
          box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        }
        62.5% {
          transform: scale(1);
          box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
        }
        81.25% {
          box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }
        100% {
          opacity: 1;
          box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
        }
      }
      
      @keyframes animateCheck {
        from {
          stroke-dashoffset: 80;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
      
      @keyframes animateShadow {
        0% {
          opacity: 0;
          width: 100%;
          height: 15%;
        }
        25% {
          opacity: 0.25;
        }
        43.75% {
          width: 40%;
          height: 7%;
          opacity: 0.35;
        }
        100% {
          width: 85%;
          height: 15%;
          opacity: 0.25;
        }
      }
    



}



// animated fixed but
@media screen and (max-width: 768px) {


    @keyframes mymove {
      50% {box-shadow: 0px 0px 50px #063783;}
    }
    }
    // animated fixed but end




}




    @media screen and (max-width: 768px) {

        .thankyouwrapper{
            padding: 40px 20px 40px;
 
        }

    }


  }
