.id_uploadV4{/*
*******************************************
Template Name: CAR REGISTRATION
Author: SHAREEF
Date: 12-06-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$color1: #1F1F1F;
$color2: #bfbfbf;
$color3: #D1D1D1;
$color4: #DF4514;
$color5: #E23232;
$color6: #E23232;
$black-color: #000;
$white-color: #fff;

/**/
//////////////////////////Fonts*/////////

$font_family_1: "Poppins", sans-serif !important;
$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

$shdow1 : rgba(0, 0, 0, 0.24) 0px 3px 8px;



/*================================================
02 - Sass Mixins
==================================================*/
@mixin commButton {
  align-items: center;
  background-clip: padding-box;
  background-color: #ed6843;
  border: 1px solid transparent;
  border-radius: .8rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3.8rem;
  padding: calc(1rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:focus {
    background-color: #ed6843;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  }

  &:hover {
    background-color: #ed6843;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #ed6843;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }

  i {
    margin-left: 10px;
  }
}

@mixin commbg1 {
  background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
  background-repeat: no-repeat;
  background-position: bottom center;

}



/**/
///////////////////////////////////////////////////////////////////////////
.com-button {
  @include commButton;

  &.skipbtn {
    // background: $white-color;
    color: $color1;
    border: 1px solid $color1;
    font-size: 1rem;
  }

}



.upload-box {
  cursor: pointer;

  // @extend .car-box;

  .icon-circle {
    background: $color2;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.4rem;
    }
  }

  
    &.updated {
      &.nation-card {
        .icon-circle {
          background: $color4;
          color: $white-color;
        }
      }
      &.license{
        .icon-circle {
          background: $color4;
          color: $white-color;
        }
      }
      &.passport{
        .icon-circle {
          background: $color4;
          color: $white-color;
        }
      }
      
    }
     

  h3 {
    font-size: 1.3rem;
  }

  .click-upload {
    background: $color1;
    color: $white-color;
    border-radius: 30px;
    text-align: center;
    font-size: 1rem;
    display: inline-block;
    border: none;
  }

  .fileupload {
    @extend .click-upload;
    background: #b0f3b7;
    color: #0C9D1B;
  }

  .forward-angle {
    margin-left: auto;
    font-size: 1.4rem;
    margin-right: 15px;
  }
}

.file-uploads-box {
  background: #e5e5e5;
  border-radius: 15px;
  border: 1px dashed $color1;
  position: relative;

  .iput-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
    .cursor-pointer {
      cursor: pointer;
    }

  i {
    font-size: 4rem;
    color: #a7a7a7;
  }

  .choose-file {
    background: $color1;
    border-radius: 10px;
    color: $white-color;
    font-size: 1.2rem;
  }

  .file-text {
    font-size: 1rem;
  }
}

.file-upload-body {
  .close-btn {
    margin-left: auto;


    &:hover {

      i {
        transform: rotate(180deg);
        transition: ease-in 0.3s;
        transform-origin: center;
      }
    }
  }

  .com-button {
    font-size: 1.1rem;
    min-height: inherit;

    &.takephoto {
      padding: 13px;
    }

    i {
      font-size: 1.2rem;
    }
  }

  .progress {
    width: 100%;
  
    .progress-bar {
      animation: profill 2s ease-in 100ms  both  ;
    }
    @keyframes profill {
      0% {
        width: 0%;
      }
    
      50% {
        width: 100%;
      }
    }
  }

}


.fade-scale {
  transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;

  &.show {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
  }

}

 }