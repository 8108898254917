@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$background_color_1: #ffffff;
$background_color_2: #063783;
$background_color_3: #f0f2f9;


$color_1: #ffffff;
$color_2: #063783;
$color_3: #000;


$font-family_1: "Inter", sans-serif !important;
body {
    
  font-family: $font_family_1;
}
.car-header-logo {
    max-height: 55px;
}
.car_registration_wrapper {
    #preloader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color:$background_color_2;
		z-index: 999999;
		#loader {
			display: block;
			position: relative;
			left: 50%;
			top: 50%;
			width: 150px;
			height: 150px;
			margin: -75px 0 0 -75px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #fff;
			-webkit-animation: spin 2s linear infinite;
			animation: spin 2s linear infinite;
			&:before {
				content: "";
				position: absolute;
				top: 5px;
				left: 5px;
				right: 5px;
				bottom: 5px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #6984b4;
				-webkit-animation: spin 3s linear infinite;
				animation: spin 3s linear infinite;
			}
			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 15px;
				right: 15px;
				bottom: 15px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #fff;
				-webkit-animation: spin 1.5s linear infinite;
				animation: spin 1.5s linear infinite;
			}
		}//loader
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}//@keyframes spin
		h3{
			color: $color_1;
			font-weight: 600;
			font-size: 20px;
			text-align: center;
		}
		h6{
			color: $color_1;
			font-weight: 600;
			font-size: 16px;
			text-align: center;
		}
	}//preloader
    .loader-style{
        img {
            height: 80px;
        }
        h4,h6,h3 {
            color: $color_2 !important;
        }
    }
    li {
        list-style-type: none;
    }
.purchase-date{
    color: $background_color_2 !important;
}
    footer.car-footer-style {
        padding: 25px 0;
        background: #fff;
        font-size: 13px;
        color: $color_2;

        p {
            color: $color_2;
            font-size: 13px;
        }

        a {
            text-decoration: none;
            color: $color_2;
        }
    }

    .car_registration {
        background: #e8e8e8 url(../../img/signature_bg.png) no-repeat center left;
        padding: 50px 0 80px;
        background-size: cover;
        background-attachment: fixed;
        margin-bottom: 50px;

        .top_right_sec {
            .fixed {
                display: flex;
                flex-direction: column;

                position: -webkit-sticky;
                position: sticky;
                right: 0px;
                top: 0;
            }

            .rating {
                margin-bottom: 15px;

                img {
                    height: 20px;
                }
            }

            .potential-claim {
                background: #ec6842 url(../../img/bg-claim.png) no-repeat;
                background-size: cover;
                border-radius: 0px;
                color: #fff;
                margin: 20px auto 10px;
                position: relative;
                width: 100%;
                padding: 25px 15px;
                text-align: center;
                box-shadow: 0 25px 25px rgba(5, 28, 49, .14);
                -webkit-box-shadow: 0 25px 25px rgba(5, 28, 49, .14);

                &::before {

                    background: url(../../img/left_ar.png) no-repeat;
                    background-size: 100% 100%;
                    content: "";
                    height: 34px;
                    left: -20px;
                    position: absolute;
                    top: 50px;
                    width: 20px;

                }

                @media screen and (max-width: 768px) {
                    &::before {
                        display: none;
                    }

                    &::after {
                        background: url(../../img/bot_ar.png) no-repeat;
                        content: "";
                        height: 24px;
                        position: absolute;
                        bottom: -23px;
                        left: 45%;
                        width: 30px;
                        background-size: contain;
                    }
                }



                h4 {
                    font-size: 20px;
                    font-weight: 400;

                    span {
                        font-size: 64px;
                        line-height: 64px;
                        font-weight: 800;
                        margin-top: 15px;
                        display: block;

                    }
                }
            }

            .useful-facts {
                background: #ffe500 url(../../img/bg-claim2.png) no-repeat;
                background-size: 100% 100%;
                border-radius: 0px;
                color: $color_2;
                margin: 20px auto 10px;
                position: relative;
                width: 100%;
                padding: 15px;
                box-shadow: 0 25px 25px rgba(5, 28, 49, .14);
                -webkit-box-shadow: 0 25px 25px rgba(5, 28, 49, .14);

                &::before {

                    background: url(../../img/left_ar_2.png) no-repeat;
                    background-size: 100% 100%;
                    content: "";
                    height: 34px;
                    left: -20px;
                    position: absolute;
                    top: 50px;
                    width: 20px;

                }

                @media screen and (max-width: 768px) {
                    margin: 35px auto 10px;
                    border-radius: 7px;

                    &::before {
                        display: none;
                    }
                }

                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                }

                ul {
                    margin: 0px;
                    padding: 0px;
                    padding-left: 15px;

                    li {
                        background: url(../../img/fact_ico.png) no-repeat center left;
                        background-size: 24px;
                        list-style: none;
                        padding: 10px 0;
                        padding-left: 35px;
                    }
                }

            }






        }

        .regwrapper {
            padding: 15px 15px 30px;
            background: #fff;
            border-radius: 7px;
            border-top: 5px solid $color_2;
            border-bottom: 5px solid $color_2;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

            h2 {
                font-size: 36px;
                font-weight: 600;
                color: $color_2 !important;
                margin: 0px 0 0;
            }

            h3 {
                font-size: 18px;
                line-height: 1.5;
                font-weight: 600;
                color: #063783 !important;
                margin-bottom: 15px;

                span {
                    background: #01b636;
                    color: #fff;
                    padding: 5px;
                    font-size: 17px;
                    line-height: 35px;

                }
            }

            p {
                font-size: 14px;

                a {
                    color: $color_2 !important;
                    text-decoration: underline;

                    &:hover {
                        color: #01b636 !important;
                    }
                }
            }

            .accordion-body {
                @media screen and (max-width: 768px) {
                    padding: 15px;
                }
            }

            .accordion-item {
                margin-bottom: 20px !important;
                border-radius: 0px !important;
                box-shadow: 0px 1px 4px 0px #bfbfbf !important;

                .form-check {
                    margin-left: 0px;
                }

                .form-check-input {
                    border: 2px solid #063783;
                    width: 25px;
                    height: 25px;
                    border-radius: 0px;
                    padding: 10px;
                }

                .form-check-input:checked {
                    border: 2px solid #063783;
                    background-color: $background_color_2;
                }

            }

            .arrow-img{
                transition: ease .5s;
            }

            .accordion-item.active{
                .arrow-img{
                    transform: rotate(-180deg);
                }
            }

            .accordion_button[aria-expanded="true"] {
                background-color: #fcfcfc;
                color: #404040;
                /*   box-shadow:0px 1px 2px 2px #fffff7; */
            }

            .accordion_button {
                    border: 2px solid #063783 !important;
                    padding: 1rem !important;
                    border-radius: 5px !important;

                label {
                    width: 100%;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

            .accordion_button .check {
                border: 1px solid #063783;
                background: #063784 url(../../img/cbimage.png) no-repeat center 6px;
                background-size: 12px;
            }

            .accordion_button .cancel_check {
                border: 1px solid #063783;
                background: #063784 url(../../img/close.png) no-repeat center 5px;
                background-size: 10px;
            }

            .collapsed {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .check, .cancel_check, .uncheck {
                    width: 22px;
                    height: 22px;
                    border-radius: 16%;
                    display: inline-block;
                    border: 1px solid #063783;
                    margin-right: 10px;
                    position: relative;
                    top: 0px;
                }
            }
            .accordion-content {
                border: solid 2px #063783;
                border-top: 0px;
                padding: 1.25rem !important;
            }

            .accordion-button:after {
                color: #404040;
            }

            .material-textfield {
                position: relative;
                margin-top: 20px;
                margin-bottom: 30px;

                &:before {
                    @media screen and (max-width: 768px) {
                        top: 29px;
                    }

                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 8px;
                    color: #0A0A0A;
                    background: #0267C1 url(../../img/reg_uk.svg) no-repeat center center;
                    -webkit-background-size: 15px 25px;
                    -moz-background-size: 15px 25px;
                    -o-background-size: 15px 25px;
                    background-size: 15px 25px;
                    border: 2px solid #FFFFFF;
                    -webkit-box-shadow: 0px 0px 0px 2px #0267C1;
                    -moz-box-shadow: 0px 0px 0px 2px #0267C1;
                    box-shadow: 0px 0px 0px 2px #0267C1;
                    width: 30px;
                    height: 40px;
                    content: '';
                    z-index:99;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                }

                label {
                    position: absolute;
                    font-size: 16px;
                    font-weight: 800;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #6e6e6e;
                    padding: 3px 9px 3px 44px;
                    margin: 0 0.5rem;
                    transition: .1s ease-out;
                    transform-origin: left top;
                    pointer-events: none;
                    border-radius: 5px;

                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                        top: 29px;
                    }
                }

                input {
                    font-size: 19px;
                    outline: none;
                    border: 2px solid #063783;
                    border-radius: 5px;
                    height: 58px;
                    transition: 0.1s ease-out;
                    padding: 0 45px;
                    text-transform: uppercase;
                    font-weight: 700;
                    background: #fff41f;
                    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);

                    @media screen and (max-width: 768px) {
                        border-radius: 5px !important;
                    }

                    &:focus {
                        border-color: #afafaf;
                    }

                    &:focus+label {
                        color: #6e6e6e;
                        top: -11px;
                        transform: translateY(-50%) scale(0.9);
                        padding: 11px 9px 13px 9px;
                        margin-left: 45px;
                        font-weight: 700;
                        font-size: 15px;
                    }

                    &:not(:placeholder-shown)+label {
                        top: -11px;
                        transform: translateY(-50%) scale(.9);
                        padding: 3px 9px 3px 9px;
                        margin-left: 45px;
                    }

                    @media (max-width: 510px) {}
                }
            }

            //material-textfield

            .reg_lookup_but {
                background-image: url(../../img/search.svg) !important;
                background-size: 15px 15px !important;
                background-repeat: no-repeat !important;
                background-position: 90% !important;
                display: inline-block;
                background: $background_color_2;
                color: #fff !important;
                padding: 16px 0px 16px 10px;
                border-radius: 7px;
                border: 0;
                width: 30%;
                font-size: 16px;
                font-weight: 600;
                box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
                cursor: pointer;
                transition: all .3s ease-in-out;
                text-align: center;

                @media screen and (max-width: 768px) {
                    background-position: 90% !important;
                    width: 100%;
                    font-size: 18px;
                    border-radius: 7px !important;
                    margin-top: 20px;
                }

                &:hover {
                    background: #01b636;
                }
            }

            .regButton {
                background-image: url(../../img/right-chevron.svg) !important;
                background-size: 17% 42px !important;
                background-repeat: no-repeat !important;
                background-position: 100% !important;
                display: inline-block;
                background: $background_color_2;
                color: #fff !important;
                padding: 16px 0px;
                border-radius: 7px;
                border: 0;
                font-size: 22px;
                width: 50%;
                // max-width: 200px;
                font-weight: 600;
                box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
                cursor: pointer;
                transition: all .3s ease-in-out;
                text-align: center;

                &:hover {
                    background: #01b636;
                }
            }








        }

        .car-ulstyle {
            li {
                list-style-type: none;
            }
        }


        // animated fixed but
        @media screen and (max-width: 768px) {

            .top_right_sec {
                .potential-claim {
                    margin: 0px auto 10px;
                    padding: 10px 0px;
                    border-radius: 7px;

                    h4 {
                        font-size: 16px;

                        span {
                            font-size: 24px;
                            line-height: 23px;
                            margin-top: 0px;
                            display: inline-block;
                        }
                    }
                }
            }


            .fixed_but {
                bottom: 0;
                left: 0;
                right: 0;
                position: fixed;
                z-index: 99;
                width: 100% !important;
            }

            .but_anim {
                animation: mymove 1s infinite;
            }

            @keyframes mymove {
                50% {
                    box-shadow: 0px 0px 50px #063783;
                }
            }
        }

        // animated fixed but end


        .carregform-modal {
            .modal-header {
                background: $background_color_2;
                text-align: center;
                display: block;

                img {
                    max-height: 35px;
                }

                @media screen and (max-width: 767px) {
                    img {
                        max-height: 30px;
                    }
                }

            }

            .modal-body {
                color: $color_2;

                h3 {
                    font-size: 20px !important;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                }

                label {
                    font-size: 18px;
                    font-weight: 600;
                }

                .form-control {
                    padding: 15px;
                    background: $background_color_1;
                    color: $color_2;
                    border: 2px #063783 solid;
                    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
                }

                .input-group-text {
                    background: #063783;
                    color: #fff;
                    font-size: 30px;
                    box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
                    border: 1px solid #063783;
                }

            }

            .modal-footer {
                background: $background_color_2;

                p {
                    font-size: 13px;
                    color: $color_1;
                }

                img {
                    filter: invert(1);
                }
            }

            @media screen and (max-width: 767px) {
                .modal-body {
                    h3 {
                        font-size: 18px !important;
                        margin: 10px 0 20px;
                    }

                    p {
                        font-size: 14px;
                    }

                    label {
                        font-size: 18px;
                    }

                }
            }
        }

    }

    .choose_date {
        display: flex;
        width: 100%;
        padding: 0 0px;
        flex-direction: column;


        @media (max-width:768px) {
            flex-direction: column;
            gap: 4px;
        }

        /////////////////////////// form check /////////////////////////////////
        .form-check {
            width: 100%;
            margin-bottom: 10px;

            [type="radio"] {
                display: none;
            }

            // & + .form-check {
            //  width: 50%;
            // }
            label {
                display: block;
                padding: 13px 60px;
                background: #fff;
                border: 2px solid #063783;
                box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.4);
                border-radius: 5px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                color: #063783;
                white-space: nowrap;
                position: relative;
                transition: 0.4s ease-in-out 0s;

                @media (max-width: 768px) {
                    display: block;
                    padding: 13px 10px 13px 50px;
                    width: 100%;
                    white-space: inherit;
                }

                &:after {
                    height: 19px;
                    width: 19px;
                    border: 2px solid #063783;
                    left: 19px;
                    top: calc(50% - 10px);
                }

                &:before {
                    background: #004d8b;
                    height: 11px;
                    width: 11px;
                    left: 23px;
                    top: calc(50% - 6px);
                    transform: scale(5);
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.4s ease-in-out 0s;
                }
            }

            [type="radio"]:checked {
                &~label {
                    border-color: $color_2;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    background: $background_color_2 !important;
                    color: #ffffff !important;

                    &::before {
                        background: #fff;
                        opacity: 1;
                        visibility: visible;
                        transform: scale(1);
                    }

                    &::after {
                        border: 2px solid #fff;
                    }
                }
            }
        }

        .form-check label:after,
        .form-check label:before {
            content: "";
            position: absolute;
            border-radius: 50%;
        }

        ///////////////////////// form check /////////////////////////////////
    }

    //choose_bw
    .back-but {
        display: inline-block;
        padding: 5px 10px;
        cursor: pointer;
        text-align: center;
        background-size: 14px;
        background-color: #ccc;
        font-weight: 700;
        outline: none;
        border: 0;
        height: auto !important;
        max-width: 100px !important;
    }
    .regButtonpop {
        background-image: url(../../img/right-chevron.svg) !important;
        background-size: 17% 42px !important;
        background-repeat: no-repeat !important;
        background-position: 100% !important;
        display: inline-block;
        background: $background_color_2;
        color: #fff !important;
        padding: 16px 0px;
        border-radius: 7px;
        border: 0;
        font-size: 22px;
        width: 50%;
        // max-width: 200px;
        font-weight: 600;
        box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
        cursor: pointer;
        transition: all .3s ease-in-out;
        text-align: center;

        &:hover {
            background: #01b636;
        }
    }


    .green_color_but {
        background-image: url(../../img/right-chevron.svg) !important;
        background: #01b636;
        transition: all .3s ease-in-out;

        &:hover {
            background: #ec6842;
        }
    }

    .btn-skip {
        background: #fff;
        padding: 7px 25px;
        border: dashed 2px #9b9b9b;
        border-radius: 7px;
        display: inline-block;
        margin-bottom: 15px;
        color: #9b9898 !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }

    @media screen and (max-width: 768px) {
        

        .car_registration {
            padding: 10px 0 40px;

            .regwrapper {
                margin-top: 20px;

                h2 {
                    font-size: 24px;
                    margin: 0px 0 0;
                }

                h3 {
                    margin-top: 15px;
                }
            }
        }

    }


    @media screen and (max-width: 578px) {
        .car-header-logo {
            max-height: 30px;
        }

    }

   
}