.signature-section {
  h1 {
    color: #515151;
  }
.sign-sec {
  background-image: url(../../img/followup/bg8588.png);
  background-position: center bottom;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  h5 {
    font-size: 16px;
  }
  .clearbutton {
    background: #eee;
    border: none;
    outline: 0;
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    width: 100%;
  }
  .signbox {
    text-align: center;
    .border-div{
      @media screen and (max-width: 399px) {
        min-width: 280px;
    }
      background: #02a1e5;
    border-radius: 7px;
    padding: 10px 0;
    min-width: 320px;
    width: 60%;
    margin: 0 auto 20px;
    display: grid;
    justify-content: center;
  }
    canvas {
      // width: 100%;
      // height: 300px;
      width: 250px;
      height: 160px;
      background: #e4e4e4;

      // border: 1px solid #d5d5d5;
      border: 2px dashed #063783;
       background: #eef5ff url(../../img/followup/signhere.png) no-repeat center center;
      border-radius: 10px;
    }
    h5{
      position: relative;
    top: 15px;
    background-color: #00b90f;
    color: #fff;
    padding: 10px 25px;
    margin: 0;
    border-radius: 7px;
    font-size: 18px;
    text-align: center;
    display: inline-block;

      }
      .icodown {
          animation: MoveUpDown 1s linear infinite;
          position: relative;
          left: 0;
          bottom: 0;
        }
        
        @keyframes MoveUpDown {
          0%, 100% {
            bottom: -10px;
          }
          50% {
            bottom: 5px;
          }
        }
  }
  .signature-footer {
    p {
      font-size: 12px;
    }
    a {
      color: black;
    }
  }
}

}



.shine {
  position: relative;
  overflow: hidden;
  
  &::before {
    position: absolute;
    top: 0;
    left: -500px;
    content: "";
    width: 120px;
    height: 500px;
    background: rgba(255, 255, 255, 0.6);
    transform: skew(-50deg);
    /* transition: 1s; */
    animation: shine 3s ease infinite;
  }
  @keyframes shine {
    from {left: -100px;}
    to {left: 655px;}
  }
   
}


.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #f39d56;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


.confettiCanvas {
  #confettiCanvas {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
  }
}

.is_sticky {
  position: sticky;
  bottom: 5px;
  z-index: 999;
}

.text-cicle {
  position: relative;
  svg {
    position: absolute;
    fill: transparent;
    left: 0;
    width: 150px;
    height: 150px;
    font-size: 12px;
    z-index: 99;
  }
  
}